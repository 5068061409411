import React from 'react';

import { Header } from '../../layout/header/Header';
import { Footer } from '../../layout/footer/Footer';
import { Breaker } from '../../UIKit/breaker/Breaker';
import { ProvidersHIWHeadSection } from './components/ProvidersHIWHeadSection';
import { ProvidersHIWJoinUs } from './components/ProvidersHIWJoinUs';
import { ProvidersHIWGrow } from './components/ProvidersHIWGrow';
import { ProvidersHIWPartnering } from './components/ProvidersHIWPartnering';
import { ProvidersHIWContact } from './components/ProvidersHIWContact';

export const ProvidersHIW: React.FC = () => {

  return (
    <div className='bg-white'>
        <Header className='bg-white'/>
        <ProvidersHIWHeadSection />
        <Breaker className='breaker-disable_gradient bg-aqua'/>
        <ProvidersHIWJoinUs />
        <ProvidersHIWGrow />
        <Breaker className='breaker-disable_gradient bg-aqua'/>
        <ProvidersHIWPartnering />
        <ProvidersHIWContact />
        <Footer />
    </div>
  );
};
