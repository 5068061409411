import React from 'react';
import { Title } from '../../../../UIKit/title/Title';
import { useLang } from '../../../../../Lang/useLang';

export const AboutHeadSection: React.FC = () => {
  const { $ }: any = useLang();
  return (
    <div className="w-full bg-team bg-cover bg-no-repeat h-[400px] md:h-[500px] mb-12 ">
      <div className="w-full h-[400px] md:h-[500px] absolute bg-gradient-to-t from-black/50 to-black/0 " />
      <div className="w-full h-full flex items-center justify-center">
        <Title 
          text={$("ABOUT_HEADER_TITLE")} 
          className="text-white text-5xl md:text-6xl z-0" 
        />
      </div>
    </div>
  );
};
