
interface IObserver {
    list: Record<string, any>[];
    listen: (name: TObserverName, evt: (payload: any) => void) => void;
    shout: (name: TObserverName, payload: any) => void;
    has: (name: TObserverName) => boolean;
}

type TObserverName = 'cta_click' | 'form_submission' | 
    'faq_click' | 'region_picker_open' | 'region_picker_select' |
    'appointment_step' | 'appointment_booked';

export const observer: IObserver = {
    list: [],
    listen: (name, evt) => {
        observer.list.push({
            name,
            evt
        })
    },
    has: (name) => {
        return !!observer.list.find(i => i.name === name);
    },
    shout: (name, payload) => {
        observer.list.forEach(i => {
            if(i.name === name) {
                i.evt(payload)
            }
        })
    }
}