import React from 'react';
import { useLang } from '../../../../Lang/useLang';
import { Title } from '../../../UIKit/title/Title';
import cn from 'classnames'
import { ScienceButton } from '../../home/home-sections/science/components/ScienceButton';
import { useMediaQuery } from 'react-responsive'
import { SciencePublicationsSlider } from './SciencePublicationsSlider';
import { Link } from 'react-router-dom';



export const SciencePublications: React.FC = () => {
    const { $, $flat } = useLang();
    const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })

    const publications = [
        {
            title: $('SCIENCE_PUBLICATIONS_1_TITLE'),
            text: $('SCIENCE_PUBLICATIONS_1_TEXT'),
            type: $('SCIENCE_PUBLICATIONS_1_TYPE'),
            publicationInfo: $('SCIENCE_PUBLICATIONS_1_INFO'),
            link: $flat('SCIENCE_PUBLICATIONS_1_LINK')
        },
        {
            title: $('SCIENCE_PUBLICATIONS_2_TITLE'),
            text: $('SCIENCE_PUBLICATIONS_2_TEXT'),
            type: $('SCIENCE_PUBLICATIONS_2_TYPE'),
            publicationInfo: $('SCIENCE_PUBLICATIONS_2_INFO'),
            link: $flat('SCIENCE_PUBLICATIONS_2_LINK')

        },
        {
            title: $('SCIENCE_PUBLICATIONS_3_TITLE'),
            text: $('SCIENCE_PUBLICATIONS_3_TEXT'),
            type: $('SCIENCE_PUBLICATIONS_3_TYPE'),
            publicationInfo: $('SCIENCE_PUBLICATIONS_3_INFO'),
            link: $flat('SCIENCE_PUBLICATIONS_3_LINK')

        },
        {
            title: $('SCIENCE_PUBLICATIONS_4_TITLE'),
            text: $('SCIENCE_PUBLICATIONS_4_TEXT'),
            type: $('SCIENCE_PUBLICATIONS_4_TYPE'),
            publicationInfo: $('SCIENCE_PUBLICATIONS_4_INFO'),
            link: $flat('SCIENCE_PUBLICATIONS_4_LINK')

        },
        {
            title: $('SCIENCE_PUBLICATIONS_5_TITLE'),
            text: $('SCIENCE_PUBLICATIONS_5_TEXT'),
            type: $('SCIENCE_PUBLICATIONS_5_TYPE'),
            publicationInfo: $('SCIENCE_PUBLICATIONS_5_INFO'),
            link: $flat('SCIENCE_PUBLICATIONS_5_LINK')

        },
    ]

  return (
    <div className='w-full max-w-content flex flex-col items-center py-20 mx-auto'>
        <Title className='text-[40px] m-0 mb-6' text={$('SCIENCE_PUBLICATIONS_TITLE')}/>

        <div className='w-full max-w-[1100px] md:px-5'>
            {
                isDesktop ? publications.map(({ title, text, type, publicationInfo, link }, index) => (
                    <div className={cn('flex justify-between py-12',{ 'border-t-[3px] border-primary-500': index })} key={index}>
                        <div>
                            <h5 className='font-medium text-[22px] leading-[24px]'>{title}</h5>
                            <p className='text-gray-300 mt-4 mb-5 max-w-[745px]'>{text}</p>
                            <p className='text-lg mb-2'>{type}</p>
                            <p className='text-lg font-medium'>{publicationInfo}</p>
                        </div>
                        <Link to={link as string}>
                            <ScienceButton className='py-3 gap-x-3'>{$('HOME_SCIENCE_ARTICLE_BTN')}</ScienceButton>
                        </Link>
                    </div>
                )) : (
                    <SciencePublicationsSlider publications={publications} />
                )
            }
        </div>
    </div>
  );
};