import React from 'react'
import { Footer } from '../../layout/footer/Footer'
import { Header } from '../../layout/header/Header'
import { Breaker } from '../../UIKit/breaker/Breaker';
import { LearnMoreListing } from './components/LearnMoreListing';

export const LearnMorePage: React.FC = () => {
 
  return (
    <div className='w-full flex h-auto flex-col items-center bg-white'>
        <Header />
        <LearnMoreListing />
        <Footer /> 
    </div>
  )
}