import React from 'react';
import { useLang } from '../../../../Lang/useLang';
import { ITag } from '../../../../types/type';
import { Title } from '../../../UIKit/title/Title';
import { LineCenter } from '../../../UIKit/Line/Line';
import { Tag } from '../uikit/tag/Tag';

export const ExploreArticles: React.FC<{ tags: ITag[], handleTagSelection: (tag: string) => void, selectedTag: string }> = ({ tags, handleTagSelection, selectedTag }) => {
  const { $ }: any = useLang()

  return (
    <div className='w-full max-w-content px-8'>
      <Title text={$('BLOG_LISTING_EXPLORE_ARTICLES')} className='text-3xl mt-11 mb-10' />
      <LineCenter>
        {tags.map(({ title, id }) => (
          <Tag
            key={id}
            title={title}
            isSelected={selectedTag === id}
            onClick={() => handleTagSelection(id)}
          />
        ))}
      </LineCenter>
    </div>
  );
};
