import { twMerge } from "tailwind-merge";
import { Title } from "../../UIKit/title/Title";
import { Header } from "../../layout/header/Header";
import { Footer } from "../../layout/footer/Footer";
import { Breaker } from "../../UIKit/breaker/Breaker";
import { KidsSection } from "./components/KidsSection";
import { WhatWeGiveYouSection } from "./components/WhatWeGiveYouSection";
import { useLang } from "../../../Lang/useLang";

export default function Toddlers() {
  const { $ }: any = useLang();

  return (
    <div className='bg-blur bg-cover bg-no-repeat '>
      <Header isWhite className='absolute w-full z-50'/>
      <div className="flex flex-col min-h-screen items-center">
        <div 
          className={twMerge(
            'bg-toddlers bg-no-repeat bg-cover',
            'w-full h-[500px] items-start justify-center',
          )}
        >
          <div className="w-full flex items-center justify-center absolute h-[500px] opacity-50 z-10 bg-gradient-to-t from-black" />
            <div className="w-full max-w-content mx-auto h-full flex flex-col items-center justify-start px-12 mt-24">
              <div className="w-full flex flex-col justify-center">
                <Title 
                  text={$("TODDLERS_HEADER_TITLE")} 
                  className="mb-8 text-5xl max-w-[600px] text-left leading-tight text-white z-20 " 
                />
                <div className="flex flex-col max-w-[600px] gap-4 text-base text-white z-20">
                  <span>
                    {$("TODDLERS_HEADER_SUBTITLE")} 
                  </span>
                  <span
                    className="font-bold" 
                  >
                    {$("TODDLERS_HEADER_SUBTITLE_2")} 
                  </span>
                </div>
              </div>
            </div>
        </div>
        <div className="w-full flex flex-col items-center justify-center">
          <div className="w-full max-w-content flex flex-col items-center justify-center gap-8 px-12 py-16">
            <div className="w-full text-4xl text-center font-light leading-relaxed text-zinc-800">
              {$("TODDLERS_HERO")}  
            </div> 
          </div>
          <Breaker />
          
          <KidsSection />

          <Breaker />
                    
          <WhatWeGiveYouSection />
          
          <Breaker />
          
          <div className="w-full flex flex-col items-center justify-center relative">
            <div className="w-full max-w-content flex flex-col items-center justify-center gap-8 px-12 py-16 ">
              <span className="text-3xl text-zinc-800 text-center leading-relaxed">
                {$("TODDLERS_CONSULTATION_TITLE")}
              </span>
              <div className="w-full flex flex-col md:flex-row items-center justify-center md:justify-between rounded-lg bg-white shadow-sm p-12 gap-8">
                <span className="text-xl text-zinc-600 text-center md:text-left">
                  {$("TODDLERS_CONSULTATION_TEXT")}
                </span>
                <button
                  className="flex items-center justify-center whitespace-nowrap px-5 py-4 rounded-lg bg-primary-500 font-bold text-white hover:bg-primary-600 transition-colors"
                >
                  {$("TODDLERS_CONSULTATION_BUTTON")}
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  )
}