import React from 'react';
import { storyType } from '../../../../../../types/type';
import { Card } from '../../../../../UIKit/card/Card';

interface StoryProps {
  story: storyType;
}

export const Story: React.FC<StoryProps> = ({ story }) => {
  return (
    <Card className="flex w-full flex-col py-10 px-8 gap-8">
      <div className='flex flex-col items-center gap-4'>
        <img 
          className='rounded-full w-24 h-24' 
          src={story.imgSource} 
          alt="" 
        />
        <div className='w-full flex flex-col gap-1'>
          <div className='font-semibold'>
            {story.name}
          </div>
          <div className='text-sm text-zinc-600'>
            {story.location}
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-8'>
        <div className='text-base text-zinc-700 font-semibold'>
          {story.title}
        </div>
        <div className='text-sm text-zinc-600'>
          {story.text}
        </div>
      </div>
    </Card>
  );
};
