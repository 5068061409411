import { useEffect, useState } from 'react';
import { Logo } from '../../UIKit/logo/Logo';
import { Links } from './components/links/Links';
import { ButtonGroup } from './components/buttonGroup/ButtonGroup';
import { useNavigate } from 'react-router-dom';
import { TryButton } from './components/buttonGroup/TryButton';
import { AlignJustify, X } from 'lucide-react';
import { twMerge } from 'tailwind-merge';
import { Line } from '../../UIKit/Line/Line';
import { useMediaQuery } from 'react-responsive'

interface HeaderProps {
  className?: string;
  isWhite?: boolean;
}

export function Header({ className, isWhite = false }: HeaderProps) {

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const navigator = useNavigate();
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })

  const onMenuIconClick = () => {
    setOpenMenu(!openMenu);
  };

  const closeMenu = () => setOpenMenu(false)

  const onLogoHandler = () => {
    navigator('/');
  };

  useEffect(() => {
    if(openMenu) document.body.classList.add('!overflow-hidden')
    else document.body.classList.remove('!overflow-hidden')
    return () => document.body.classList.remove('!overflow-hidden')
  }, [openMenu])


  return (
    <div
      id='header'
      data-open={!isDesktop && openMenu}
      className={twMerge(
        'w-full flex items-center justify-center mx-auto relative h-[90px]',
        'border-b border-b-black/20 transition-all ease-in-out',
        'data-[open=true]:items-start data-[open=true]:bg-white z-50',
        className
      )}
    >
      {!isDesktop ? (
        <div className="w-full h-full flex flex-col items-center justify-center gap-5 ">
          <div className="w-full max-w-content flex items-center justify-between px-8 gap-3">
            <Logo
              onClick={onLogoHandler}
              isBlack={openMenu || !isWhite}
            />
            <Line addClass='flex-nowrap'>
              <ButtonGroup isMenuOpen={openMenu} />
              <button
                className={twMerge(
                  'flex items-center justify-center p-2 rounded-md hover:bg-zinc-200',
                  'transition-colors',
                )}
                onClick={onMenuIconClick}
              >
                <AlignJustify
                  data-show={openMenu}
                  data-is-white={isWhite}
                  className={twMerge(
                    'w-5 h-5 data-[show=true]:hidden',
                    'data-[is-white=true]:text-white data-[is-white=true]:hover:text-zinc-600'
                  )}
                />
                <X
                  data-show={openMenu}
                  className="w-5 h-5 hidden data-[show=true]:flex text-zinc-600"
                />
              </button>
            </Line>
          </div>

          <div
            data-show={openMenu}
            className={twMerge(
              'w-full flex flex-col items-start gap-1/3 opacity-0 absolute top-[90px] transition-opacity ease-in-out',
              'bg-white px-8 py-12 border-b border-zinc-200 data-[show=true]:opacity-100',
              'shadow-sm',
              'h-[calc(100vh-90px)] justify-between items-center',
              !openMenu && 'hidden'
            )}
          >
            <div className="flex mb-8">
              <Links isBlack={!isWhite} className="items-center" handleClick={closeMenu} />
            </div>
            <TryButton className='w-full'/>
          </div>
        </div>
      ) : (
        <div
          data-white={isWhite}
          className={twMerge(
            'w-full max-w-content flex items-center justify-between px-8 gap-3',
            'data-[white=true]:text-white'
          )}>
          <Logo
            onClick={onLogoHandler}
            isBlack={!isWhite}
          />
          <Links isBlack={!isWhite} />
          <ButtonGroup isBlack={!isWhite} />
        </div>
      )}
    </div>
  );
};
