import { ReactNode } from "react"

interface IProps {
    children: ReactNode
}

export const H1 = ({ children }: IProps) => {
    return (
        <p className='text-4xl font-bold text-zinc-900'>{children}</p>
    )
}

export const H2 = ({ children }: IProps) => {
    return (
        <p className='font-bold text-zinc-900 text-2xl md:text-3xl'>{children}</p>
    )
}


export const H2Thin = ({ children }: IProps) => {
    return (
        <p className='text-zinc-900 text-2xl md:text-3xl max-w-[920px]'>{children}</p>
    )
}


export const P = ({ children }: IProps) => {
    return (
        <p className='max-w-[920px] text-2xl md:text-lg '>{children}</p>
    )
}

export const Num = ({ children }: IProps) => {
    return (
        <h6 className="text-[64px] text-primary-300 font-bold">{children}</h6>
    )
}

export const TitleP = ({ children }: IProps) => {
    return (
        <h6 className="text-primary-500 text-xl font-bold flex items-center gap-x-2.5">{children}</h6>
    )
}