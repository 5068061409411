import React, { useContext } from 'react'
import { AboutHeadSection } from './about-sections/header-section/AboutHeadSection'
import { OurMissionSection } from './about-sections/our-mission-section/OurMissionSection'
import { Breaker } from '../../UIKit/breaker/Breaker'
import { AboutScienceSection } from './about-sections/the-science-section/AboutScienceSection'
import { TeamSection } from './about-sections/team-section/TeamSection'
import { Footer } from '../../layout/footer/Footer'
import { Header } from '../../layout/header/Header'

export const AboutPage: React.FC = () => {
 
  return (
    <div className='bg-blur bg-cover bg-no-repeat'>
        <Header className="absolute z-10" isWhite />
        <AboutHeadSection />
        <OurMissionSection />
        <Breaker />
        <AboutScienceSection />
        <Breaker />
        <TeamSection />
        <Footer /> 
    </div>
  )
}