import { FC } from 'react';
import partnerCTA from '../../../../../assets/images/partner-cta.png';
import { Button } from '../../../../UIKit/button/Button';
import { useLang } from '../../../../../Lang/useLang';
import { Title } from '../../../../UIKit/title/Title';
import { usePopupIframe } from '../../../../../modal/popIframe';


export const PartnerCTA: FC = () => {
  const { $ }: any = useLang();
  const { popIframe } = usePopupIframe();

  return (
    <div className="w-full max-w-content flex flex-col md:flex-row gap-x-14 pt-11 md:pb-3 py-8 md:py-20 px-6 md:px-16 mx-auto">
      <img src={partnerCTA} className="hidden md:block" />
      <div className='mt-8 flex flex-col gap-y-8'>
        <Title className="m-0 md:text-left" text={$('HOME_PARTNER_CTA_SECTION_TITLE')} />
        <img src={partnerCTA} className="block md:hidden" />
        <div className="w-full md:w-fit">
          <Button
            className="cta_click w-full flex px-6 py-4 items-center justify-center text-base font-semibold hover:bg-primary-400"
            position="GET_STARTED"
            onClick={() => popIframe("section")}
          >
            {$('GET_STARTED')}
          </Button>
        </div>
      </div>

    </div>
  );
};
