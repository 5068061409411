import React from 'react';
import { Accordion } from '../../../../../UIKit/accordion/Accordion';
import { useLang } from '../../../../../../Lang/useLang';
import { Link } from 'react-router-dom';

type TQuestions = {
  title: string,
  answer: string
}

export const Questions: React.FC = () => {
  const { $, country, countries }: any = useLang();


  const countryCode = countries.find((i: any) => i._id === country)?.key as string;

  const getReadMoreURL = () => {
      switch (countryCode) {
        case "au": 
          return "https://preventallergies.org.au/";
        case "us": 
        return "https://sciencedirect.com/science/article/abs/pii/S2213219820312113?casa_token=euLh1m7oIZ8AAAAA:We-iEZvKyVxsnLCnyP7BUH9mke8v0BEJ2plR8XVznLLfqTf8zMe8qky-TB2r9Nv9MKUCh-THQn2f";
        case "sp": 
        case "en-irl": 
          return "https://onlinelibrary.wiley.com/doi/pdfdirect/10.1111/pai.13496";
        default:
          return "https://myorcare.com/science";
      }
  }

  const questions: TQuestions[] = [
    {
      title: $('HOME_QUESTION_1_TITLE'),
      answer: $('HOME_QUESTION_1_ANSWER')
    },
    {
      title: $('HOME_QUESTION_2_TITLE'),
      answer: $('HOME_QUESTION_2_ANSWER')
    },
    {
      title: $('HOME_QUESTION_3_TITLE'),
      answer: $('HOME_QUESTION_3_ANSWER', {
        link: (value: string) => <Link to={getReadMoreURL()} target="_blank">{value}</Link>,
      })
    },
    {
      title: $('HOME_QUESTION_4_TITLE'),
      answer: $('HOME_QUESTION_4_ANSWER')
    },
    {
      title: $('HOME_QUESTION_5_TITLE'),
      answer: $('HOME_QUESTION_5_ANSWER')
    },
    {
      title: $('HOME_QUESTION_6_TITLE'),
      answer: $('HOME_QUESTION_6_ANSWER')
    },
    {
      title: $('HOME_QUESTION_7_TITLE'),
      answer: $('HOME_QUESTION_7_ANSWER')
    },
    {
      title: $('HOME_QUESTION_8_TITLE'),
      answer: $('HOME_QUESTION_8_ANSWER')
    },
    {
      title: $('HOME_QUESTION_9_TITLE'),
      answer: $('HOME_QUESTION_9_ANSWER')
    },
    {
      title: $('HOME_QUESTION_10_TITLE'),
      answer: $('HOME_QUESTION_10_ANSWER')
    },
    {
      title: $('HOME_QUESTION_11_TITLE'),
      answer: $('HOME_QUESTION_11_ANSWER')
    },
    {
      title: $('HOME_QUESTION_12_TITLE'),
      answer: $('HOME_QUESTION_12_ANSWER')
    }
  ];
  return (
    <div className="w-full max-w-content flex flex-col gap-24">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        {
          questions.map(({ title, answer }, index) => (
            <Accordion
              key={index}
              className='md:border border-gray-400 min-w-0 min-h-0 h-fit'
              activeClassName='border-primary-500'
              title={title}
              content={answer}
            />
          ))
        }
      </div>
    </div>
  );
};
