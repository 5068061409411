import React from 'react';
import c from 'classnames';
import './Label.css';

export enum LabelPosition {
  Up,
  Down,
  Left,
  Right
}

interface LabelProps {
  children: React.ReactNode;
  text: string;
  labelClassName?: string;
  containerClassName?: string;
  position?: LabelPosition;
}

export const Label: React.FC<LabelProps> = ({
  children,
  text,
  labelClassName,
  containerClassName,
  position = LabelPosition.Up
}) => {
  return (
    <div className={c(containerClassName, `label-container label-${LabelPosition[position]}`)}>
      <div className={c(labelClassName, 'label')}>{text}</div>
      {children}
    </div>
  );
};
