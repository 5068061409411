import { FC } from 'react';
import { Button } from '../../../../UIKit/button/Button';
import { useLang } from '../../../../../Lang/useLang';
import { Link } from 'react-router-dom';
import { Title } from '../../../../UIKit/title/Title';


export const RecruitmentSection: FC = () => {
  const { $ }: any = useLang();

  return (
    <div className="w-full flex bg-blue-light flex-col items-center justify-center px-8 py-20 md:px-16">
        <Title className="m-0 text-2xl md:text-[31px] mb-10" text={$('HOME_RECRUITMENT_SECTION_TITLE')}/>
        <div className='w-full text-center md:text-left md:bg-white rounded-xl md:py-8 md:px-11 max-w-[1100px] flex flex-col gap-y-8 md:flex-row md:justify-between items-center'>
            <p className='text-lg max-w-[646px] text-gray-300'>{$('HOME_RECRUITMENT_SECTION_DESCRIPTION')}</p>
            <Link to={'/contact'} className="w-full md:w-fit">
                <Button
                    className="flex w-full px-6 py-4 items-center justify-center text-base font-semibold hover:bg-primary-400 whitespace-nowrap"
                >
                    {$('JOIN_THE_NETWORK')}
                </Button>
            </Link>
        </div>

    </div>
  );
};
