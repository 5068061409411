import React, { useMemo } from 'react';
import { Title } from '../../../../UIKit/title/Title';
import { Employee } from '../../../../../types/type';
import ArielKImageSrc from '../../../../../assets/images/Ariel-k.png';
import RoniKImageSrc from '../../../../../assets/images/Roni-k.png';
import MichaelBImageSrc from '../../../../../assets/images/Michael-b.png';
import TamiLImageSrc from '../../../../../assets/images/Tami-l.png';
import KerenGImageSrc from '../../../../../assets/images/Keren-g.png';
import EstherLImageSrc from '../../../../../assets/images/Esther-l.png';
import ElizabethBImageSrc from '../../../../../assets/images/Elizabeth-b.png';
import TaniaALImageSrc from '../../../../../assets/images/Tania-a-l.png';
import LeticiaTImageSrc from '../../../../../assets/images/Leticia-t.png';
import HelenJImageSrc from '../../../../../assets/images/Helen-j.png';
import JanaImageSrc from '../../../../../assets/images/jana_pitcock.jpeg';
import TavierneyRImageSrc from '../../../../../assets/images/Tavierney-r.png';
import { EmployeeCard } from './employee-card/EmployeeCard';
import { useLang } from '../../../../../Lang/useLang';

export const TeamSection: React.FC = () => {
  const { $, $flat }: any = useLang();
  const employees: Employee[] = useMemo(() => [
    {
      name: $('ARIEL_K_NAME'),
      role: $('ARIEL_K_ROLE'),
      location: $flat('ISRAEL'),
      region: 'Israel',
      linkedInUrl: 'https://www.linkedin.com/in/dr-ariel-katz-3989a01b/',
      emailUrl: 'mailto://ariel@myorcare.com',
      imageSrc: ArielKImageSrc
    },
    {
      name: $('RONI_K_NAME'),
      role: $('RONI_K_ROLE'),
      location: $flat('ISRAEL'),
      region: 'Israel',
      linkedInUrl: 'https://www.linkedin.com/in/roni-kohen-83724937/',
      emailUrl: 'mailto://ronk@ekmd.huji.ac.il',
      imageSrc: RoniKImageSrc
    },
    {
      name: $('MICHAEL_B_NAME'),
      role: $('MICHAEL_B_ROLE'),
      location: $flat('ISRAEL'),
      region: 'Israel',
      linkedInUrl: 'https://www.linkedin.com/in/michaelbrandwein/',
      emailUrl: 'mailto://michael@myorcare.com',
      imageSrc: MichaelBImageSrc
    },
    {
      name: $('TAMI_L_NAME'),
      role: $('TAMI_L_ROLE'),
      location: $flat('ISRAEL'),
      region: 'Israel',
      linkedInUrl: 'https://www.linkedin.com/in/tami-landau-aba5089b/',
      emailUrl: 'mailto://tami@myorcare.com',
      imageSrc: TamiLImageSrc
    },
    {
      name: $('KEREN_G_NAME'),
      role: $('KEREN_G_ROLE'),
      location: $flat('ISRAEL'),
      region: 'Israel',
      linkedInUrl: 'https://www.linkedin.com/in/keren-gamrasni-a31436111/',
      emailUrl: 'mailto://keren@myorcare.com',
      imageSrc: KerenGImageSrc
    },
    {
      name: $('ESTHER_L_NAME'),
      role: $('ESTHER_L_ROLE'),
      location: $flat('ISRAEL'),
      region: 'Israel',
      linkedInUrl: 'https://www.linkedin.com/in/estherlevenson/',
      emailUrl: 'mailto://esther@myorcare.com',
      imageSrc: EstherLImageSrc
    },
    {
      name: $('ELIZABETH_B_NAME'),
      role: $('ELIZABETH_B_ROLE'),
      location: $flat('SPAIN'),
      region: 'Europe',
      linkedInUrl: 'https://www.linkedin.com/in/elizabeth-bannett-1b3267182/',
      emailUrl: 'mailto://elizabeth@myorcare.com',
      imageSrc: ElizabethBImageSrc
    },
    {
      name: $('TANIA_A_NAME'),
      role: $('TANIA_A_ROLE'),
      location: $flat('MEXICO'),
      region: 'NA',
      linkedInUrl: 'https://www.linkedin.com/in/tania-aguilar-aa900233/',
      emailUrl: 'mailto://tania.aguilar@myormexico.com',
      imageSrc: TaniaALImageSrc
    },
    {
      name: $('LETICIA_T_NAME'),
      role: $('LETICIA_T_ROLE'),
      location: $flat('MEXICO'),
      region: 'NA',
      linkedInUrl: 'https://www.linkedin.com/in/leticia-trevino-55442063/',
      emailUrl: 'mailto://leticia.trevino@myormexico.com',
      imageSrc: LeticiaTImageSrc
    },
    {
      name: $('JANA_P_NAME'),
      role: $('JANA_P_ROLE'),
      location: $flat('USA'),
      region: 'USA',
      linkedInUrl: 'https://www.linkedin.com/in/jana-pitcock-rdn-ld-30a033255/',
      emailUrl: 'mailto://jana@myorcare.com',
      imageSrc: JanaImageSrc
    },
    {
      name: $('HELEN_J_NAME'),
      role: $('HELEN_J_ROLE'),
      location: $flat('AUSTRALIA'),
      region: 'Australia',
      linkedInUrl: 'https://www.linkedin.com/in/helen-jackson-40b88259/',
      emailUrl: 'mailto://helen@myorcare.com',
      imageSrc: HelenJImageSrc
    },
    {
      name: $('TAVIERNEY_R_NAME'),
      role: $('TAVIERNEY_R_ROLE'),
      region: 'NA',
      location: $flat('USA'),
      state: $('ILLINOIS'),
      linkedInUrl: 'https://www.linkedin.com/in/tavierney-rogan-0ba36018/',
      emailUrl: 'mailto://tavierney@myorcare.com',
      imageSrc: TavierneyRImageSrc
    }
  ], [$]);

  return (
    <div className="w-full flex flex-col items-center justify-center my-16">
    <div className="w-full max-w-content flex flex-col items-center px-8 gap-8">
      <Title className="mt-14 mb-8" text="Meet the Global Team Behind MyOr" />
      
      <div className="w-full flex flex-col items-center gap-8">
        
        <div className="text-lg font-bold text-zinc-900">
          Israel
        </div>
        
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {employees &&
            employees
              .filter((e) => e.region === 'Israel')
              .map((e, i) => <EmployeeCard employee={e} key={`il-eu-employee-${i}`} />)}
        </div>

      </div>

      <div className="w-full flex flex-col items-center gap-8">
        
        <div className="text-lg font-bold text-zinc-900">
          North America, Europe & Australia
        </div>

        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {employees &&
            employees
              .filter((e) => e.region === 'NA' || e.region === 'Australia' || e.region === 'Europe' || e.region === 'USA')
              .map((e, i) => <EmployeeCard employee={e} key={`na-au-employee-${i}`} />)}
        </div>
      </div>
    </div>
    </div>
  );
};
