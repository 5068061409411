import { FC } from 'react';
import { useLang } from '../../../../../Lang/useLang';

export const MyOrIntroduction: FC = () => {
  const { $ }: any = useLang();
  return (
    <div className="w-full bg-blue-light flex items-center justify-center px-12 py-36 md:pb-[86px] md:pt-[132px] md:px-16 bg-homepage-intro-bg md:bg-homepage-intro-bg-md bg-no-repeat bg-[position:top_10px_left,bottom_10px_right] md:bg-[position:top_right,bottom_left]">
     <p className='font-bold text-[29px] md:text-[40px] max-w-[1000px] text-center'>{$('HOME_INTRODUCTION')}</p>
    </div>
  );
};
