import React from 'react'
import c from 'classnames';
import { twMerge } from 'tailwind-merge';

interface TitleProps {
  className?: string;
  text: string;
}

export const Title: React.FC<TitleProps> = ({ className, text }) => {
    return (
    <div className={twMerge(
      'text-4xl font-bold mt-8 mb-16 text-center text-zinc-900',
      className
    )}>
     {text}
    </div>
  )
}