import React from 'react';
import { Employee } from '../../../../../../types/type';
import { Card } from '../../../../../UIKit/card/Card';
import {
  USAFlagIcon,
  SpainFlagIcon,
  MexicoFlagIcon,
  AustraliaFlagIcon,
  IsraelFlagIcon,
  LinkedinIcon,
  EmailCIcon
} from '../../../../../UIKit/icon/Icon';

interface EmployeeCardProps {
  employee: Employee;
}

export const EmployeeCard: React.FC<EmployeeCardProps> = ({ employee }) => {
  const getLocationFlag = () => {
    switch (employee.location) {
      case 'USA':
        return <USAFlagIcon className="w-5 h-5" />;
      case 'Spain':
        return <SpainFlagIcon className="w-5 h-5" />;
      case 'Mexico':
        return <MexicoFlagIcon className="w-5 h-5" />;
      case 'Australia':
        return <AustraliaFlagIcon className="w-5 h-5" />;
      default:
        return <IsraelFlagIcon className="w-5 h-5" />;
    }
  };

  return (
    <Card 
      className="w-full flex flex-col items-center p-5 gap-3 hover:shadow-lg transition-all "
    >
      <div className="w-full flex flex-col items-center gap-3">
        <img 
          className="flex w-20 h-20 rounded-full bg-cover shadow-md" 
          src={employee.imageSrc} alt='' 
          />
        <div className="flex flex-col items-center justify-center gap-1">
          <span className="text-sm text-center font-semibold text-zinc-900">
            {employee.name}
          </span>
          <span className="text-xs text-center text-zinc-600">
            {employee.role}
          </span>
        </div>

        <div className="w-full flex flex-row items-center justify-center my-2 gap-2 ">
          <div>{getLocationFlag()}</div>
          <span className="text-xs text-zinc-600">
            {employee.state || employee.location}
          </span>
        </div>
      </div>
     
      

      <div className="flex flex-row w-full items-center justify-center mt-auto p-2 gap-2 ">
        
        <div
          className="cursor-pointer"
          onClick={() => {
            window.location.href = employee.linkedInUrl;
          }}
        >
          <LinkedinIcon className="w-5 h-5 opacity-40 hover:opacity-100 transition-opacity" />
        </div>
        
        <div
          className="cursor-pointer"
          onClick={() => {
            window.location.href = employee.emailUrl;
          }}
        >
          <EmailCIcon className="w-5 h-5 opacity-40 hover:opacity-100 transition-opacity" />
        </div>
      </div>
    </Card>
  );
};
