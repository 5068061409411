import {
  BlogsSection,
  HeadSection,
  PhoneSection,
  QuestionsSection,
  RegulatorsSection,
  ScienceSection,
  StoriesSection,
  MyOrIntroduction,
  SupportingSection,
  PartnerSection,
  PartnerCTA,
  RecruitmentSection
} from './home-sections';
import { Breaker } from '../../UIKit/breaker/Breaker';
import { Footer } from '../../layout/footer/Footer';
import {useLocation} from 'react-router-dom';
import { useLayoutEffect } from 'react';

export function HomePage() {
const location = useLocation()

useLayoutEffect(() => {
  const destination = location.state?.destination;
    
  if (!!destination) {
    document.querySelector(destination).scrollIntoView({behavior: "smooth"})
  }
}, [location])

  return (
    <div className="home-page bg-white">
      <HeadSection />
      <MyOrIntroduction />
      <PhoneSection />
      <SupportingSection />
      <Breaker className='breaker-disable_gradient bg-aqua'/>
      <RegulatorsSection />
      <Breaker className='breaker-disable_gradient bg-aqua'/>
      <PartnerSection />
      <Breaker className='breaker-disable_gradient bg-aqua'/>
      <ScienceSection />
      <StoriesSection />
      <PartnerCTA />
      <Breaker className='breaker-disable_gradient hidden md:block bg-aqua'/>
      <BlogsSection />
      <RecruitmentSection />
      <QuestionsSection />
      <Footer />
    </div>
  );
};
