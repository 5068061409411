import React, { useEffect } from 'react';
import { Header } from '../../layout/header/Header';
import { Footer } from '../../layout/footer/Footer';
import { ContactForm } from './ContactForm';
import { observer } from '../../../utils/observer';

export const ContactPage: React.FC = () => {
  useEffect(() => {
    observer.shout("form_submission", 'contact us');
  }, [])
  return (
    <div className="contact-page">
      <Header className="bg-white" isWhite={false}/>
      <ContactForm />
      <Footer />
    </div>
  );
};
