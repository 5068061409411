import { useEffect, useState } from "react";
import { Line, Tag, TitleP } from "../../../../UIKit"
import { Rows } from "../../../../UIKit/Line/Line"
import styles from './TagsWrap.module.css';
import { api, routes } from "../../../../../helpers/api";

interface IProps {
    tag: string,
    title: string
}
interface ITags {
    id: string,
    title: string
}

export const TagsWrap = ({ tag, title }: IProps) => {
    const [tags, setTags] = useState<ITags[]>([]);

    useEffect(() => {
        api.get(routes.server + "/blogs/tags?prefix=" + tag)
            .then((resp: ITags[]) => setTags(resp))
    }, [])

    return (
        <div className={styles.container}>
            <Rows>
                <TitleP>{title}</TitleP>
                <div className={styles.box}>
                    <Line>
                        {
                            tags.map(({ title, id }) => (
                                <Tag key={id} text={title} checked thin />
                            ))
                        }
                    </Line>
                </div>
            </Rows>
        </div>
    )
}