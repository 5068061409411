import { FC } from "react"
import { useLang } from "../../../../Lang/useLang";
import { ContactForm } from "../../contact/ContactForm"


export const ProvidersHomeContact: FC = () => {
    const { $ }: any = useLang();

    return (
        <div className="bg-blue-light md:py-20">
            <ContactForm title={$('PROVIDERS_HOME_CONTACT_TITLE')} cardClassName="px-0 md:px-12 bg-transparent md:bg-white" />
        </div>
    )
}