import React from 'react';
import c from 'classnames';

import { useLang } from '../../../../../../../Lang/useLang';
import { useNavigate } from 'react-router-dom';
import { ScienceButton } from '../ScienceButton';
import { twMerge } from 'tailwind-merge';

interface ScienceDescriptionProps {
  index: number;
}

export const ScienceDescription: React.FC<ScienceDescriptionProps> = ({
  index
}) => {
  const { $ }: any = useLang();
  const navigator = useNavigate();
  
  const textsArray = [
    $('HOME_SCIENCE_DESC_1'),
    $('HOME_SCIENCE_DESC_2'),
    $('HOME_SCIENCE_DESC_3'),
    $('HOME_SCIENCE_DESC_4')
  ];

  function handleClickToRead(id:number) {
    navigator(`/blogs/${id}`)
  }

  return (
    <div className="w-full flex flex-col items-center justify-between mt-8 ">
      <div className="w-full block">
        <div 
          data-show={index === 1}
          className={twMerge(
            'w-full hidden opacity-0 flex-col items-center md:flex-row gap-4',
            'data-[show=true]:flex data-[show=true]:opacity-100 transition-all ease-in-out'
          )}
        >
          <span className="text-lg text-zinc-600 leading-relaxed">
            {textsArray[0]}
          </span>
          <ScienceButton
            onClick={() => handleClickToRead(6)}
          >
            {$('HOME_SCIENCE_ARTICLE_BTN')}
          </ScienceButton>
        </div>

        <div 
          data-show={index === 2}
          className={twMerge(
            'w-full hidden opacity-0 flex-col items-center md:flex-row gap-4',
            'data-[show=true]:flex data-[show=true]:opacity-100 transition-all ease-in-out'
          )}
        >
          <span className="text-lg text-zinc-600 leading-relaxed">
            {textsArray[1]}
          </span>
          <ScienceButton
            onClick={() => handleClickToRead(4)}
          >
            {$('HOME_SCIENCE_ARTICLE_BTN')}
          </ScienceButton>
        </div>
        
        <div 
          data-show={index === 3}
          className={twMerge(
            'w-full hidden opacity-0 flex-col items-center md:flex-row gap-4',
            'data-[show=true]:flex data-[show=true]:opacity-100 transition-all ease-in-out'
          )}
        >
          <span className="text-lg text-zinc-600 leading-relaxed">
            {textsArray[2]}
          </span>
          <ScienceButton
            onClick={() => handleClickToRead(5)}
          >
            {$('HOME_SCIENCE_ARTICLE_BTN')}
          </ScienceButton>
        </div>

        <div 
          data-show={index === 4}
          className={twMerge(
            'w-full hidden opacity-0 flex-col items-center md:flex-row gap-4',
            'data-[show=true]:flex data-[show=true]:opacity-100 transition-all ease-in-out'
          )}
        >
          <span className="text-lg text-zinc-600 leading-relaxed">
            {textsArray[3]}
          </span>
          <ScienceButton
            onClick={() => handleClickToRead(6)}
          >
            {$('HOME_SCIENCE_ARTICLE_BTN')}
          </ScienceButton>
        </div>
      </div>
      
      {/* <div className="relative inset-0 flex items-center justify-center mt-20 gap-4">
        {textsArray.map((text, i) => (
          <div
            data-position={i + 1 === index}
            className={twMerge(
              'flex w-5 h-5 rounded-full bg-white border border-primary-500',
              'data-[position=true]:bg-primary-500 md:hidden'
            )}
            key={i + 1}
          />
        ))}
      </div> */}
    </div>
  );
};
