import React from 'react';
import { twMerge } from 'tailwind-merge';

interface TextAreaProps {
  className?: string;
  placeholder?: string;
  name?: string;
  register?: any;
  error?: any;
}

export const TextArea: React.FC<TextAreaProps> = ({
  className,
  placeholder,
  name,
  register,
  error
}) => {
  return (
    <>
      <textarea
        placeholder={placeholder}
        className={twMerge(
          'h-40 flex border-[1px] border-zinc-300 rounded-lg p-3 outline-none ',
          'focus:border-primary-500 placeholder:text-zinc-400',
          className
        )}
        {...register(name || '')}
      />
      {error && <div className="absolute text-xs mt-1 text-red-600">{error.message}</div>}
    </>
  );
};
