import z from 'zod';
import { emailRegex, phoneRegex } from '../helpers/regex';

const root = {
    appointmentFor: z.object({ value: z.string(), label: z.string() }),
    fullName: z.string().min(1, 'Invalid Name'),
    phoneNumber: z.string().regex(phoneRegex, 'Invalid Number'),
    email: z.string().regex(emailRegex, 'Invalid Email'),
    topics: z.array(z.object({ value: z.string(), label: z.string() })),
    specificDietitian: z.string().optional(),
}

const aus = {
    state: z.object({ value: z.string(), label: z.string() }),
    insurance: z.object({ value: z.string(), label: z.string() }),
}
export const appointmentSchema = z.object({ ...root, ...aus }).or(z.object({ ...root }));

export type AppointmentSchemaType = z.infer<typeof appointmentSchema>;
