import { BannerTopWhite, Center, H1, H2, Inner, MarginHeader, MarginTitle, P } from "../../../UIKit"
import { Rows } from "../../../UIKit/Line/Line"
import nextStepsCoverPng from '../../../../assets/images/next-steps-cover.png';
import { useLang } from "../../../../Lang/useLang";

export const WhatNext = () => {
    const { $ } = useLang();

    return (
        <BannerTopWhite>
            <Rows>
                <MarginTitle>
                    <H1>{$('POST_HEADLINE')}</H1>
                </MarginTitle>
                <Inner thin>
                    <img src={nextStepsCoverPng} alt="" />
                </Inner>
                <MarginHeader>
                    <Center>
                        <H2>{$('POST_SUB_TITLE')}</H2>
                    </Center>
                </MarginHeader>
                <Center>
                    <P>{$('POST_HEAD_CONTENT')}</P>
                </Center>
            </Rows>
        </BannerTopWhite>

    )
}