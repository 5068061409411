import React from 'react';
import { twMerge } from 'tailwind-merge';

interface InputProps {
  className?: string;
  placeholder?: string;
  name?: string;
  register?: any;
  error?: any;
}

export const Input: React.FC<InputProps> = ({
  className,
  placeholder,
  name,
  register,
  error
}) => {
  return (
    <div className='w-full flex relative'>
      <input
        type="text"
        placeholder={placeholder}
        className={twMerge(
          'w-full flex h-8 border-[1px] border-zinc-300 rounded-lg p-3 outline-none',
          'placeholder:text-zinc-400 focus:border-primary-500 ',
          className
          )}
        {...register(name || '')}
      />
      {error && <div className="absolute bottom-0 translate-y-[100%] text-xs mt-1 text-red-600">{error.message}</div>}
    </div>
  );
};
