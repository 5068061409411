import React from 'react';
import { useLang } from '../../../../Lang/useLang';
import { Title } from '../../../UIKit/title/Title';

export const ProvidersHIWHeadSection: React.FC = () => {
  const { $ }: any = useLang();
  return (
    <div className='w-full max-w-content md:h-[477px] flex md:items-center bg-providers-hiw-bg md:bg-providers-hiw-bg-md bg-no-repeat bg-right-top md:bg-right mx-auto'>
        <div className='md:w-1/2 max-w-[800px] ml-0 md:ml-20 lg:ml-40 mt-[200px] py-8 md:mt-0 px-6 md:px-0'>
            <Title className='text-left text-[38px] leading-[46px] md:text-[54px] md:leading-[66px] mt-0 mb-3' text={$('PROVIDERS_HIW_HEADER_TITLE')}/>
            <p className='max-w-[620px] text-2xl md:text-lg '>{$('PROVIDERS_HIW_HEADER_SUBTITLE')}</p>
        </div>
    </div>
  );
};
