import React from 'react';
import { useLang } from '../../../../Lang/useLang';
import { Title } from '../../../UIKit/title/Title';
import scienceMapMdPNG from '../../../../assets/images/science-map-md.png'
import scienceMapPNG from '../../../../assets/images/science-map.png'
import { ReactComponent as CESvg } from '../../../../assets/svgs/CE.svg';
import { ReactComponent as FDASvg } from '../../../../assets/svgs/FDA.svg';
import { ReactComponent as TGASvg } from '../../../../assets/svgs/TGA.svg';
import { ReactComponent as USSvg } from '../../../../assets/svgs/usFlag.svg';
import { ReactComponent as AustraliaSvg } from '../../../../assets/svgs/australia-flag.svg';
import { ReactComponent as CanadaSvg } from '../../../../assets/svgs/canada-flag.svg';
import { ReactComponent as UKSvg } from '../../../../assets/svgs/uk-flag.svg';
import { ReactComponent as EUSvg } from '../../../../assets/svgs/eu-flag.svg';
import nihLogoPNG from '../../../../assets/images/nih-logo.png';
import aapLogoPNG from '../../../../assets/images/aap-logo.png';
import aaaaiLogoPNG from '../../../../assets/images/aaaai-logo.png';
import acaaiLogoPNG from '../../../../assets/images/acaai-logo.png';
import cpsLogoPNG from '../../../../assets/images/cps-logo.png';
import asciaLogoPNG from '../../../../assets/images/ascia-logo.png';
import bsaciLogoPNG from '../../../../assets/images/bsaci-logo.png';
import eaaciLogoPNG from '../../../../assets/images/eaaci-logo.png';
import espghanLogoPNG from '../../../../assets/images/espghan-logo.png';



export const AllergenPrevention: React.FC = () => {
    const { $ }: any = useLang();

    const items = [
        {
            icon: <CESvg />,
            title: $('SCIENCE_ALLERGY_1')
        },
        {
            icon: <FDASvg />,
            title: $('SCIENCE_ALLERGY_2')
        },
        {
            icon: <TGASvg />,
            title: $('SCIENCE_ALLERGY_3')
        },
    ]

    const points = [
        {
            flag: <USSvg />,
            logos: [<img key={nihLogoPNG} alt='' src={nihLogoPNG} />],
            text: $('SCIENCE_ALLERGEN_1_TEXT')
        },
        {
            flag: <USSvg />,
            logos: [<img key={aapLogoPNG} alt='' src={aapLogoPNG} />],
            text: $('SCIENCE_ALLERGEN_2_TEXT')
        },
        {
            flag: <USSvg />,
            logos: [
                <img key={aaaaiLogoPNG} alt='' src={aaaaiLogoPNG} />, 
                <img key={acaaiLogoPNG} alt='' src={acaaiLogoPNG} />
            ],
            text: $('SCIENCE_ALLERGEN_3_TEXT')
        },
        {
            flag: <CanadaSvg />,
            logos: [<img key={cpsLogoPNG} alt='' src={cpsLogoPNG} />],
            text: $('SCIENCE_ALLERGEN_4_TEXT')
        },
        {
            flag: <AustraliaSvg />,
            logos: [<img key={asciaLogoPNG} alt='' src={asciaLogoPNG} />],
            text: $('SCIENCE_ALLERGEN_5_TEXT')
        },
        {
            flag: <UKSvg />,
            logos: [<img key={bsaciLogoPNG} alt='' src={bsaciLogoPNG} />],
            text: $('SCIENCE_ALLERGEN_6_TEXT')
        },
        {
            flag: <EUSvg />,
            logos: [<img key={eaaciLogoPNG} alt='' src={eaaciLogoPNG} />],
            text: $('SCIENCE_ALLERGEN_7_TEXT')
        },
        {
            flag: <EUSvg />,
            logos: [<img key={espghanLogoPNG} alt='' src={espghanLogoPNG} />],
            text: $('SCIENCE_ALLERGEN_8_TEXT')
        },
    ]

  return (
    <div className='w-full max-w-content flex flex-col items-center pb-16 pt-28 px-6 mx-auto'>
        <Title className='text-[29px] md:text-[40px] m-0 mb-20' text={$('SCIENCE_ALLERGEN_TITLE')}/>
        <img alt='' src={scienceMapMdPNG} className="w-full max-w-[1100px] hidden md:block"/>
        <img alt='' src={scienceMapPNG} className="w-full block md:hidden"/>
        <div className='flex flex-col gap-y-4 mt-6 md:hidden'>
            {
                points.map(({ flag, logos, text}, index) => (
                    <div key={index} className="p-4 flex gap-x-4 border border-primary-500 rounded-lg">
                        {flag}
                        <div className='w-full'>
                            <div className='flex justify-between'>{logos}</div>
                            <p className='text-[10px] mt-1.5'>{text}</p>
                        </div>
                    </div>
                ))
            }
        </div>
        <div className='w-full max-w-[1185px] bg-blue-light rounded-xl flex flex-col md:flex-row gap-y-5 justify-around py-6 mt-14'>
            {
                items.map(({ icon, title }, index) => (
                    <div className='flex flex-col items-center h-32' key={index}>
                        <div className='h-20 flex items-center'>{icon}</div>
                        <h5 className='text-xl font-medium'>{title}</h5>
                    </div>
                ))
            }
        </div>
    </div>
  );
};