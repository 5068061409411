import React from 'react';
import { useLang } from '../../../../Lang/useLang';
import { Title } from '../../../UIKit/title/Title';

export const ScienceIntroduction: React.FC = () => {
    const { $ }: any = useLang();

    const statistic = [
        {
            percent: '100K+',
            text: $('SCIENCE_INTRO_1'),
        },
        {
            percent: '8',
            text: $('SCIENCE_INTRO_2'),
        },
        {
            percent: '83%',
            text: $('SCIENCE_INTRO_3'),
        },
        {
            percent: '1M+',
            text: $('SCIENCE_INTRO_4'),
        },
    ]

  return (
    <div className='w-full max-w-content flex flex-col pb-16 pt-28 px-6 mx-auto md:bg-science-bg-md bg-no-repeat md:bg-[position:top_40px_right]'>
        <div className='w-full max-w-[992px] mx-auto'>
            <Title className='text-[40px] elading[46px] md:text-[60px] md:leading-[80px] m-0 text-left' text={$('SCIENCE_INTRO_TITLE')}/>
            <p className='text-2xl text-left max-w-[800px] md:mt-8 mb-7'>{$('SCIENCE_INTRO_SUBTITLE')}</p>
        </div>
        <div className='max-w-[1210px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-20 mb-16 xs:mx-auto'>
            {
                statistic.map(({ percent, text }, index) => (
                    <div className='text-center flex flex-col justify-center items-center w-full xs:max-w-[260px] h-[230px] bg-blue-light rounded-2xl' key={index}>
                        <h6 className='text-[63px] text-primary-500 font-bold'>{percent}</h6>
                        <p className='font-bold xs:max-w-[230px]'>{text}</p>
                    </div>
                ))
            }
        </div>
    </div>
  );
};