import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { ChevronDown } from 'lucide-react';

// TODO: types

export const FormDropDown: React.FC<any> = ({ placeholder = "Select...", error, className, selectedOption, options = [], label, handleChange, hideOnSelect }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [selected, setSelected] = useState<any>(selectedOption)

    const handleSelect = (option: any) => {
        handleChange(option);
        setSelected(option)
        if(hideOnSelect) setIsOpen(false)
    }
  return (
      <div className={`w-full ${className}`}>
        <div className='label'>{label}</div>
        <div className='w-full relative'
            onBlur={() => setIsOpen(false)}
            tabIndex={0}
        >
            <div
                className={twMerge(
                    'w-full flex border-[1px] border-zinc-300 rounded-lg p-3 outline-none transition-colors duration-500',
                    isOpen && 'border-primary-500',
                )}
                onClick={() => setIsOpen(prevState => !prevState)}
            >
                {selected?.label || (<span className='text-gray-200'>{placeholder}</span>)}
                <ChevronDown className={twMerge('ml-auto transition-transform duration-500', isOpen && ('rotate-180'))}/>
            </div>
            {isOpen && (
                <div className={twMerge('absolute z-10 inset-0 translate-y-14 h-fit flex flex-col mt-0 border-[1px] border-primary-500 rounded-lg bg-white max-h-[200px] overflow-y-auto')}>
                        {options?.map((option: any) => (
                            <div key={option?.label} className='px-2.5 py-1.5 hover:bg-primary-500 hover:bg-opacity-50' onClick={() => handleSelect(option)}>{option?.label}</div>
                        ))}
                </div>
            )}
        </div>
    </div>
  );
};
