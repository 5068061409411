import { api, routes } from "../helpers/api"
import { useLang } from "../Lang/useLang";

export const useMarkdown = (key: string) => {
    const { langIndex } = useLang();
    const fetch = async () => {
        return await api.get(routes.server + `/markdown/landing/${langIndex}/${key}`)
    }

    return fetch;
}