import React from 'react';
import { useLang } from '../../../../Lang/useLang';
import { BannerTrans, AlignCenter, H2Thin } from '../../../UIKit';
import { TagsWrap } from '../uikit/tagsWrap/TagsWrap';


export const Support: React.FC = () => {
    const { $ }: any = useLang();

    return (
        <BannerTrans>
            <AlignCenter>
                <H2Thin>
                    {$('POST_SUPPORT_TITLE')}
                </H2Thin>
            </AlignCenter>
            <TagsWrap 
                tag="POST_SURVEY_SUP_TAG_"
                title={$('POST_SUPPORT_SUP_TITLE')}
            />
            <TagsWrap 
                tag="POST_SURVEY_EDU_TAG_"
                title={$('POST_SUPPORT_EDU_TITLE')}
            />
        </BannerTrans>
    );
};
