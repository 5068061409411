import { Num, P } from "../../../../UIKit";
import { Rows } from "../../../../UIKit/Line/Line"
import styles from './Step.module.css';

interface IProps {
    iconSrc: string,
    swiggleSrc: string,
    num: string,
    text: string,
}
export const Step = ({ iconSrc, swiggleSrc, num, text }: IProps) => {
    return (
        <div className={styles.swiggle} style={{ backgroundImage: `url(${swiggleSrc})` }}>
            <Rows addClass={styles.rows}>
                <div className={styles.icon} style={{ backgroundImage: `url(${iconSrc})` }} />
                <Num>{num}</Num>
                <div className={styles.text}>
                    <P>{text}</P>
                </div>
            </Rows>
        </div>
    )
}