import React from 'react';
import { useLang } from '../../../../Lang/useLang';
import { Title } from '../../../UIKit/title/Title';
import { ReactComponent as ArrowSVG } from '../../../../assets/svgs/arrow-right.svg'
import girlWithPhonePNG from '../../../../assets/images/girl-with-phone.png'
import myorLaptopPNG from '../../../../assets/images/myor-laptop.png'
import fatherSonPNG from '../../../../assets/images/father-son.png'
import { Link } from 'react-router-dom';

export const ProvidersHIWJoinUs: React.FC = () => {
    const { $ }: any = useLang();

    const steps = [
        {
            step: 'Step',
            title: $('PROVIDERS_HIW_TEAM_1_TITLE'),
            description: $('PROVIDERS_HIW_TEAM_1_SUBTITLE'),
            linkText: $('APPLY_NOW'),
            link: '/contact',
            image: girlWithPhonePNG
        },
        {
            step: 'Step',
            title: $('PROVIDERS_HIW_TEAM_2_TITLE'),
            description: $('PROVIDERS_HIW_TEAM_2_SUBTITLE'),
            linkText: $('GET_STARTED'),
            link: '/contact',
            image: myorLaptopPNG
        },
        {
            step: 'Step',
            title: $('PROVIDERS_HIW_TEAM_3_TITLE'),
            description: $('PROVIDERS_HIW_TEAM_3_SUBTITLE'),
            linkText: $('EXPAND_YOUR_PRACTICE'),
            link: '/contact',
            image: fatherSonPNG
        },
    ]

  return (
    <div className='w-full bg-blue-light flex flex-col items-center py-8 md:pt-32 px-6 mx-auto'>
        <Title className='text-[29px] md:text-[40px] mb-0 md:mb-16 mt-0' text={$('PROVIDERS_HIW_TEAM_TITLE')}/>
        <div className='max-w-[830px] w-full '>
            {
                steps.map(({ step, title, description, linkText, link, image }, index) => (
                    <div className='flex flex-col-reverse items-center md:flex-row justify-between py-7 md:py-16 gap-y-4' key={index}>
                        <div className='max-w-[400px] flex flex-col md:items-center md:block'>
                            <h6 className='text-[29px] md:text-[32px] text-primary-500 font-medium'>{step} {index+1}</h6>
                            <Title className='m-0 md:mt-4 md:mb-3.5 text-left text-[28px] md:text-[31px]' text={title} />
                            <p className='text-gray-300 mb-1 md:mb-5 text-left leading-[24px] text-xs md:text-base'>{description}</p>
                            <Link to={link} className='text-primary-500 text-lg md:text-xl font-bold flex items-center gap-x-2.5'>
                                {linkText}
                                <ArrowSVG className='self-end'/>
                            </Link>
                        </div>
                        <div className='min-w-[344px] flex justify-center'>
                            <img src={image}/>
                        </div>
                    </div>
                ))
            }
        </div>
    </div>
  );
};
