import { FC } from "react";
import { Link } from "react-router-dom";
import { useLang } from "../../../../../../../Lang/useLang";
import { ScienceButton } from "../ScienceButton";

export const ScienceSlide: FC<{science: any}> = ({ science }) => {
    const { $ }: any = useLang();
    return (
        <div className="max-w-[500px] flex flex-col gap-y-14">
            <img 
                src={science.imgSource} 
                className="mx-auto"
                alt="" 
            />
            <p className="text-lg">{science.text}</p>
            <ScienceButton
                className="!w-full"
            >
                <Link to={science.url} className="w-full text-left">
                    {$('HOME_SCIENCE_ARTICLE_BTN')}
                </Link>
          </ScienceButton>
        </div>
    )
}