import React from 'react';
import imageSource1 from '../../../../../assets/images/Device.png';
import imageSource2 from '../../../../../assets/images/phone-screen-form.png';
import { PhoneDescription } from './phone-description/PhoneDescription';
import { Button } from '../../../../UIKit/button/Button';
import { useLang } from '../../../../../Lang/useLang';
import { twMerge } from 'tailwind-merge';
import { Link } from 'react-router-dom';

export const PhoneSection: React.FC = () => {
  const { $, getSelectedLang }: any = useLang();
  return (
    <div className="w-full flex items-center justify-center px-8 py-12 md:px-16 overflow-hidden">
      <div className={twMerge(
        'w-full max-w-content flex flex-col items-center justify-center gap-y-6 md:gap-12 ',
        'md:flex-row '
      )}>
        <PhoneDescription />
        <div className='flex flex-col items-center overflow-x-hidden md:overflow-visible'>
          <div
            className={twMerge(
              'flex items-center justify-center relative overflow-hidden',
              'md:-bottom-16'
            )}
          >
            <div
              className={twMerge(
                'h-[355px] scale-105 w-[340px] min-w-[340px] z-10 absolute bottom-0 cover ',
                'rounded-md overflow-hidden'
              )}
            >
              <img src={imageSource2} alt="" />
            </div>
            <img className="w-[370px] min-w-[370px] " src={imageSource1} alt="" />
          </div>
          <div className='h-1 rounded-full bg-primary-500 w-[110%] z-50 md:hidden' />
        </div>

        <Link to={getSelectedLang().url} className="w-full md:w-fit">
          <Button
            className="cta_click w-full flex items-center justify-center rounded-2xl text-base font-semibold p-4 md:sr-only hover:bg-primary-400"
            position="HOME_PHONE_START_SURVEY"
          >
            {$('HOME_PHONE_START_SURVEY')}
          </Button>
        </Link>
      </div>
    </div>
  );
};
