import React, { useEffect, useMemo, useState } from 'react';
import { Blog1 } from './blogs-components/blog1';
import { Blog2 } from './blogs-components/blog2';
import { Blog3 } from './blogs-components/blog3';
import { Blog4 } from './blogs-components/blog4';
import { Blog5 } from './blogs-components/blog5';
import { Blog6 } from './blogs-components/blog6';
import { useParams } from 'react-router-dom';
import { Header } from '../../layout/header/Header';
import { Footer } from '../../layout/footer/Footer';
import { api, routes } from '../../../helpers/api';



import { useMarkdown } from "../../../hooks/useMarkdown"
import { useLang } from "../../../Lang/useLang"
import { MarkdownShow } from "../../UIKit/MarkdownShow/MarkdownShow"
import { BlogSection } from "./blogs-components/BlogSection"
import { twMerge } from 'tailwind-merge';

const getBlogByUrl = async (url: string | undefined) => {
  
  const response = await api.get(routes.server + `/blogs/${url}`)
  return response
}

export const BlogPage: React.FC = () => {
  const [data, setData] = useState<any>({})
    const { url } = useParams();
    const { title, cover, content }: { cover: string; title: string; content: string} = data;

    useEffect(() => {
      (async () => {
        if(!url) return;
        if(!isNaN(+url)) return;
        
        try {
          const res = await getBlogByUrl(url)
          setData(res);
        } catch(err) {
          console.log(err)
        }
        
      })()
    }, [])

  const currentBlog = useMemo(() => {
    switch (url) {
      case '1':
        return <Blog1 />;
      case '2':
        return <Blog2 />;
      case '3':
        return <Blog3 />;
      case '4':
        return <Blog4 />;
      case '5':
        return <Blog5 />;
      case '6':
        return <Blog6 />;
      default:
        return <Blog1 />;
    }
  }, [url]);

  if (!data?.title) return (
    <div>
    <Header isWhite className='absolute w-full z-50'/>
    {currentBlog}
    <Footer />
    </div>
  )

  return (
    <div>
      <Header isWhite className='absolute w-full z-50'/>
      {/*  */}

      <BlogSection
        title={title}
        bgClassName={`bg-center`}
        style={{ backgroundImage: `url(${cover})` }}
      >
        <div
          className={twMerge('MarkdownShow', 'flex flex-col gap-8')}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </BlogSection>

      {/*  */}
      <Footer />
    </div>
  );
};
