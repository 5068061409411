import React from "react"
import styles from './Tag.module.css';
import { ReactComponent as CheckSvg } from '../../../assets/svgs/check-small.svg';


interface IProps { 
    text: string,
    selected?: true,
    checked?: boolean,
    thin?: boolean
}
export const Tag: React.FC<IProps> = ({ text, selected, checked, thin }) => {
    return (
        <div className={[
            styles.container, 
            selected && styles.selected,
            thin && styles.thin
        ].join(' ')}>
            {checked && <CheckSvg />}
            {text}
        </div>
    )
}