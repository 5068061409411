import React from "react";

export function InformationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="73"
      height="72"
      fill="none"
      viewBox="0 0 73 72"
    >
      <g clipPath="url(#clip0_2709_10175)">
        <mask
          id="mask0_2709_10175"
          style={{ maskType: "luminance" }}
          width="73"
          height="73"
          x="0"
          y="-1"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M.667-.004h72v72h-72v-72z"></path>
        </mask>
        <g
          stroke="#01A5AF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          mask="url(#mask0_2709_10175)"
        >
          <path d="M71.612 47.54v8.47a4.22 4.22 0 01-4.219 4.219H5.94a4.22 4.22 0 01-4.219-4.22V20.202a4.22 4.22 0 014.22-4.219h12.115M55.28 15.98h12.115a4.22 4.22 0 014.22 4.22v22.417"></path>
          <path d="M18.056 20.201H6.362a.422.422 0 00-.422.422V56.01h61.031a.422.422 0 00.422-.422V20.623a.422.422 0 00-.422-.422H55.277M40.778 60.227v6.495M32.555 66.722v-6.495M43.166 66.722H30.17a3.797 3.797 0 00-3.797 3.797c0 .233.19.421.422.421h19.745a.422.422 0 00.422-.421c0-2.097-1.7-3.797-3.797-3.797z"></path>
          <path d="M18.057 56.01V5.27a4.219 4.219 0 014.219-4.218h28.782a4.219 4.219 0 014.218 4.218v50.74"></path>
          <path d="M29.225 9.853a12.38 12.38 0 017.442-2.475c6.862 0 12.445 5.583 12.445 12.445 0 6.863-5.583 12.446-12.445 12.446-6.863 0-12.446-5.583-12.446-12.446 0-2.304.63-4.465 1.726-6.317"></path>
          <path d="M34.541 25.33c-.72 0-1.44-.274-1.988-.823l-2.337-2.336a2.11 2.11 0 012.983-2.983l1.044 1.044a.422.422 0 00.597 0l5.252-5.252c.803-.804 2.111-.878 2.951-.113a2.11 2.11 0 01.074 3.053l-6.587 6.587a2.804 2.804 0 01-1.989.822zM24.523 39.16h24.288M24.523 45.487h24.288"></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2709_10175">
          <path
            fill="#fff"
            d="M0 0H72V72H0z"
            transform="translate(.667)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}
