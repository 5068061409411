import React from 'react';
import { twMerge } from 'tailwind-merge';

interface PProps {
  className?: string;
  children: React.ReactNode;
  title?: string;
  bold?: boolean;
}

export const P: React.FC<PProps> = ({ className, children, title, bold = false }) => {
  return (
    <p className={twMerge(
      'text-zinc-600 text-justify text-lg',
      className, 
      bold && 'font-bold')}>
      <span className='font-bold'>
        {title}
      </span>
      {children}
    </p>
  )
};
