import React from 'react';
import './Breaker.css';
import c from 'classnames';

interface BreakerProps {
  className?: string;
  vertical?: boolean;
}

export const Breaker: React.FC<BreakerProps> = ({ className, vertical }) => {
  return <div className={c(`breaker-${vertical ? "vertical" : "horizontal"}`, className)} />;
};
