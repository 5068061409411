import { useLang } from "../../../../Lang/useLang";
import { Card } from "../../../UIKit/card/Card";
import { Title } from "../../../UIKit/title/Title";
import { GuideIcon } from "./icons/Guide";
import { InformationIcon } from "./icons/Information";
import { MindIcon } from "./icons/Mind";
import { ReportIcon } from "./icons/Report";
import { ResourceIcon } from "./icons/Resource";

export function WhatWeGiveYouSection() {
  const { $ }: any = useLang();

  return (
    <div className="w-full flex flex-col items-center justify-center relative py-16 ">
      <div className="w-full max-w-content flex flex-col items-center justify-center gap-8 px-12">
        <Title
          text={$("TODDLERS_WHAT_WE_GIVE_YOU_TITLE")}
          className="mb-12"
        />
        
        <div className="flex flex-col md:flex-row flex-wrap items-stretch justify-center mb-8 gap-8 ">
        
          <Card
            className="w-full flex flex-col md:w-[45%] lg:w-[30%] items-center justify-center p-4 gap-2"
          >
            <InformationIcon />
            <span className="text-zinc-600 text-lg text-center">
              {$("TODDLERS_WHAT_WE_GIVE_YOU_CARD_1_TEXT")} <b className="font-bold">{$("TODDLERS_WHAT_WE_GIVE_YOU_CARD_1_TEXT_2")}</b> {$("TODDLERS_WHAT_WE_GIVE_YOU_CARD_1_TEXT_3")}
            </span>
          </Card>
          <Card
            className="w-full flex flex-col md:w-[45%] lg:w-[30%] items-center justify-center p-4 gap-2"
          >
            <ReportIcon />
            <span className="text-zinc-600 text-lg font-bold text-center">
              {$("TODDLERS_WHAT_WE_GIVE_YOU_CARD_2_TEXT")}
            </span>
          </Card>
          <Card
            className="w-full flex flex-col md:w-[45%] lg:w-[30%] items-center justify-center p-4 gap-2"
          >
            <MindIcon />
            <span className="text-zinc-600 text-lg text-center">
              <b className="font-bold">{$("TODDLERS_WHAT_WE_GIVE_YOU_CARD_3_TEXT_1")}</b> {$("TODDLERS_WHAT_WE_GIVE_YOU_CARD_3_TEXT_2")}
            </span>
          </Card>
          <Card
            className="w-full flex flex-col md:w-[45%] lg:w-[30%] items-center justify-center p-4 gap-2"
          >
            <GuideIcon />
            <span className="text-zinc-600 text-lg text-center">
              <b className="font-bold">{$("TODDLERS_WHAT_WE_GIVE_YOU_CARD_4_TEXT_1")}</b> {$("TODDLERS_WHAT_WE_GIVE_YOU_CARD_4_TEXT_2")}
            </span>
          </Card>
          <Card
            className="w-full flex flex-col md:w-[45%] lg:w-[30%] items-center justify-center p-4 gap-2"
          >
            <ResourceIcon/>
            <span className="text-zinc-600 text-lg text-center">
              <b className="font-bold">{$("TODDLERS_WHAT_WE_GIVE_YOU_CARD_5_TEXT_1")}</b> {$("TODDLERS_WHAT_WE_GIVE_YOU_CARD_5_TEXT_2")}
            </span>
          </Card>

        </div>

      </div>
    </div>
  )
}