import React from 'react';
import { Title } from '../../../../UIKit/title/Title';
import imgSource1 from '../../../../../assets/images/blog1.png';
import imgSource2 from '../../../../../assets/images/blog2.png';
import imgSource3 from '../../../../../assets/images/blog3.png';
import './BlogsSection.css';
import { useLang } from '../../../../../Lang/useLang';
import { Link } from 'react-router-dom';

export const BlogsSection: React.FC = () => {
  const { $ }: any = useLang();
  const blogs = [
    {
      title: $('HOME_BLOG_1_TITLE'),
      imgSource: imgSource1,
      url: '/blogs/1'
    },
    {
      title: $('HOME_BLOG_2_TITLE'),
      imgSource: imgSource2,
      url: '/blogs/2'
    },
    {
      title: $('HOME_BLOG_3_TITLE'),
      imgSource: imgSource3,
      url: '/blogs/3'
    }
  ];
  return (
    <div className="blogs-section">
      <div className="blogs-title-container">
        <Title className="blogs-title" text={$('HOME_BLOGS_TITLE')} />
        <div className="blogs-see-all">
          <Link to='/blogs'>
            {$('HOME_BLOGS_SEE_ALL')}
          </Link>
        </div>
      </div>
      <div className="blogs-container">
        {blogs &&
          blogs.map((blog, i) => (

            <div
              key={`blog-${i}`}
              style={{
                backgroundImage: `url(${blog.imgSource})`,
                backgroundSize: 'cover'
              }}
              className={`blog blog-${i + 1}`}
            >

              <a href={blog.url} className='blog-link' >
                <div className="blog-content">
                  <div className="blog-content-text">
                    {blog.title}
                  </div>
                </div>
              </a>

            </div>
          ))}
        <div className="blogs-see-all-mobile">
          <Link to='/blogs'>
            {$('HOME_BLOGS_SEE_ALL')}
          </Link>
        </div>
      </div>
    </div>
  );
};
