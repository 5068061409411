import React from 'react';
import { Header } from '../../layout/header/Header';
import { Footer } from '../../layout/footer/Footer';
import { useLang } from '../../../Lang/useLang';
import { SkeletonLoading } from '../../layout/SkeletonLoading';
import { MarkdownShow } from '../../UIKit/MarkdownShow/MarkdownShow';
import { useMarkdown } from '../../../hooks/useMarkdown';


export const PrivacyPolicyPage: React.FC = () => {
  const { loading }: any = useLang();
  const fetchPPcontent = useMarkdown('PRIVACY_POLICY')

  return (
    <>
    { loading ? (
      <SkeletonLoading /> 
    ): (
      <div className="w-full bg-white flex h-screen flex-col items-center">   
        <Header />
        <div className="w-full max-w-content px-12 flex flex-col gap-8">
          <MarkdownShow fetch={fetchPPcontent} className="privacy_policy flex flex-col gap-8 mt-8" />
        </div>
        <Footer />
      </div>

    )}
    </>
  );
};
