import React, { Fragment } from 'react';
import { Breaker } from '../../UIKit/breaker/Breaker';
import { Logo } from '../../UIKit/logo/Logo';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  YoutubeIcon
} from '../../UIKit/icon/Icon';
import { useLang } from '../../../Lang/useLang';
import { getStoredLocation } from '../../../helpers/locationStorage';
import { twMerge } from 'tailwind-merge';
import { FooterBlock } from './FooterBlock';
import { FooterLink } from './FooterLink';
import { useMediaQuery } from 'react-responsive'

export const Footer: React.FC = () => {
  const { $ }: any = useLang();
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  const currentYear = new Date().getFullYear()

  const blocks = [
    {
      title: $('FOOTER_PARENTS_TITLE'),
      links: [
        {
          text: $('FOOTER_HOME'),
          slug: '/'
        },
        {
          text: $('FOOTER_HOW_IT_WORKS'),
          slug: '/parents/how-it-works'
        },
        {
          text: $('FOOTER_PRODUCT_EDUCATIONAL_RESOURCES'),
          slug: '/blogs'
        },
        {
          text: $('HEADER_LINK_DROP_CARE'),
          slug: '/appointment'
        },
      ]
    },
    {
      title: $('FOOTER_PROVIDERS_TITLE'),
      links: [
        {
          text: $('FOOTER_HOME'),
          slug: '/providers'
        },
        {
          text: $('FOOTER_HOW_IT_WORKS'),
          slug: '/providers/how-it-works'
        },
        {
          text: $('FOOTER_PROVIDERS_PORTAL'),
          slug: `${process.env.REACT_APP_RESULTS_PORT}`
        },
        {
          text: $('FOOTER_JOIN'),
          slug: '/contact'
        },
      ]
    },
    {
      title: $('FOOTER_COMPANY_TITLE'),
      links: [
        {
          text: $('FOOTER_COMPANY_ABOUT_US'),
          slug: '/about'
        },
        {
          text: $('FOOTER_COMPANY_CONTACT_US'),
          slug: '/contact'
        },
        {
          text: $('FOOTER_COMPANY_PRESS_CENTER'),
          slug: '/'
        },
      ]
    },
    {
      title: $('FOOTER_CONTENT_TITLE'),
      links: [
        {
          text: $('FOOTER_CONTENT_EDUCATIONAL_RESOURCES'),
          slug: '/blogs'
        },
        {
          text: $('FOOTER_CONTENT_SCIENCE_RESEARCH'),
          slug: '/science'
        },
        {
          text: $('FOOTER_CONTENT_USER_MANUAL'),
          slug: '/user-manual'
        },
      ]
    },
    {
      title: $('FOOTER_PRIVACY_TITLE'),
      links: [
        {
          text: $('FOOTER_PRIVACY_PRIVACY_POLICY'),
          slug: '/privacy-policy'
        },
        {
          text: $('FOOTER_PRIVACY_TERMS_OF_USE'),
          slug: '/terms-of-service'
        },
        {
          text: $('FOOTER_PRIVACY_WITHDRAWAL_POLICY'),
          slug: '/withdrawal-policy'
        },
      ]
    },
  ]
  
  const iconContainer = (
    <div className="flex flex-row items-center justify-between cursor-pointer gap-3">
      <FacebookIcon
        className="w-8 h-8 hover:brightness-90 "
        onClick={() => {
          window.location.href = 'https://www.facebook.com/MyOrDiagnostics/';
        }}
      />
      <YoutubeIcon
        className="w-8 h-8 hover:brightness-90"
        onClick={() => {
          window.location.href = 'https://www.youtube.com/channel/UC22VqcDRcMU0ao4ZCM3zBZg';
        }}
      />
      <LinkedinIcon
        className="w-8 h-8 hover:brightness-90"
        onClick={() => {
          window.location.href = 'https://www.linkedin.com/company/myor';
        }}
      />
      <InstagramIcon
        className="w-8 h-8 hover:brightness-90"
        onClick={() => {
          window.location.href = 'https://www.instagram.com/myordiagnostics/';
        }}
      />
    </div>
  );

  const getUrlByLocation = (path: string) => {
    return `${path}${
      getStoredLocation() !== 0 ? `/${getStoredLocation()}` : ''
    }`;
  };

  
  return (
    <div className="w-full flex flex-col items-center bg-white mt-auto">
      <div className={twMerge(
        'w-full max-w-content flex flex-col items-center justify-between pt-16 pb-10 gap-2',
        'md:flex-row md:items-start'
      )}>

        <div className="w-full md:w-52 flex flex-col px-5 pb-5 gap-5 text-xs text-zinc-600">
          <Logo isBlack className="w-full" />
          <div className="w-full text-sm md:text-xs text-zinc-600 flex items-center justify-center">
            { $('FOOTER_LOGO_INFO') }
          </div>
          <div className="hidden md:flex">
            {iconContainer}
          </div>
        </div>
        {
          blocks.map(({ title, links }, blockIndex ) => (
            <Fragment key={blockIndex}>
              <Breaker vertical className="hidden md:flex opacity-30 h-[220px]" />
              <FooterBlock
                key={blockIndex}
                title={title}
              >
                <div className='flex flex-col gap-y-2 md:gap-y-4 mt-3 md:mt-6'>
                  {links.map(({ text, slug }, linkIndex) => (
                    <FooterLink
                      href={slug}
                      key={`${blockIndex} - ${linkIndex}`}
                    >
                      {text}
                    </FooterLink>
                  ))}
                </div>
              </FooterBlock>
            </Fragment>
          ))
        }
      </div>

      <Breaker className="w-full flex opacity-80 mx-8 mb-4" />

      {!isDesktop && iconContainer}

      <Breaker className="md:hidden w-full flex opacity-80 mx-8 mt-4 " />

      <div 
        className="text-zinc-600 text-sm m-2"
      >
        ©{currentYear} - MyOR | All right reserved | Barket 29, Zichron Yaakov, Israel
      </div>
    </div>
  );
};
