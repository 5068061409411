import React from 'react';
import { twMerge } from 'tailwind-merge';

interface SubtitleProps {
  className?: string;
  children: React.ReactNode;
}

export const Subtitle: React.FC<SubtitleProps> = ({ className, children }) => {
  return (
    <div className={twMerge(
        'text-xl font-semibold text-zinc-900',
        className
      )}
    >
      {children}
    </div>
  )
};
