import React from 'react'
import { Footer } from '../../layout/footer/Footer'
import { Header } from '../../layout/header/Header'
import { USAFlagIcon, IrelandFlagIcon, SpainFlagIcon, MexicoFlagIcon, AustraliaFlagIcon } from '../../UIKit/icon/Icon'
import { Title } from '../../UIKit/title/Title'
import { P } from '../../UIKit/paragraph/P'
import { UserManualCard } from './components/UserManualCard'


export const UserManual: React.FC = () => {
  return (
    <div className='flex flex-col w-full min-h-screen items-center justify-center bg-blur bg-cover bg-no-repeat '>
        <Header className='bg-white' />
        <section className='flex flex-col items-center justify-center px-8 mb-12 '>
          <div className="w-full max-w-[790px]">
            <div className="flex flex-col items-start mt-12 mb-8" >
              <Title
                className='mb-3 text-left'
                text='MyorCare Instructions for Use'
              />
              <P>
                Please download and read the instructions for use before using MyorCare
              </P>
            </div>

            <div className="space-y-3">
              <UserManualCard
                icon={USAFlagIcon}
                text="USA (English)"
                link="/pdf/usa-english-manual.pdf"
              />
              <UserManualCard
                icon={IrelandFlagIcon}
                text="Ireland (English)"
                link="/pdf/ireland-english-manual.pdf"
              />
              <UserManualCard
                icon={SpainFlagIcon}
                text="Spain (Español)"
                link="/pdf/spain-espanol-manual.pdf"
              />
              <UserManualCard
                icon={MexicoFlagIcon}
                text="Mexico (Español)"
                link="/pdf/mexico-espanol-manual.pdf"
              />
              <UserManualCard
                icon={AustraliaFlagIcon}
                text="Australia: Healthcare Provider (English)"
                link="/pdf/australia-english-healthcare-manual.pdf"
              />
              <UserManualCard
                icon={AustraliaFlagIcon}
                text="Australia: Specialist Dietitian (English)"
                link="/pdf/australia-english-dietitian-manual.pdf"
              />
              
              
            </div>
          </div>
       </section>
       
        <Footer /> 
    </div>
  )
}