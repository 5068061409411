import React, { useEffect, useState } from 'react';

import { Header } from '../../layout/header/Header';
import { Footer } from '../../layout/footer/Footer';
import { api, routes } from '../../../helpers/api';
import { useLang } from '../../../Lang/useLang';
import { BlogListingHeader } from './blog-catalog-components/BlogListingHeader';
import { EditorPicks } from './blog-catalog-components/EditorPicks';
import { ExploreArticles } from './blog-catalog-components/ExploreArticles';
import { LatestArticles } from './blog-catalog-components/LatestArticles';

const getBlogs = async (...params: any) => {
    const paramsString = params.join('&')

    const response = await api.get(routes.server + `/blogs?${paramsString}`)
    return response
}

const getTags = async () => {
    const response = await api.get(routes.server + "/blogs/tags")
    return response
}

export const BlogCatalog: React.FC = () => {
    const [blogs, setBlogs] = useState([])
    const [editorPicks, setEditorPicks] = useState([])
    const [tags, setTags] = useState([])
    const [selectedTag, setSelectedTag] = useState('');
    const [seletedPage, setSelectedPage] = useState(1);
    const [pageCount, setPageCount] = useState(1)
    const { $ } = useLang()

    useEffect(() => {
        (async () => {
            const [blogsResponse, tagsResponse, editorPicksResponse] = await Promise.all([getBlogs(), getTags(), getBlogs('limit=10000')])

            setBlogs(blogsResponse?.list || [])
            setEditorPicks(editorPicksResponse?.list.filter(({ editorsPick }: { editorsPick: boolean }) => editorsPick) || [])
            setPageCount(blogsResponse?.totalPages)
            setTags(tagsResponse)
        })()
    }, [])

    useEffect(() => {
        setSelectedPage(0);
        handlePageChange({ selected: 0 });
    }, [selectedTag])

    const handlePageChange = async ({ selected }: { selected: number }) => {
        const nextPage = selected + 1;
        const { list, totalPages } = await getBlogs(`page=${nextPage}&tag=${selectedTag}`)
        setBlogs(list)
        setPageCount(totalPages)
        setSelectedPage(nextPage);
    }

    const handleTagSelection = (tag: string) => setSelectedTag(selectedTag === tag ? '' : tag)

    console.log(selectedTag);

    return (
        <div className='w-full flex h-auto flex-col items-center bg-white'>
            <Header className='bg-white' />

            <BlogListingHeader />
            <EditorPicks blogs={editorPicks} />
            <ExploreArticles tags={tags} handleTagSelection={handleTagSelection} selectedTag={selectedTag} />
            <LatestArticles blogs={blogs} pageCount={pageCount} handlePageChange={handlePageChange} selectedTag={selectedTag} />

            <Footer />
        </div>
    );
};
