import { Link } from "react-router-dom"
import { Title } from "../../../../UIKit/title/Title"
import styles from './Blog.module.css';
import { useLang } from "../../../../../Lang/useLang";

interface IProps {
    cover: string,
    title: string,
    tags: string[],
    url: string
}
export const Blog = ({ cover, title, tags, url }: IProps) => {
    const { $ } = useLang();

    return (
        <Link to={url} key={url}>
            <div>
                <div 
                    className={styles.picWrap}
                    style={{
                        backgroundImage: `url(${cover})`
                    }}
                />
                <div className='text-[12px] sm:text-xs text-primary-500 flex flex-wrap gap-x-2 mb-1.5'>
                    {tags.map(tag => (<span>{$(tag)}</span>))}
                </div>
                <Title text={title} className="text-[16px] sm:text-lg font-medium text-left m-0 mb-1.5" />
            </div>
        </Link>
    )
}