import { ReactNode } from "react";
import { Title } from "../../../UIKit/title/Title";
import { twMerge } from "tailwind-merge";

interface BlogSectionProps {
  bgClassName: string;
  title: string;
  children: ReactNode;
  style?: any;
}

export function BlogSection({bgClassName, title, children, style}: BlogSectionProps) {
  return (
    <div className="flex flex-col items-center">
      <div 
        className={twMerge(
          'w-full h-[400px] md:h-[500px] items-start justify-center bg-no-repeat bg-cover',
          bgClassName
        )}
        style={style}
      >
        <div className="w-full flex items-center justify-center absolute h-[400px] md:h-[500px] opacity-50 z-10 bg-gradient-to-t from-black" />
        <div className="w-full max-w-content mx-auto h-full flex items-center justify-center px-12">
          <Title 
            text={title} 
            className="text-5xl text-white z-20" 
          />
        </div>
      </div>
      <div className="w-full flex flex-col max-w-content gap-8 px-12 py-16">
        {children}
      </div>
    </div>
  )
}