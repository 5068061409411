import React, { useState } from 'react';
import { ScienceImages } from './components/images/ScienceImages';
import { Indicator } from './components/indicator/Indicator';
import { ScienceDescription } from './components/description/ScienceDescription';
import { Title } from '../../../../UIKit/title/Title';
import { useLang } from '../../../../../Lang/useLang';
import { ScienceSlider } from './components/ScienceSlider';
import { useMediaQuery } from 'react-responsive'

export const ScienceSection: React.FC = () => {
  const [index, setIndex] = useState<number>(1);
  const { $ }: any = useLang();
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })

  const changeIndex = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="w-full flex flex-col items-center py-16 px-8">
      <div className="w-full flex flex-col items-center justify-center max-w-[1110px]">
        <Title className="mt-0 mb-0 md:mb-16 text-[29px] md:text-[40px]" 
          text={$("HOME_SCIENCE_TITLE")} 
        />
        {isDesktop ? (
          <>
            <div className="w-full ">
              <ScienceImages index={index} onClickImage={changeIndex} />
              <Indicator index={index} setIndex={changeIndex}/>
            </div>
            <ScienceDescription index={index} />
          </>
        ) : (
          <ScienceSlider />
        )
      }
      </div>
    </div>
  );
};
