import { ArrowDownToLine } from "lucide-react";
import { ElementType } from "react";
import { Link } from "react-router-dom";

interface UserManualCardProps {
  icon: ElementType
  link: string
  text: string
}

export function UserManualCard({ icon: Icon, link, text }: UserManualCardProps) {
  return (
    <div className='flex flex-row w-full items-center justify-between bg-white rounded-md p-5 shadow-sm'>
      <div className="flex flex-row items-center gap-3">
        <Icon className="h-6 w-6" />
        <span>{text}</span>
      </div>
      
        <Link
          className="flex flex-row items-center gap-3 font-bold text-base text-primary-500 hover:text-primary-500 "
          to={link}
          target="_blank"
          download
        >
          Download
          <ArrowDownToLine className="w-6 h-6" />
        </Link>
    </div>
  )
}