import React from 'react';
import { ArrowUpFullIcon } from '../icon/Icon';
import { twMerge } from 'tailwind-merge';

interface TooltipProps {
  className?: string;
  children?: React.ReactNode;
  content: React.ReactNode;
}

export const Tooltip: React.FC<TooltipProps> = ({
  className,
  children,
  content
}) => {
  return (
    <div 
      className={twMerge(
        'flex flex-col items-center relative',
      )}
    >
      {children}
      <div className={twMerge(
          'hidden justify-center w-[320px] absolute top-16 z-50',
          className
        )}
      >
        <ArrowUpFullIcon />
        <div 
          className="text-white bg-zinc-900 text-sm rounded-lg p-3 absolute top-3"
        >
          {content}
        </div>
      </div>
    </div>
  );
};
