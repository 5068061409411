import React from 'react';
import { Title } from '../../../UIKit/title/Title';
import { useLang } from '../../../../Lang/useLang';
import { Link } from 'react-router-dom';
import { Button } from '../../../UIKit/button/Button';

export const ProvidersHomeHeadSection: React.FC = () => {
  const { $ }: any = useLang();
  return (
    <div className="w-full bg-providers-home [background-position-x:60%] md:[background-position-x: none] md:bg-providers-home-md bg-cover bg-no-repeat h-[640px] md:h-[560px]">
      <div className="w-full max-w-[600px] h-full px-6 md:px-0 md:ml-40 flex flex-col pb-8 justify-end xs:justify-center">
        <Title 
          text={$("PROVIDERS_HOME_HEADER_TITLE")} 
          className="text-white text-left m-0 text-[36px] leading-[44px] md:text-[54px] md:leading-[66px]" 
        />
        <p className='text-white text-lg mt-2'>{$("PROVIDERS_HOME_HEADER_SUBTITLE")}</p>
        <Link to={'/contact'} className="mt-7 w-full md:w-fit">
            <Button
                    className="w-full flex items-center justify-center rounded-2xl !text-base font-bold !px-16 py-4  hover:bg-primary-400"
                >
                    {$('APPLY_NOW')}
                </Button>
          </Link>
      </div>
    </div>
  );
};
