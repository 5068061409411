import React, { useEffect, useMemo, useState } from 'react';
import image1 from '../../../../../../../assets/images/pediatrics-color.png';
import image2 from '../../../../../../../assets/images/annals-color.png';
import image3 from '../../../../../../../assets/images/pai-color.png';
import image4 from '../../../../../../../assets/images/allergy-color.png';
import c from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLang } from '../../../../../../../Lang/useLang';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { useMediaQuery } from 'react-responsive'
import { ScienceSlide } from '../ScienceSlide';

export const ScienceSlider: React.FC = () => {
  const [index, setIndex] = useState<number>(0);
  const [swiper, setSwiper] = useState<any>(null);
  const { $ } = useLang();
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  const slidesPerView = 1;
  
  const scienceItems: any = [
    {
      text: $('HOME_SCIENCE_DESC_1'),
      imgSource: image1,
      url: 'blogs/6'
    },
    {
      text: $('HOME_SCIENCE_DESC_2'),
      imgSource: image2,
      url: 'blogs/4'

    },
    {
      text: $('HOME_SCIENCE_DESC_3'),
      imgSource: image3,
      url: 'blogs/5'

    },
    {
      text: $('HOME_SCIENCE_DESC_4'),
      imgSource: image4,
      url: 'blogs/6'

    },
  ];  


  const sliderPositionElements = useMemo(() => {
    let elements = [];
    for (
      let i = 0;
      i < (scienceItems.length);
      i++
    ) {
      elements.push(
        <div
          className={c('w-3 h-3 border-2 border-primary-500 rounded-full ', i === index && 'bg-primary-500')}
          key={i}
        ></div>
      );
    }
    return elements;
  }, [scienceItems, isDesktop]);

  const onLeftArrowClickHandler = () => {
    if (index > 0) {
      setIndex((prev) => prev - 1);
    }
    if(swiper) swiper.slideTo(index-1)
  };

  const onRightArrowClickHandler = () => {
    if (index < scienceItems.length - 1) {
      setIndex((prev) => prev + 1);
    }
    if(swiper) swiper.slideTo(index+1)
  };

  useEffect(() => {
    const storiesElement = document.querySelector('.stories');
    const dragging = (e: any) => {
      storiesElement!.scrollLeft = e.pageX;
    };
    storiesElement?.addEventListener('mousemove', dragging);
  }, []);

  return (
    <div className="flex flex-col items-center gap-12">
      <div className="flex items-center pt-10 md:p-16 gap-8 md:mb-16">
        <button
          data-show={!isDesktop}
          className={`group flex items-center justify-center p-4 border hover:bg-primary-500 border-primary-500 rounded-xl cursor-pointer z-10 data-[show=true]:hidden transition-colors ${!index && 'opacity-0 pointer-events-none'}`}
          onClick={onLeftArrowClickHandler}
        >
          <ArrowLeft className="w-6 h-6 text-primary-500 group-hover:text-white " />
        </button>


        <Swiper
          className="flex w-[calc(100vw-100px)] md:w-[calc(100vw-340px)] md:max-w-[900px] gap-x-4"
          slidesPerView={slidesPerView}
          spaceBetween={10}
          onSwiper={setSwiper}
          onSlideChange={(slider: any) => {
            setIndex(slider.activeIndex);
          }}
        >
          {scienceItems &&
            scienceItems.map((science: any, index: number) => (
              <SwiperSlide key={index} className="flex justify-center">
                  <ScienceSlide science={science} />
              </SwiperSlide>
            ))}
        </Swiper>

        <button
          data-show={!isDesktop}
          className={`group flex items-center justify-center p-4 border hover:bg-primary-500 border-primary-500 rounded-xl cursor-pointer z-10 data-[show=true]:hidden transition-colors ${index+slidesPerView === scienceItems.length && 'opacity-0 pointer-events-none'}`}
          onClick={onRightArrowClickHandler}
        >
          <ArrowRight className="w-6 h-6 text-primary-500 group-hover:text-white " />
        </button>

      </div>
      <div className="flex gap-5 z-20">
        {sliderPositionElements.map((element: JSX.Element) => element)}
      </div>
    </div>
  );
};
