import React, { useState } from 'react';
import { ToggleElement } from './ToggleElement';

const testSwitchOptions = [
    {
        label: 'Me',
        value: 'Me'
    },
    {
        label: 'My Child',
        value: 'My Child'
    }
]
// TODO: types

export const SwitchElement: React.FC<any> = ({ handleChange = () => {}, selectedOption}) => {
    const [selected, setSelected] = useState(selectedOption || testSwitchOptions[0])

    const handleToggle = (option: any) => {
        setSelected(option)
        handleChange(option)
    }
  return (
      <div className='grid grid-cols-2 gap-x-3 border border-zinc-300 rounded-lg p-1.5'>
          {testSwitchOptions.map(option => (
            <ToggleElement 
                key={option.value}
                option={option} 
                controlled 
                handleToggle={handleToggle} 
                active={option.value === selected.value} 
                className="border-none justify-center py-2.5 text-gray-200 rounded-lg whitespace-nowrap" 
                activeClassName="text-white bg-opacity-100" 
            />
          ))}

      </div>
  );
};
