import React from 'react';
import { TryButton } from './TryButton';
import { LangCountryPicker } from '../langCountryPicker/langCountryPicker';
import { useMediaQuery } from 'react-responsive'

type ButtonGroupProps = {
  isMenuOpen?: boolean;
  isBlack?: boolean;
};

export const ButtonGroup: React.FC<ButtonGroupProps> = (props) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })

  return (
    <div className="flex gap-5">
      <LangCountryPicker {...props} />
      {isDesktop && (
        <TryButton />
      )}
    </div>

  )
};
