import React from 'react';
import { Link } from 'react-router-dom';
import { useLang } from '../../../../Lang/useLang';
import { IBlog } from '../../../../types/type';
import { Title } from '../../../UIKit/title/Title';
import { Blog } from '../uikit/blog/Blog';

export const EditorPicks: React.FC<{ blogs: IBlog[] }> = ({ blogs }) => {
  const { $ }: any = useLang()
  return (
    <div className='w-full max-w-content px-8 sm:block'>
            <Title text={$('BLOG_LISTING_EDITOR_PICKS')} className='m-0 text-3xl text-left my-12'/>
            <div className='grid grid-cols-2 lg:grid-cols-3 gap-7'>
                {
                    blogs.map((props: IBlog) => (
                        <Blog key={props._id} {...props} />
                    ))
                }

            </div>
        </div>
  );
};
