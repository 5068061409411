import React from 'react';
import { Title } from '../../../../UIKit/title/Title';
import { Questions } from './questions/Questions';
import { useLang } from '../../../../../Lang/useLang';

export const QuestionsSection: React.FC = () => {
  const { $ }: any = useLang();
  return (
    <div 
      id="question-section"
      className="flex flex-col items-center pt-12 pb-16 px-8 md:px-16"
    >
      <Title 
        className="text-[30px] md:text-[40px] leading-9 m-8 md:mb-16" 
        text={$("HOME_QUESTIONS_TITLE")} 
      />
      <Questions />
    </div>
  );
};
