import React from 'react';
import { useLang } from '../../../../Lang/useLang';
import { Title } from '../../../UIKit/title/Title';
import { Breaker } from '../../../UIKit/breaker/Breaker';

export const BlogListingHeader: React.FC = () => {
  const { $ }: any = useLang()
  return (
    <div className='w-full max-w-content px-8'>
        <div className='flex flex-col lg:flex-row gap-y-2 justify-between lg:items-center mb-4 mt-12'>
            <Title text={$('BLOG_LISTING_MYOR')} className='m-0 text-[30px] lg:text-6xl font-medium text-left'/>
            <p className='max-w-[500px] text-base lg:text-lg'>{$('BLOG_LISTING_HEADER_DESCRIPTION')}</p>
        </div>
        <Breaker className='breaker-disable_gradient bg-aqua mb-6'/>
    </div>
  );
};
