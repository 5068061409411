import { ReactNode } from "react"
import { Link } from "react-router-dom";

interface FooterLinkProps {
  href?: string
  children: ReactNode
}

export function FooterLink({href = "#", children}: FooterLinkProps) {
  return (
    <Link 
      to={href} 
      className="w-full flex cursor-pointer text-sm text-zinc-600 hover:text-zinc-900"
    >
      {children}
    </Link>
  )
}