import React from 'react';
import { twMerge } from 'tailwind-merge';

interface CardProps {
  className?: string;
  children?: React.ReactNode;
  onClick?: (e: any) => void;
  position?: string;
}

export const Card: React.FC<CardProps> = ({ className, children, onClick, position }) => {
  return (
    <div 
      onClick={onClick}
      className={twMerge(
        'bg-white rounded-2xl shadow-sm',
        className
      )}
      data-position={position}
    >
      {children}
    </div>
  )
};
