import React from 'react';
import { useLang } from '../../../../Lang/useLang';
import { Title } from '../../../UIKit/title/Title';
import { Link } from 'react-router-dom';
import { Button } from '../../../UIKit/button/Button';

export const Introduction: React.FC = () => {
  const { $ }: any = useLang();
  return (
    <div className='w-full max-w-content pt-56 md:pt-0 md:h-[383px] flex md:items-center bg-parents-md bg-right-top md:bg-parents bg-no-repeat md:bg-right'>
        <div className='md:w-1/2 max-w-[800px] ml-0 md:ml-20 lg:ml-40 mt-auto md:mt-0 px-6 md:px-0'>
            <Title className='text-left text-[40px] leading-[46px] md:text-[54px] md:leading-[66px] mt-0 mb-3' text={$('PARENTS_INTRO_TITLE')}/>
            <p className='max-w-[620px] text-2xl md:text-lg '>{$('PARENTS_INTRO_SUBTITLE')}</p>
            <Link to={'/'} className="block mt-14 md:hidden">
              <Button
                className="w-full flex items-center justify-center rounded-2xl !text-base font-bold px-6 py-4 hover:bg-primary-400"
              >
                {$('START_THE_FREE_SURVEY')}
              </Button>
            </Link>
        </div>
    </div>
  );
};
