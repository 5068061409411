import { ReactNode } from "react"
import styles from './banner.module.css';
import SwiggleLeftPath from '../../../assets/svgs/swiggle-left.svg'
import SwiggleRightPath from '../../../assets/svgs/swiggle-right.svg'

interface IProps {
    children: ReactNode
}

export const BannerTopWhite = ({ children }: IProps) => {
    return (
        <div className={[styles.containerRoot, styles.containerWrap].join(' ')}>
            <div className={styles.container}
                style={{
                    backgroundImage: `url(${SwiggleLeftPath}), url(${SwiggleRightPath})`
                }}
            >
                <div className={styles.content}>
                    {children}
                    <div className={styles.contentBorder}></div>
                </div>
            </div>
        </div>
    )
}

export const BannerBlue = ({ children }: IProps) => {
    return (
        <div className={[styles.containerRootBlue, styles.containerWrap].join(' ')}>
            <div className={styles.container}>
                <div className={styles.content}>
                    {children}
                    <div className={styles.contentBorder}></div>
                </div>
            </div>
        </div>
    )
}

export const BannerTrans = ({ children }: IProps) => {
    return (
        <div className={[styles.containerRootTrans, styles.containerWrap].join(' ')}>
            <div className={styles.container}>
                <div className={styles.content}>
                    {children}
                    <div className={styles.contentBorder}></div>
                </div>
            </div>
        </div>
    )
}