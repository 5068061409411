import React, { ReactNode } from 'react';
import styles from './Inner.module.css';

interface IProps {
    children: ReactNode
    thin?: boolean
}

export const Inner: React.FC<IProps> = ({ thin, children }) => {

    return (
        <div className={[
            styles.container,
            thin && styles.thin
        ].join(' ')}>
            {children}
        </div>
    )
}

export const MarginTitle = ({ children }: IProps) => {
    return (
        <div className={styles.marginTitle}>
            {children}
        </div>
    )
}

export const MarginHeader = ({ children }: IProps) => {
    return (
        <div className={styles.marginHeader}>
            {children}
        </div>
    )
}

export const Center = ({ children }: IProps) => {
    return (
        <div className={styles.center}>
            {children}
        </div>
    )
}

export const AlignCenter = ({ children }: IProps) => {
    return (
        <div className={styles.alignCenter}>
            {children}
        </div>
    )
}