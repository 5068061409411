import './Line.css';

interface IProps {
    children?: any;
    addClass?:string;
}

export const Line = ({ children, addClass }: IProps) => {
    return (
        <div className={`Line ${addClass || ''}`}>
            {children}
        </div>
    )
}

export const Rows = (props: IProps) => {
    return <Line {...props} addClass={["rows", props.addClass || ''].join(' ')} />
}

export const RowsStart = (props: IProps) => {
    return <Line {...props} addClass="rows start" />
}

export const RowsShort = (props: IProps) => {
    return <Line {...props} addClass="rows short" />
}

export const RowsMedium = (props: IProps) => {
    return <Line {...props} addClass="rows medium" />
}

export const RowsHigh = (props: IProps) => {
    return <Line {...props} addClass="rows high" />
}

export const RowsFull = (props: IProps) => {
    return <Line {...props} addClass="rows full" />
}

export const RowsFullShort = (props: IProps) => {
    return <Line {...props} addClass="rows full short" />
}

export const RowsFullHigh = (props: IProps) => {
    return <Line {...props} addClass="rows full high" />
}

export const Between = (props: IProps) => {
    return <Line {...props} addClass="between" />
}

export const End = (props: IProps) => {
    return <Line {...props} addClass="end" />
}

export const LineCenter = (props: IProps) => {
    return <Line {...props} addClass="center" />
}

export const Middle = (props: IProps) => {
    return <Line {...props} addClass="middle" />
}

export const CenterAlign = (props: IProps) => {
    return <Line {...props} addClass="center-align" />
}

export const StartAlign = (props: IProps) => {
    return <Line {...props} addClass="start-align" />
}

export const Spread = (props: IProps) => {
    return <Line {...props} addClass="spread" />
}

export const SpreadEven = (props: IProps) => {
    return <Line {...props} addClass="spread-even" />
}

export const SpreadEnd = (props: IProps) => {
    return <Line {...props} addClass="spread-end" />
}

export const Short = (props: IProps) => {
    return <Line {...props} addClass="short" />
}

export const SelfStart = (props: IProps) => {
    return <Line {...props} addClass="self-start" />
}

export const SelfEnd = (props: IProps) => {
    return <Line {...props} addClass="self-end" />
}

export const NoWrap = (props: IProps) => {
    return <Line {...props} addClass="no-wrap" />
}

export const EvenBetween = (props: IProps) => {
    return <Line {...props} addClass="even-between" />
}