import styles from './Tag.module.css';

interface IProps {
    title: string;
    isSelected: boolean;
    onClick: () => void;
}

export const Tag = ({ title, onClick, isSelected }: IProps) => {
    return (
        <div className={[
            styles.container, 
            isSelected && styles.selected
        ].join(' ')} onClick={onClick}>
            {title}
        </div>
    )
}