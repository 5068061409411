import React, { useEffect, useMemo, useState } from 'react';
import c from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLang } from '../../../../Lang/useLang';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { useMediaQuery } from 'react-responsive'
import { ScienceButton } from '../../home/home-sections/science/components/ScienceButton';
import { Link } from 'react-router-dom';

export const SciencePublicationsSlider: React.FC<{ publications: any }> = ({ publications = []}) => {
  const [index, setIndex] = useState<number>(0);
  const [swiper, setSwiper] = useState<any>(null);
  const { $ } = useLang();
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  const slidesPerView = 1;

  const sliderPositionElements = useMemo(() => {
    let elements = [];
    for (
      let i = 0;
      i < (publications.length);
      i++
    ) {
      elements.push(
        <div
          className={c('w-3 h-3 border-2 border-primary-500 rounded-full ', i === index && 'bg-primary-500')}
          key={i}
        ></div>
      );
    }
    return elements;
  }, [publications, isDesktop, index]);

  const onLeftArrowClickHandler = () => {
    if (index > 0) {
      setIndex((prev) => prev - 1);
    }
    if(swiper) swiper.slideTo(index-1)
  };

  const onRightArrowClickHandler = () => {
    if (index < publications.length - 1) {
      setIndex((prev) => prev + 1);
    }
    if(swiper) swiper.slideTo(index+1)
  };

  useEffect(() => {
    const storiesElement = document.querySelector('.stories');
    const dragging = (e: any) => {
      storiesElement!.scrollLeft = e.pageX;
    };
    storiesElement?.addEventListener('mousemove', dragging);
  }, []);

  return (
    <div className="flex flex-col items-center gap-12">
      <div className="flex items-center pt-10 md:p-16 gap-8 md:mb-16">
        <button
          data-show={!isDesktop}
          className={`group flex items-center justify-center p-4 border hover:bg-primary-500 border-primary-500 rounded-xl cursor-pointer z-10 data-[show=true]:hidden transition-colors ${!index && 'opacity-0 pointer-events-none'}`}
          onClick={onLeftArrowClickHandler}
        >
          <ArrowLeft className="w-6 h-6 text-primary-500 group-hover:text-white " />
        </button>


        <Swiper
          className="flex w-[calc(100vw-40px)] md:w-[calc(100vw-340px)] md:max-w-[900px] gap-x-4"
          slidesPerView={slidesPerView}
          spaceBetween={10}
          onSwiper={setSwiper}
          onSlideChange={(slider: any) => {
            setIndex(slider.activeIndex);
          }}
        >
          {publications &&
            publications.map((publication: any, index: number) => (
              <SwiperSlide key={index} className="flex justify-center">
                  <div>
                    <h5 className='font-medium text-[22px] leading-[24px]'>{publication.title}</h5>
                    <p className='text-gray-300 mt-4 mb-8 max-w-[745px]'>{publication.text}</p>
                    <p className='text-lg mb-2'>{publication.type}</p>
                    <p className='text-lg font-medium mb-8'>{publication.publicationInfo}</p>
                    <Link to={publication.link}>
                      <ScienceButton className='py-3 gap-x-3'>{$('HOME_SCIENCE_ARTICLE_BTN')}</ScienceButton>
                    </Link>
                  </div>
              </SwiperSlide>
            ))}
        </Swiper>

        <button
          data-show={!isDesktop}
          className={`group flex items-center justify-center p-4 border hover:bg-primary-500 border-primary-500 rounded-xl cursor-pointer z-10 data-[show=true]:hidden transition-colors ${index+slidesPerView === publications.length && 'opacity-0 pointer-events-none'}`}
          onClick={onRightArrowClickHandler}
        >
          <ArrowRight className="w-6 h-6 text-primary-500 group-hover:text-white " />
        </button>

      </div>
      <div className="flex gap-5 z-20">
        {sliderPositionElements.map((element: JSX.Element) => element)}
      </div>
    </div>
  );
};
