import React from 'react';
import { useLang } from '../../../../Lang/useLang';
import { Title } from '../../../UIKit/title/Title';
import ReactPaginate from 'react-paginate';
import { IBlog } from '../../../../types/type';
import { Blog } from '../uikit/blog/Blog';
import { AlignCenter, P } from '../../../UIKit';

export const LatestArticles: React.FC<{ blogs: IBlog[], pageCount: number, handlePageChange: (selectedPage: any) => Promise<void>, selectedTag: string }> = ({ blogs, pageCount, handlePageChange, selectedTag }) => {
    const { $ }: any = useLang()


    const renderBlogs = () => {
        if (!blogs.length) {
            return (
                <AlignCenter>
                    <P>{$('BLOG_LISTING_LATEST_ARTICLES_NO_BLOGS')}</P>
                </AlignCenter>
            )
        }

        return (
            <>
                <div className='grid grid-cols-2 lg:grid-cols-3 gap-7'>
                    {
                        blogs.map((props) => (
                            <Blog key={props._id} {...props} />
                        ))
                    }
                </div>

                <ReactPaginate
                    pageCount={pageCount}
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={10}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    pageLinkClassName="w-7 h-7 flex items-center justify-center"
                    containerClassName="flex justify-center items-center gap-1 list-none mt-16"
                    activeLinkClassName="font-medium text-xsm text-primary-500 rounded-md"
                    pageClassName="text-xsm font-medium text-black hover:text-blue w-7 h-7 cursor-pointer"
                />
            </>
        )
    }

    return (
        <div className='w-full max-w-content px-8'>
            <Title text={$('BLOG_LISTING_LATEST_ARTICLES')} className='text-3xl text-left mt-12 mb-16' />

            {renderBlogs()}
        </div>
    );
};
