import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as GreenTickSVG } from '../../../../assets/svgs/green-tick.svg'
import { ReactComponent as PlusSVG } from '../../../../assets/svgs/plus.svg'



export const ToggleElement: React.FC<any> = ({ option = {}, active = false, controlled = false, handleToggle = () => {}, className, activeClassName, renderCheckbox }) => {
    const [isActive, setIsActive] = useState<boolean>(false)
    const activeToUse = controlled ? active : isActive
    
    const handleClick = () => {
        if(controlled) handleToggle(option)
        else setIsActive(prevState => !prevState)
    }

  return (
    <div 
        className={twMerge('w-auto text-gray-200 flex gap-x-2 items-center border border-zinc-400 rounded-[24px] px-6 py-3 opacity-70', className, activeToUse && `bg-primary-500 border-primary-500 text-primary-500 bg-opacity-20 opacity-100 ${activeClassName}`)}
        onClick={handleClick}
    >
        {option.label}
        {renderCheckbox && (
            <div className={twMerge('flex items-center justify-center w-5 h-5 overflow-hidden')}>{activeToUse ? <GreenTickSVG /> : <PlusSVG />}</div>
        )}
    </div>
  );
};
