import { AlignCenter, BannerTrans, Center, H1, P } from "../../../UIKit"
import { LineCenter, RowsHigh } from "../../../UIKit/Line/Line"
import { Point } from "../uikit/point/Point"
import { ReactComponent as ShieldSVG } from '../../../../assets/svgs/shield.svg';
import { ReactComponent as ShakeSVG } from '../../../../assets/svgs/shake.svg';
import { ReactComponent as GlutenSVG } from '../../../../assets/svgs/gluten.svg';
import { useLang } from "../../../../Lang/useLang";

export const Points = () => {
    const { $ } = useLang();

    return (
        <BannerTrans>
            <Center>
                <RowsHigh>
                    <AlignCenter>
                        <H1>{$('POST_POINT_TITLE')}</H1>
                    </AlignCenter>
                    <AlignCenter>
                        <P>{$('POST_POINT_INFO')}</P>
                    </AlignCenter>
                    <LineCenter>
                        <Point
                            icon={<ShieldSVG />}
                            text={$('POST_POINT_ICON_SHEILD')}
                        />
                        <Point
                            icon={<ShakeSVG />}
                            text={$('POST_POINT_ICON_SHAKE')}
                        />
                        <Point
                            icon={<GlutenSVG />}
                            text={$('POST_POINT_ICON_GLUTEN')}
                        />
                    </LineCenter>
                    <AlignCenter>
                        <P>{$('POST_POINT_FOOTER')}</P>
                    </AlignCenter>
                </RowsHigh>
            </Center>
        </BannerTrans>
    )
}