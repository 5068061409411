import React, { useContext } from 'react';
import backgroundVideo from '../../../../../assets/videos/background-video.mp4';
import { Header } from '../../../../layout/header/Header';
import { Titles } from './titles/Titles';
import { Button } from '../../../../UIKit/button/Button';
import { PageContext } from '../../../../../context/PageContext';
import { Breaker } from '../../../../UIKit/breaker/Breaker';
import { useLang } from '../../../../../Lang/useLang';
import { twMerge } from 'tailwind-merge';
import { Link } from 'react-router-dom';

export const HeadSection: React.FC = () => {
  const pageWidth = useContext(PageContext);
  const { $, getSelectedLang } = useLang();

  return (
    <div className="w-full h-[760px] flex flex-col relative  ">
      <div className="w-full h-full md:h-fit flex flex-col mb-8">
        <Header
          isWhite={pageWidth > 768}
        />
        <Titles />
      </div>
      <video
        muted
        loop
        autoPlay
        className="w-full h-full absolute object-cover z-10">
        <source src={backgroundVideo} type="video/mp4" />
      </video>
    </div>
  );
};
