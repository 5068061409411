import { useEffect, useRef } from "react";
import { observer } from "../utils/observer";
import { useLang } from "../Lang/useLang";

export const useGTM = () => {
    const { getSelectedLang } = useLang();
    const urlRef = useRef("");
    urlRef.current = getSelectedLang().url;

    useEffect(() => {
        if (observer.has("form_submission")) { return }

        document.body.addEventListener('click', (e: any) => {
            const aTag = e?.target?.closest('a');
            if (aTag?.closest('a')?.href === urlRef.current) {
                window.dataLayer.push({
                    'event': 'cta_click',
                    'link_text': aTag.text,
                    'link_url': urlRef.current,
                    'link_position': 'hero'
                });
            }
        })

        observer.listen("form_submission", (value: string) => {
            window.dataLayer.push({
                'event': 'form_submission',
                'form_name': value
            });
        })

        observer.listen("faq_click", (title: string) => {
            window.dataLayer.push({
                'event': 'faq_click',
                'link_text': title
            });
        })

        observer.listen("region_picker_open", () => {
            window.dataLayer.push({
                'event': 'region_picker_open'
            });
        })

        observer.listen("region_picker_select", ({ lang, country }) => {
            window.dataLayer.push({
                'event': 'region_picker_select',
                'language': lang,
                'region': country,
            });
        })

        observer.listen("appointment_step", ({ step, flow }) => {
            window.dataLayer.push({
                'event': `appointment_step_${step}`,
                'step_name': 'personal details',
                'survey_flow': flow
            });
        })

        observer.listen("appointment_booked", ({ email, phone, flow }) => {
            window.dataLayer.push({
                'event': 'appointment_booked',
                'email': email,
                'phone': phone,
                'survey_flow': flow
            });
        })
    }, [])
}