import React from 'react';
import { useLang } from '../../../../Lang/useLang';
import { Title } from '../../../UIKit/title/Title';
import laptopTypingPNG from '../../../../assets/images/laptop-typing.png';
import consultationPNG from '../../../../assets/images/consultation.png';
import laptopDocsPNG from '../../../../assets/images/laptop-docs.png';
import doctorCardPNG from '../../../../assets/images/doctor-card.png';

export const ProvidersHIWPartnering: React.FC = () => {
    const { $ }: any = useLang();

    const items = [
        {
            title: $('PROVIDERS_HIW_PARTNERING_1_TITLE'),
            description: $('PROVIDERS_HIW_PARTNERING_1_SUBTITLE'),
            image: laptopTypingPNG
        },
        {
            title: $('PROVIDERS_HIW_PARTNERING_2_TITLE'),
            description: $('PROVIDERS_HIW_PARTNERING_2_SUBTITLE'),
            image: consultationPNG
        },
        {
            title: $('PROVIDERS_HIW_PARTNERING_3_TITLE'),
            description: $('PROVIDERS_HIW_PARTNERING_3_SUBTITLE'),
            image: laptopDocsPNG
        },
        {
            title: $('PROVIDERS_HIW_PARTNERING_4_TITLE'),
            description: $('PROVIDERS_HIW_PARTNERING_4_SUBTITLE'),
            image: doctorCardPNG
        },
    ]

  return (
    <div className='w-full max-w-content flex flex-col items-center py-8 md:pt-32 md:pb-32 px-6 mx-auto'>
        <Title className='text-[29px] md:text-[40px] mb-0 mt-0 max-w-[800px]' text={$('PROVIDERS_HIW_PARTNERING_TITLE')}/>
        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 mt-8 md:mt-28'>
            {
                items.map(({ title, description, image }, index) => (
                    <div key={index} className={`flex md:gap-y-6 flex-col ${index%2 !== 0 ? 'md:flex-col' : 'md:flex-col-reverse'}`}>
                        <img src={image} />
                        <div className='w-[320px] md:w-[300px] md:h-[251px] py-6 md:py-0 px-4 md:px-7 bg-aqua-300 rounded-2xl flex flex-col items-center justify-center text-center text-gray-300'>
                            <h5 className='text-[28px] md:text-lg font-medium'>{title}</h5>
                            <p className='mt-2 md:mt-4 text-lg md:text-base'>{description}</p>
                        </div>
                    </div>
                ))
            }
        </div>
    </div>
  );
};
