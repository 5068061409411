import React from 'react'
import { Footer } from '../../layout/footer/Footer'
import { Header } from '../../layout/header/Header'
import { Breaker } from '../../UIKit/breaker/Breaker';
import { ProvidersHomeBenefits } from './components/ProvidersHomeBenefits'
import { ProvidersHomeContact } from './components/ProvidersHomeContact'
import { ProvidersHomeHeadSection } from './components/ProvidersHomeHeadSection'
import { ProvidersHomeIntroduction } from './components/ProvidersHomeIntroduction'
import { ProvidersHomeNetwork } from './components/ProvidersHomeNetwork'
import { ProvidersHomeQuote } from './components/ProvidersHomeQuote'
import { ProvidersHomeStatistic } from './components/ProvidersHomeStatistic'

export const ProvidersHomePage: React.FC = () => {
 
  return (
    <div className='bg-white'>
        <Header className="absolute z-10" isWhite />
        <ProvidersHomeHeadSection />
        <ProvidersHomeIntroduction />
        <Breaker className='breaker-disable_gradient bg-aqua'/>
        <ProvidersHomeStatistic />
        <Breaker className='breaker-disable_gradient bg-aqua'/>
        <ProvidersHomeBenefits />
        <ProvidersHomeQuote />
        <Breaker className='breaker-disable_gradient bg-aqua'/>
        <ProvidersHomeNetwork />
        <Breaker className='breaker-disable_gradient bg-aqua'/>
        <ProvidersHomeContact />
        <Footer /> 
    </div>
  )
}