import { ChevronDown } from "lucide-react";
import { ReactNode, useState } from "react";
import { twMerge } from "tailwind-merge";

interface FooterBlockProps {
  title: string
  children: ReactNode
}

export function FooterBlock({title, children }: FooterBlockProps) {
  const [openBlock, setOpenBlock] = useState([false, false, false, false]);
  
  const handleOpenBlock = (index: number) => {
    setOpenBlock((prev) => {
      return prev.map((block, i) => {
        return i === index ? !block : false;
      });
    });
  };
  
  return (
    <div 
      className={twMerge(
        'w-full flex flex-col px-8 pt-8 pb-3 text-xs text-zinc-600',
        'border-t-2 border-zinc-200',
        'md:w-52 md:px-4 md:border-0 md:pt-0',
      )}
    >
          <div
            className="w-full flex justify-between"
            onClick={() => {
              handleOpenBlock(0);
            }}
            
          >
            <span className="text-xl font-semibold text-zinc-900">
              {title}
            </span>
             
            <ChevronDown 
              data-open={openBlock[0]}
              className="w-8 h-8 md:hidden data-[open=true]:rotate-180 transition-all ease-in-out "
            />
          
          </div>

          <div
            data-open={openBlock[0]}
            className='flex flex-col w-full h-0 md:h-full text-sm overflow-hidden items-start transition-all ease-in-out gap-3 data-[open=true]:h-1/3'
          >
            {children}
          </div>
        </div>
  )
}