import { FC } from "react"
import { useLang } from "../../../../Lang/useLang";
import { Title } from "../../../UIKit/title/Title";
import { ReactComponent as BirthSVG } from '../../../../assets/svgs/learn-more-birth.svg'
import { ReactComponent as SexSVG } from '../../../../assets/svgs/learn-more-sex.svg'
import { ReactComponent as GestationalSVG } from '../../../../assets/svgs/learn-more-gestational.svg'
import { ReactComponent as CaesarianSVG } from '../../../../assets/svgs/learn-more-caesarian.svg'
import { ReactComponent as AtopicSVG } from '../../../../assets/svgs/learn-more-atopic.svg'
import { ReactComponent as AntibioticsSVG } from '../../../../assets/svgs/learn-more-antibiotics.svg'
import { ReactComponent as SiblingsSVG } from '../../../../assets/svgs/learn-more-siblings.svg'
import { ReactComponent as FamilySVG } from '../../../../assets/svgs/learn-more-family.svg'
import { ReactComponent as EnvironmentSVG } from '../../../../assets/svgs/learn-more-environment.svg'
import { ReactComponent as FeedingSVG } from '../../../../assets/svgs/learn-more-feeding.svg'
import { ReactComponent as SmokingSVG } from '../../../../assets/svgs/learn-more-smoking.svg'
import { AlignCenter, Center } from "../../../UIKit";

export const LearnMoreListing: FC = () => {
    const { $, $flat }: any = useLang();
    const items = [
        {
            icon: <BirthSVG />,
            text: $flat('LEARN_MORE_1')
        },
        {
            icon: <SexSVG />,
            text: $flat('LEARN_MORE_2')
        },
        {
            icon: <GestationalSVG />,
            text: $flat('LEARN_MORE_3')
        },
        {
            icon: <CaesarianSVG />,
            text: $flat('LEARN_MORE_4')
        },
        {
            icon: <AtopicSVG />,
            text: $flat('LEARN_MORE_5')
        },
        {
            icon: <AntibioticsSVG />,
            text: $flat('LEARN_MORE_6')
        },
        {
            icon: <AntibioticsSVG />,
            text: $flat('LEARN_MORE_7')
        },
        {
            icon: <SiblingsSVG />,
            text: $flat('LEARN_MORE_8')
        },
        {
            icon: <FamilySVG />,
            text: $flat('LEARN_MORE_9')
        },
        {
            icon: <EnvironmentSVG />,
            text: $flat('LEARN_MORE_10')
        },
        {
            icon: <FeedingSVG />,
            text: $flat('LEARN_MORE_11')
        },
        {
            icon: <SmokingSVG />,
            text: $flat('LEARN_MORE_12')
        },
    ]

    return (
        <div className="px-6 md:px-44 md:bg-learn-more-bg-md bg-no-repeat bg-right-top">
            <Title className='text-left text-[40px] md:text-6xl pb-36 mb-0 md:pb-0 md:mb-20 md:mt-28 bg-learn-more-bg md:bg-none bg-no-repeat bg-right-bottom' text={$('LEARN_MORE_TITLE')} />

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7">
                {
                    items.map(({ icon, text }) => (
                        <div className="bg-blue-light p-9 rounded-xl max-w-[350px] mx-auto">
                            <Center>
                                <AlignCenter>
                                    {icon}
                                </AlignCenter>
                                <div className='mt-2.5 text-sm [&>b]:block [&>b]:text-lg [&>p]:text-primary-500 [&>p]:text-xs flex flex-col gap-y-1.5' dangerouslySetInnerHTML={{ __html: text }} />
                            </Center>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}