import React from 'react';
import { Subtitle } from '../../../UIKit/subtitle/Subtitle';
import { useLang } from '../../../../Lang/useLang';
import { useMarkdown } from '../../../../hooks/useMarkdown';
import { MarkdownShow } from '../../../UIKit/MarkdownShow/MarkdownShow';


export const WithdrawalForm: React.FC = () => {
    const { $ }: any = useLang();
    const fetchFormContent = useMarkdown('WITHDRAWAL_POLICY_FORM_CONTENT');
  return (
    <div className="flex flex-col gap-8">
      <Subtitle>{$('WITHDRAWAL_POLICY_FORM_TITLE')}</Subtitle>
      <MarkdownShow fetch={fetchFormContent} className="flex flex-col gap-8" />
    </div>
  );
};
