
import { useEffect, useState } from 'react';
import './MarkdownShow.css';
import { useLang } from '../../../Lang/useLang';
import { twMerge } from 'tailwind-merge';

interface IProps {
    fetch: () => Promise<string>,
    className?: string
}
export const MarkdownShow = ({ fetch, className }: IProps) => {
    const [html, setHtml] = useState('');
    const [loading, setLoading] = useState(true);
    const { $ } = useLang();    

    useEffect(() => {
        fetch().then(resp => {
            setHtml(resp);
            setLoading(false);
        });
    }, [fetch])

    return !loading ? (
            <div
                className={twMerge('MarkdownShow', className)}
                dangerouslySetInnerHTML={{ __html: html }}
            />
    ) : null
}