import z from 'zod';
import { emailRegex, phoneRegex } from '../helpers/regex';

export const contactSchema = z.object({
  fullName: z.string().min(1, 'Invalid Name'),
  location: z.string().min(1, 'Invalid Location'),
  phoneNumber: z.string().regex(phoneRegex, 'Invalid Number'),
  email: z.string().regex(emailRegex, 'Invalid Email'),
  description: z.string().max(1000)
});

export type ContactSchemaType = z.infer<typeof contactSchema>;
