import { ReactNode } from 'react';
import styles from './StepsWrap.module.css';

interface IProps {
    children: ReactNode
}

export const StepsWrap = ({ children }: IProps) => {
    return (
        <div className={styles.container}>
            {children}
        </div>
    )
}