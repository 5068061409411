import React from 'react';
import { Title } from '../../../../UIKit/title/Title';
import imgSource from '../../../../../assets/images/our-mission.png';
import { useLang } from '../../../../../Lang/useLang';

export const OurMissionSection: React.FC = () => {
  const { $ }: any = useLang();

  return (
    <div className="flex flex-col items-center justify-center mx-8 my-12 md:m-16">
      <div className="w-full flex flex-col max-w-content md:flex-row items-center justify-center gap-8">
        
        <img 
          src={imgSource} 
          className="w-full md:max-w-xs h-auto rounded-2xl order-2 md:order-1" 
          alt= ""
        />
        <div className='flex flex-col items-center md:items-start gap-3 order-1 md:order-last'>
          <Title 
            text={$('ABOUT_OUR_MISSION_TITLE')} 
            className='mb-3'
          />   
          <div className="text-lg text-justify md:text-left text-zinc-700">
            {$('ABOUT_OUR_MISSION_TEXT')}
          </div>
        </div>     
      </div>

    </div>
  );
};
