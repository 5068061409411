import React from 'react';
import { useLang } from '../../../../Lang/useLang';
import { Title } from '../../../UIKit/title/Title';
import { ReactComponent as HUSSvg } from '../../../../assets/svgs/HUS.svg';
import { ReactComponent as MountSinaiSvg } from '../../../../assets/svgs/mount-sinai.svg';
import ShamirPNG  from '../../../../assets/images/shamir.png';
import KingsCollegePNG  from '../../../../assets/images/kings-college.png';
import LeumitStartPNG  from '../../../../assets/images/leumit-start.png';
import MinistryOfHealthPNG  from '../../../../assets/images/ministry-of-health.png';



export const ScienceInnovation: React.FC = () => {
    const { $ }: any = useLang();

    const logos = [<HUSSvg />, <MountSinaiSvg />, <img src={ShamirPNG} className="h-[51px]" />, <img src={KingsCollegePNG} className="h-[63px]"/>, <img src={LeumitStartPNG} className="h-[51px]"/>, <img src={MinistryOfHealthPNG} className="h-[65px]"/>]
  return (
    <div className='w-full max-w-content bg-blue-light flex flex-col items-center py-20 px-6 mx-auto'>
        <Title className='text-[29px] md:text-[40px] m-0 mb-6' text={$('SCIENCE_INNOVATION_TITLE')}/>
        <p className='text-lg text-center text-gray-300'>{$('SCIENCE_INNOVATION_SUBTITLE')}</p>        
        <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 items-center gap-16 mt-20'>
            {
                logos.map( (logo, index) => (<div key={index}>{logo}</div>))
            }
        </div>
    </div>
  );
};