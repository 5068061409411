import React, { useState } from 'react';
import { Card } from '../../UIKit/card/Card';
import { Title } from '../../UIKit/title/Title';
import { Input } from '../../UIKit/input/Input';
import { useForm } from 'react-hook-form';
import {
  ContactSchemaType,
  contactSchema
} from '../../../schemas/contact-schema';
import { Label } from '../../UIKit/label/Label';
import { Button } from '../../UIKit/button/Button';
import { zodResolver } from '@hookform/resolvers/zod';
import { TextArea } from '../../UIKit/textarea/TextArea';
import { sendContactDetails } from '../../../api/contact-api';
import { useLang } from '../../../Lang/useLang';

type TContactForm = {
  title?: string;
  cardClassName?: string;
}

export const ContactForm: React.FC<TContactForm> = ({ title, cardClassName }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ContactSchemaType>({ resolver: zodResolver(contactSchema) });
  const [message, setMessage] = useState({ show: false, success: false });
  const { $, $flat }: any = useLang();

  const onSubmit = async (data: ContactSchemaType) => {
    const result = await sendContactDetails(data);
    setMessage({ show: true, success: result });
  };

  return (
    <div className="flex items-center justify-center my-16 px-8">
      <Card className={`flex flex-col items-center w-[900px] gap-12 p-12 shadow-sm ${cardClassName}`}>
        <Title className="mt-12 mb-0" text={title || $("CONTACT_TITLE")} />
        <div className="w-full flex flex-col gap-10">
          <div className="w-full flex flex-col md:flex-row items-center justify-center gap-5">
            <Label text={$("CONTACT_FULL_NAME_INPUT_LABLE")} containerClassName="w-full">
              <Input
                className="px-4 py-6"
                placeholder={$flat("CONTACT_FULL_NAME_INPUT_PLACEHOLDER")}
                name="fullName"
                register={register}
                error={errors.fullName}
              />
            </Label>
            <Label text={$("CONTACT_LOCATION_LABLE")} containerClassName="w-full">
              <Input
                 className="px-4 py-6"
                placeholder={$flat("CONTACT_LOCATION_PLACEHOLDER")}
                name="location"
                register={register}
                error={errors.location}
              />
            </Label>
          </div>
          <div className="w-full flex flex-col md:flex-row items-center justify-center gap-5">
            <Label text={$("CONTACT_PHONE_NUMBER_LABLE")} containerClassName="w-full">
              <Input
                className="px-4 py-6"
                placeholder={$flat("CONTACT_PHONE_NUMBER_PLACEHOLDER")}
                name="phoneNumber"
                register={register}
                error={errors.phoneNumber}
              />
            </Label>
            <Label text={$("CONTACT_EMAIL_LABLE")} containerClassName="w-full">
              <Input
                className="px-4 py-6"
                placeholder={$flat("CONTACT_EMAIL_PLACEHOLDER")}
                name="email"
                register={register}
                error={errors.email}
              />
            </Label>
          </div>
          <div className="w-full flex items-center justify-center gap-5">
            <Label text={$("CONTACT_DESCRIPTION_LABLE")} containerClassName="w-full flex">
              <TextArea
                placeholder={$flat("CONTACT_DESCRIPTION_PLACEHOLDER")}
                name="description"
                register={register}
              />
            </Label>
          </div>
        </div>
        <Button 
          className="!w-[200px] flex items-center justify-center font-semibold text-base hover:bg-primary-600 " 
          onClick={handleSubmit(onSubmit)}
        >
        {$("CONTACT_SUBMIT_BTN")}
        </Button>
        {message.show &&
          (message.success ? (
            <div className="flex items-center justify-center text-white w-full p-4 rounded-lg font-semibold bg-primary-500">
              {$("CONTACT_SUCCESS_MGS")}
            </div>
          ) : (
            <div className="flex items-center justify-center text-white w-full p-4 rounded-lg font-semibold bg-red-600">
              {$("CONTACT_ERROR_MGS")}
            </div>
          ))}
      </Card>
    </div>
  );
};
