import axios from "axios";
import { getUserToken } from "../context/storage";
import { toast } from 'react-toastify';

export const routes = {
    server: process.env.REACT_APP_SERVER_URL,
    upload: process.env.REACT_APP_Z3_ROUTES_UPLOADPDF,
    domains: process.env.REACT_APP_SERVER_ROUTES_DOMAINS,
    ml: process.env.REACT_APP_SERVER_ROUTES_ML,
    auth: process.env.REACT_APP_SERVER_ROUTES_AUTH,
}

class Api {
    constructor() {
    }

    send = async (method, url, data = null, config = this.getHeaders()) => {
        try {
            const resp = await axios[method](url, data || config, config);
            return resp.data;
        } catch (error) {
            await this.handleError(error);
        }
    }

    handleError = async (error) => {

        if (error.response) {
            const err = error.response?.data?.error || { message: 'Error' };

            switch (err.name) {
                case "JsonWebTokenError":
                case "TokenExpiredError":
                    toast.error(`Token - ${err.message}`);
                    break;

                default:
                    toast.error(`${err.message || err}`);
                    throw err.message || err;
            }
        } else if (error.request) {
            toast.error(`${error.request}`);
            throw error.request;
        } else {
            toast.error(`${error.message}`);
            throw error.message;
        }
    }

    getHeaders = (contentType = 'application/json') => {
        return {
            headers: {
                'Content-Type': contentType,
                'Auth': getUserToken()
            }
        }
    }

    get = async (...args) => this.send('get', ...args)
    post = async (...args) => this.send('post', ...args)
    delete = async (...args) => this.send('delete', ...args)

    upload = async (formData) => {
        return await this.send(
            'post',
            routes.upload,
            formData,
            this.getHeaders('multipart/form-data')
        );
    }
}

export const api = new Api();
