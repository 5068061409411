import { twMerge } from "tailwind-merge";
import { useLang } from "../../../../../Lang/useLang";
import { Button } from "../../../../UIKit/button/Button";
import { usePopupIframe } from "../../../../../modal/popIframe";

type TTryButtonProps = {
  className?: string
}

export function TryButton({ className }: TTryButtonProps) {
  const { $ }: any = useLang();
  const { popIframe } = usePopupIframe();

  return (
    <Button
      className={twMerge("cta_click flex whitespace-nowrap items-center justify-center px-3 hover:bg-primary-400", className)}
      position="LANDING_HEADER_TRY_BTN"
      onClick={() => popIframe("header")}
    >
      {$('LANDING_HEADER_TRY_BTN')}
    </Button>
  )
}