import React from 'react';
import { dropDownLinkType } from '../../../../../types/type';
import { Link2 } from '../../../../UIKit/link/Link';
import { useLang } from '../../../../../Lang/useLang';
import { twMerge } from 'tailwind-merge';

type LinksProps = {
  isBlack?: boolean;
  className?: string;
  handleClick?: () => void;
};

export const Links: React.FC<LinksProps> = ({ isBlack, className, handleClick = () => {} }) => {
  const { $ }: any = useLang();

  const linkList: dropDownLinkType[] = [
    {
      title: $('HEADER_LINKS_PARENTS'),
      dropDown: true,
      dropDownItems: [
        {
          title: 'HEADER_LINK_DROP_PARENTS',
          link: '/parents'
        },
        {
          title: 'HEADER_LINK_DROP_HIW',
          link: '/parents/how-it-works'
        },
        {
          title: 'HEADER_LINK_DROP_CARE',
          link: '/appointment'
        },
        {
          title: 'LANDING_HEADER_LINKS_FAQ',
          link: '/#question-section'
        },
        {
          title: 'HEADER_LINK_DROP_CENTER',
          link: '/blogs'
        },
        {
          title: 'HEADER_LINK_DROP_SCIENCE_&_RESEARCH',
          link: '/science'
        },
      ]
    },
    {
      title: $('HEADER_LINKS_PROVIDERS'),
      dropDown: true,
      dropDownItems: [
        {
          title: 'HEADER_LINK_DROP_PROVIDERS',
          link: '/providers'
        },
        {
          title: 'HEADER_LINK_DROP_HIW',
          link: '/providers/how-it-works'
        },
        {
          title: 'HEADER_LINK_DROP_JOIN_US',
          link: '/contact'
        },
        {
          title: 'HEADER_LINK_DROP_PORTAL',
          link: `${process.env.REACT_APP_RESULTS_PORT}`
        },
        {
          title: 'HEADER_LINK_DROP_SCIENCE_&_RESEARCH',
          link: '/science'
        },
      ]
    },
    {
      title: $('LANDING_HEADER_LINKS_ABOUT'),
      dropDown: true,
      dropDownItems: [
        {
          title: 'HEADER_LINK_DROP_ABOUT_US',
          link: '/about'
        },
      ]
    },
  ];

  return (
    <div 
      className={twMerge("flex flex-col md:flex-row items-start gap-5 md:gap-8 z-50", className)}
    >
      {linkList &&
        linkList.map((link, index) => {
          return (
            <Link2
              className="font-semibold text-base hover:text-primary-500"
              key={`link-item-${index}`}
              link={link.link}
              isDropDown={link.dropDown}
              dropDownItems={link.dropDownItems}
              isBlack={isBlack}
              handleClick={handleClick}
            >
              {link.title}
            </Link2>
          );
        })}
    </div>
  );
};
