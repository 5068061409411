import React, { useEffect, useState } from 'react';

export const PageContext = React.createContext<number>(window.innerWidth);

export const PageProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [pageWidth, setPageWidth] = useState(window.outerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  function handleResize() {
    setPageWidth(window.outerWidth);
  }

  return (
    <PageContext.Provider value={pageWidth}>{children}</PageContext.Provider>
  );
};
