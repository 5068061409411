import React from 'react';
import { useLang } from '../../../../Lang/useLang';
import { Title } from '../../../UIKit/title/Title';
import { Link } from 'react-router-dom';
import { Button } from '../../../UIKit/button/Button';

export const ProvidersHomeStatistic: React.FC = () => {
    const { $ }: any = useLang();

    const statistic = [
        {
            percent: '83%',
            text: $('PROVIDERS_HOME_STATISTIC_1'),
        },
        {
            percent: '81.4%',
            text: $('PROVIDERS_HOME_STATISTIC_2'),
        },
        {
            percent: '76%',
            text: $('PROVIDERS_HOME_STATISTIC_3'),
        },
        {
            percent: '1M',
            text: $('PROVIDERS_HOME_STATISTIC_4'),
        },
    ]

  return (
    <div className='w-full max-w-content flex flex-col items-center pb-5 md:pb-16 pt-10 md:pt-20 px-6 mx-auto'>
        <Title className='text-[29px] md:text-[40px] max-w-[990px] m-0' text={$('PROVIDERS_HOME_STATISTIC_TITLE')}/>
        <p className='text-xl font-medium text-center mt-6 md:mt-4 mb-8 md:mb-7'>{$('PROVIDERS_HOME_STATISTIC_SUBTITLE')}</p>
        <Link to={'/contact'} className="w-full md:w-fit">
          <Button
            className="w-full flex items-center justify-center rounded-2xl !text-base font-bold px-6 py-4  hover:bg-primary-400"
          >
           {$('JOIN_THE_NETWORK')}
          </Button>
        </Link>
        <div className='max-w-[1210px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-14 gap-6 mt-8 md:mt-20 mb-16'>
            {
                statistic.map(({ percent, text }, index) => (
                    <div className='text-center max-w-[260px]' key={index}>
                        <h6 className='text-[70px] text-primary-500 font-bold'>{percent}</h6>
                        <p className='text-lg font-bold'>{text}</p>
                    </div>
                ))
            }
        </div>
    </div>
  );
};