import React from 'react';
import { toBold, useLang } from '../../../../Lang/useLang';
import { Title } from '../../../UIKit/title/Title';
import { ReactComponent as BabyCareSVG } from '../../../../assets/svgs/baby-care.svg'
import { ReactComponent as ChartSVG } from '../../../../assets/svgs/chart.svg'
import { ReactComponent as OnlineAppointmentSVG } from '../../../../assets/svgs/online-appointment.svg'
import { ReactComponent as ReportBookSVG } from '../../../../assets/svgs/report-book.svg'
import { ReactComponent as HappyCloudSVG } from '../../../../assets/svgs/happy-cloud.svg'
import { ReactComponent as HumanBookSVG } from '../../../../assets/svgs/human-book.svg'


export const HealthierBeginnings: React.FC = () => {
    const { $ }: any = useLang();
    const points = [
        {
            icon: <OnlineAppointmentSVG />,
            text: $('POSTPARTUM_HEALTH_1', toBold)
        },
        {
            icon: <ReportBookSVG />,
            text: $('POSTPARTUM_HEALTH_2', toBold)
        },
        {
            icon: <HappyCloudSVG />,
            text: $('POSTPARTUM_HEALTH_3', toBold)
        },
        {
            icon: <ChartSVG className='h-[72px]'/>,
            text: $('POSTPARTUM_HEALTH_4', toBold)
        },
        {
            icon: <BabyCareSVG className='h-[72px] w-[58px]'/>,
            text: $('POSTPARTUM_HEALTH_5', toBold)
        },
        {
            icon: <HumanBookSVG />,
            text: $('POSTPARTUM_HEALTH_6', toBold)
        },
    ]
  return (
    <div className='w-full max-w-content flex flex-col items-center py-8 md:py-28 px-6'>
        <Title className='text-[29px] leading-[45px] md:text-[40px] md:leading-[48px] mt-0 mb-6' text={$('POSTPARTUM_HEALTH_TITLE')}/>
        <p className='max-w-[915px] text-lg text-gray-300 text-center'>{$('POSTPARTUM_HEALTH_SUBTITLE')}</p>
        <div className='max-w-[1115px] mt-6 md:mt-28 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[32px]'>
            {
                points.map(({ icon, text }, index) => (
                    <div className='max-w-[300px] py-4 px-7 flex flex-col items-center gap-y-2' key={index}>
                        {icon}
                        <div className='text-lg text-center'>{text}</div>
                    </div>
                ))
            }
        </div>
    </div>
  );
};
