import React from 'react';
import { useMarkdown } from "../../../../../hooks/useMarkdown"
import { useLang } from "../../../../../Lang/useLang"
import { MarkdownShow } from "../../../../UIKit/MarkdownShow/MarkdownShow"
import { BlogSection } from "../BlogSection"

export const Blog2: React.FC = () => {
  const { $ }: any = useLang()
  const fetchBlogContent = useMarkdown('BLOG_CONTENT_2');
  return (
    <BlogSection
      title={$('BLOG_TITLE_2')}
      bgClassName="bg-blog2"
    >
      <MarkdownShow fetch={fetchBlogContent} className="flex flex-col gap-8" />
    </BlogSection>
         
  )
}