import React from 'react';
import { Card } from '../card/Card';
import { twMerge } from 'tailwind-merge';

interface ModalProps {
  className?: string;
  children: React.ReactNode;
  isOpen?: boolean;
  onClose?: () => void
}

export const Modal: React.FC<ModalProps> = ({
  className,
  children,
  isOpen,
  onClose = () => {}
}) => {

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black/50 z-50"
          onClick={onClose}
        >
          <Card className={twMerge(
            'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2', 
            className
            )}
            onClick={e => e.stopPropagation()}
          >
            {children}
          </Card>
        </div>
      )}
    </>
  );
};
