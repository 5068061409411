import React from 'react';
import { StorySlider } from './stories-slider/StorySlider';
import { Title } from '../../../../UIKit/title/Title';
import { useLang } from '../../../../../Lang/useLang';

export const StoriesSection: React.FC = () => {
  const { $ }: any = useLang();
  return (
    <div className="flex bg-blue-light flex-col items-center justify-center px-8 py-16">
      <Title 
        className="mb-8" 
        text={$('HOME_STORIES_TITLE')} 
      />
      <div className="text-xl text-center text-zinc-600">
        {$('HOME_STORIES_SUBTITLE')}
      </div>
      <StorySlider />
    </div>
  );
};
