import React from 'react';
import { Tooltip } from '../../../../../../UIKit/tooltip/Tooltip';
import { twMerge } from 'tailwind-merge';

interface RegulatorProps {
  children: React.ReactNode;
  tooltipContent: React.ReactNode;
  className?: string;
}

export const Regulator: React.FC<RegulatorProps> = ({
  children,
  tooltipContent,
  className
}) => {
  return (
    <Tooltip 
      className={twMerge(
        'top-14 transition-opacity ease-in-out',
        className
      )}
      content={tooltipContent}
    >
      {children}
    </Tooltip>
  )
}