import { TOption, TTopicOptions } from "../types/type"

export const insuranceOptions: TOption[] = [
    {label: 'APPOINTMENT_INSURANCE_1', value: 'APPOINTMENT_INSURANCE_1'},
    {label: 'APPOINTMENT_INSURANCE_2', value: 'APPOINTMENT_INSURANCE_2'},
    {label: 'APPOINTMENT_INSURANCE_3', value: 'APPOINTMENT_INSURANCE_3'},
    {label: 'APPOINTMENT_INSURANCE_4', value: 'APPOINTMENT_INSURANCE_4'},
    {label: 'APPOINTMENT_INSURANCE_5', value: 'APPOINTMENT_INSURANCE_5'},
    {label: 'APPOINTMENT_INSURANCE_6', value: 'APPOINTMENT_INSURANCE_6'},
    {label: 'APPOINTMENT_INSURANCE_7', value: 'APPOINTMENT_INSURANCE_7'},
]

export const stateOptions: TOption[] = [
    {label: 'APPOINTMENT_STATE_1', value: 'APPOINTMENT_STATE_1'},
    {label: 'APPOINTMENT_STATE_2', value: 'APPOINTMENT_STATE_2'},
    {label: 'APPOINTMENT_STATE_3', value: 'APPOINTMENT_STATE_3'},
    {label: 'APPOINTMENT_STATE_4', value: 'APPOINTMENT_STATE_4'},
    {label: 'APPOINTMENT_STATE_5', value: 'APPOINTMENT_STATE_5'},
    {label: 'APPOINTMENT_STATE_6', value: 'APPOINTMENT_STATE_6'},
    {label: 'APPOINTMENT_STATE_7', value: 'APPOINTMENT_STATE_7'},
    {label: 'APPOINTMENT_STATE_8', value: 'APPOINTMENT_STATE_8'},
    {label: 'APPOINTMENT_STATE_9', value: 'APPOINTMENT_STATE_9'},
    {label: 'APPOINTMENT_STATE_10', value: 'APPOINTMENT_STATE_10'},
    {label: 'APPOINTMENT_STATE_11', value: 'APPOINTMENT_STATE_11'},
    {label: 'APPOINTMENT_STATE_12', value: 'APPOINTMENT_STATE_12'},
    {label: 'APPOINTMENT_STATE_13', value: 'APPOINTMENT_STATE_13'},
    {label: 'APPOINTMENT_STATE_14', value: 'APPOINTMENT_STATE_14'},
    {label: 'APPOINTMENT_STATE_15', value: 'APPOINTMENT_STATE_15'},
    {label: 'APPOINTMENT_STATE_16', value: 'APPOINTMENT_STATE_16'},
    {label: 'APPOINTMENT_STATE_17', value: 'APPOINTMENT_STATE_17'},
    {label: 'APPOINTMENT_STATE_18', value: 'APPOINTMENT_STATE_18'},
    {label: 'APPOINTMENT_STATE_19', value: 'APPOINTMENT_STATE_19'},
    {label: 'APPOINTMENT_STATE_20', value: 'APPOINTMENT_STATE_20'},
    {label: 'APPOINTMENT_STATE_21', value: 'APPOINTMENT_STATE_21'},
    {label: 'APPOINTMENT_STATE_22', value: 'APPOINTMENT_STATE_22'},
    {label: 'APPOINTMENT_STATE_23', value: 'APPOINTMENT_STATE_23'},
    {label: 'APPOINTMENT_STATE_24', value: 'APPOINTMENT_STATE_24'},
    {label: 'APPOINTMENT_STATE_25', value: 'APPOINTMENT_STATE_25'},
    {label: 'APPOINTMENT_STATE_26', value: 'APPOINTMENT_STATE_26'},
    {label: 'APPOINTMENT_STATE_27', value: 'APPOINTMENT_STATE_27'},
    {label: 'APPOINTMENT_STATE_28', value: 'APPOINTMENT_STATE_28'},
    {label: 'APPOINTMENT_STATE_29', value: 'APPOINTMENT_STATE_29'},
    {label: 'APPOINTMENT_STATE_30', value: 'APPOINTMENT_STATE_30'},
    {label: 'APPOINTMENT_STATE_31', value: 'APPOINTMENT_STATE_31'},
    {label: 'APPOINTMENT_STATE_32', value: 'APPOINTMENT_STATE_32'},
    {label: 'APPOINTMENT_STATE_33', value: 'APPOINTMENT_STATE_33'},
    {label: 'APPOINTMENT_STATE_34', value: 'APPOINTMENT_STATE_34'},
    {label: 'APPOINTMENT_STATE_35', value: 'APPOINTMENT_STATE_35'},
    {label: 'APPOINTMENT_STATE_36', value: 'APPOINTMENT_STATE_36'},
    {label: 'APPOINTMENT_STATE_37', value: 'APPOINTMENT_STATE_37'},
    {label: 'APPOINTMENT_STATE_38', value: 'APPOINTMENT_STATE_38'},
    {label: 'APPOINTMENT_STATE_39', value: 'APPOINTMENT_STATE_39'},
    {label: 'APPOINTMENT_STATE_40', value: 'APPOINTMENT_STATE_40'},
    {label: 'APPOINTMENT_STATE_41', value: 'APPOINTMENT_STATE_41'},
    {label: 'APPOINTMENT_STATE_42', value: 'APPOINTMENT_STATE_42'},
    {label: 'APPOINTMENT_STATE_43', value: 'APPOINTMENT_STATE_43'},
    {label: 'APPOINTMENT_STATE_44', value: 'APPOINTMENT_STATE_44'},
    {label: 'APPOINTMENT_STATE_45', value: 'APPOINTMENT_STATE_45'},
    {label: 'APPOINTMENT_STATE_46', value: 'APPOINTMENT_STATE_46'},
    {label: 'APPOINTMENT_STATE_47', value: 'APPOINTMENT_STATE_47'},
    {label: 'APPOINTMENT_STATE_48', value: 'APPOINTMENT_STATE_48'},
    {label: 'APPOINTMENT_STATE_49', value: 'APPOINTMENT_STATE_49'},
    {label: 'APPOINTMENT_STATE_50', value: 'APPOINTMENT_STATE_50'},
]
export const topicOptions: TTopicOptions = {
    "Me": [
        { label: 'APPOINTMENT_TOPIC_1',value: 'APPOINTMENT_TOPIC_1' },
        { label: 'APPOINTMENT_TOPIC_2',value: 'APPOINTMENT_TOPIC_2' },
        { label: 'APPOINTMENT_TOPIC_3',value: 'APPOINTMENT_TOPIC_3' },
        { label: 'APPOINTMENT_TOPIC_4',value: 'APPOINTMENT_TOPIC_4' },
        { label: 'APPOINTMENT_TOPIC_5',value: 'APPOINTMENT_TOPIC_5' },
        { label: 'APPOINTMENT_TOPIC_6',value: 'APPOINTMENT_TOPIC_6' },
        { label: 'APPOINTMENT_TOPIC_7',value: 'APPOINTMENT_TOPIC_7' },
        { label: 'APPOINTMENT_TOPIC_8',value: 'APPOINTMENT_TOPIC_8' },
        { label: 'APPOINTMENT_TOPIC_9',value: 'APPOINTMENT_TOPIC_9' },
        { label: 'APPOINTMENT_TOPIC_10',value: 'APPOINTMENT_TOPIC_10' },
        { label: 'APPOINTMENT_TOPIC_11',value: 'APPOINTMENT_TOPIC_11' },
        { label: 'APPOINTMENT_TOPIC_12',value: 'APPOINTMENT_TOPIC_12' },
        { label: 'APPOINTMENT_TOPIC_13',value: 'APPOINTMENT_TOPIC_13' },
        { label: 'APPOINTMENT_TOPIC_14',value: 'APPOINTMENT_TOPIC_14' },
        { label: 'APPOINTMENT_TOPIC_15',value: 'APPOINTMENT_TOPIC_15' },
        { label: 'APPOINTMENT_TOPIC_16',value: 'APPOINTMENT_TOPIC_16' },
        { label: 'APPOINTMENT_TOPIC_17',value: 'APPOINTMENT_TOPIC_17' },
    ],
    "My Child": [
        { label: 'APPOINTMENT_TOPIC_18',value: 'APPOINTMENT_TOPIC_18' },
        { label: 'APPOINTMENT_TOPIC_19',value: 'APPOINTMENT_TOPIC_19' },
        { label: 'APPOINTMENT_TOPIC_20',value: 'APPOINTMENT_TOPIC_20' },
        { label: 'APPOINTMENT_TOPIC_21',value: 'APPOINTMENT_TOPIC_21' },
        { label: 'APPOINTMENT_TOPIC_22',value: 'APPOINTMENT_TOPIC_22' },
        { label: 'APPOINTMENT_TOPIC_23',value: 'APPOINTMENT_TOPIC_23' },
        { label: 'APPOINTMENT_TOPIC_24',value: 'APPOINTMENT_TOPIC_24' },
        { label: 'APPOINTMENT_TOPIC_25',value: 'APPOINTMENT_TOPIC_25' },
        { label: 'APPOINTMENT_TOPIC_26',value: 'APPOINTMENT_TOPIC_26' },
        { label: 'APPOINTMENT_TOPIC_27',value: 'APPOINTMENT_TOPIC_27' },
        { label: 'APPOINTMENT_TOPIC_28',value: 'APPOINTMENT_TOPIC_28' },
        { label: 'APPOINTMENT_TOPIC_29',value: 'APPOINTMENT_TOPIC_29' },
        { label: 'APPOINTMENT_TOPIC_30',value: 'APPOINTMENT_TOPIC_30' },
        { label: 'APPOINTMENT_TOPIC_31',value: 'APPOINTMENT_TOPIC_31' },
        { label: 'APPOINTMENT_TOPIC_32',value: 'APPOINTMENT_TOPIC_32' },
    ]
}