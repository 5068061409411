import { ReactNode } from 'react';
import styles from './Point.module.css';
import { P } from '../../../../UIKit';

interface IProps {
    icon: ReactNode,
    text: string
}

export const Point = ({icon, text}: IProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.icon}>{icon}</div>
            <P>{text}</P>
        </div>
    )
}