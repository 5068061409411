import React from 'react';
import { Title } from '../../UIKit/title/Title';
import { Header } from '../../layout/header/Header';
import { Footer } from '../../layout/footer/Footer';
import { RightsOfWithdrawal } from './components/RightsOfWithdrawal';
import { EffectsOfWithdrawal } from './components/EffectsOfWithdrawal';
import { WithdrawalForm } from './components/WithdrawalForm';
import { useLang } from '../../../Lang/useLang';
import { SkeletonLoading } from '../../layout/SkeletonLoading';


export const WithdrawalPolicyPage: React.FC = () => {
    const { $, loading }: any = useLang();

  return loading ? (
        <SkeletonLoading />
    ) : (
        <div className="w-full bg-white flex h-screen flex-col items-center">   
            <Header />
            <div className="w-full max-w-content px-12 flex flex-col gap-8">
                <Title text={$('WITHDRAWAL_POLICY_PAGE_TITLE')} className="text-center text-4xl mb-0" />
                <RightsOfWithdrawal />
                <EffectsOfWithdrawal />
                <WithdrawalForm />
            </div>
            <Footer />
        </div>
    )
};
