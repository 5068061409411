interface IIframe {
    title: string;
    src: string;
    id?: string;
}

export const Iframe = ({ title, src, id }: IIframe) => {
    return (
        <iframe
            title={title}
            src={src}
            id={id}
            frameBorder='0'
            scrolling='auto'
            width='100%'
            height='1000'
            style={{ pointerEvents: "auto" }}
        />
    )
}