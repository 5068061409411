import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HomePage } from './components/pages/home/HomePage';
import { AboutPage } from './components/pages/about/AboutPage';
import { PageProvider } from './context/PageContext';
import { ContactPage } from './components/pages/contact/ContactPage';
import { TermsOfServicePage } from './components/pages/Terms-of-service-page/TermsOfServicePage';
import { PrivacyPolicyPage } from './components/pages/privacy-policy-page/PrivacyPolicyPage';
import { BlogPage } from './components/pages/blogs/BlogPage';
import { ScrollToTop } from './components/layout/ScrollToTop';
import { UserManual } from './components/pages/user-manual';

import './styles/global.css';
import Pregnancy from './components/pages/pregnancy';
import Newborns from './components/pages/newborns';
import Toddlers from './components/pages/toddlers';
import { WithdrawalPolicyPage } from './components/pages/withdrawal-policy/WithdrawalPolicyPage';
import { ProvidersHomePage } from './components/pages/providers/ProvidersHomePage';
import { ParentsPage } from './components/pages/parents/ParentsPage';
import { ProvidersHIW } from './components/pages/providers/ProvidersHIW';
import { SciencePage } from './components/pages/science/SciencePage';
import { LearnMorePage } from './components/pages/learn-more/LearnMorePage';
import { BlogCatalog } from './components/pages/blogs/BlogCatalog';
import { AppointmentPage } from './components/pages/appointment/ApppointmentPage';
import { useGTM } from './hooks/useGTM';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ScheduleAppointment } from './components/pages/schedule-appointment/scheduleAppointment';
import { ParentsPagePostPartum } from './components/pages/parentsPostpartum/ParentsPagePostPartum';
import { PostSurvey } from './components/pages/post-survey/PostSurvey';
import './App.css';

//App
function App() {
  useGTM();

  return (
    <div className="bg-blur bg-no-repeat bg-cover antialiased">

      <PageProvider>
        <Routes>
          <Route path="/*" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/terms-of-service/*" element={<TermsOfServicePage />} />
          <Route path="/privacy-policy/*" element={<PrivacyPolicyPage />} />
          <Route path="/withdrawal-policy/*" element={<WithdrawalPolicyPage />} />
          <Route path="/blogs" element={<BlogCatalog />} />
          {/* <Route path="/blog" element={<BlogCatalogPage />}  /> */}
          <Route path="/blogs/:url" element={<BlogPage />} />
          <Route path="/user-manual" element={<UserManual />} />
          <Route path="/pregnancy" element={<Pregnancy />} />
          <Route path="/newborns" element={<Newborns />} />
          <Route path="/toddlers" element={<Toddlers />} />
          <Route path="/parents/how-it-works" element={<ParentsPage />} />
          <Route path="/parents/postpartum" element={<ParentsPagePostPartum />} />
          <Route path="/providers" element={<ProvidersHomePage />} />
          <Route path="/providers/how-it-works" element={<ProvidersHIW />} />
          <Route path="/science" element={<SciencePage />} />
          <Route path="/learn-more" element={<LearnMorePage />} />
          <Route path="/appointment" element={<AppointmentPage />} />
          <Route path="/schedule-appointment" element={<ScheduleAppointment />} />
          <Route path="/post-survey" element={<PostSurvey />} />
        </Routes>
        <ScrollToTop />

      </PageProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
      />
    </div>
  );
}

export default App;
