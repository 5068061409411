import { FC } from 'react';
import phone from '../../../../../assets/images/supporting-phone.png';
import computer from '../../../../../assets/images/supporting-pc.png';
import tablet from '../../../../../assets/images/supporting-tablet.png';
import { Button } from '../../../../UIKit/button/Button';
import { useLang } from '../../../../../Lang/useLang';
import { Title } from '../../../../UIKit/title/Title';
import { usePopupIframe } from '../../../../../modal/popIframe';


export const SupportingSection: FC = () => {
  const { $ }: any = useLang();
  const { popIframe } = usePopupIframe();

  const items = [
    {
      image: phone,
      title: $('HOME_SUPPORT_SECTION_1_TITLE'),
      description: $('HOME_SUPPORT_SECTION_1_SUBTITLE'),
    },
    {
      image: computer,
      title: $('HOME_SUPPORT_SECTION_2_TITLE'),
      description: $('HOME_SUPPORT_SECTION_2_SUBTITLE'),
    },
    {
      image: tablet,
      title: $('HOME_SUPPORT_SECTION_3_TITLE'),
      description: $('HOME_SUPPORT_SECTION_3_SUBTITLE'),
    },
  ]
  return (
    <div className="w-full bg-blue-light flex flex-col items-center justify-center px-8 py-20 md:px-16">
      <Title className="text-[29px] md:text-[40px] max-w-[900px] mt-0 mb-11" text={$('HOME_SUPPORT_SECTION_TITLE')} />
      <Button
        className="cta_click flex px-6 py-4 items-center justify-center text-base font-semibold hover:bg-primary-400"
        position="HOME_PHONE_START_SURVEY"
        onClick={() => popIframe("section")}
      >
        {$('BOOK_APPOINTMENT')}
      </Button>
      <div className='flex flex-wrap md:flex-nowrap md:mt-14 w-full gap-y-8 md:gap-x-12 max-w-[1200px]'>
        {items.map(({ image, title, description }, index) => (
          <div className='w-full' key={index}>
            <div className='h-[255px] flex'><img src={image} className="m-auto" /></div>
            <div className='flex gap-x-4 mt-5 md:mt-10'>
              <div className='flex items-center justify-center text-primary-400 text-[15px] w-9 h-9 border border-primary-400 rounded-[50%] px-3.5'>{index + 1}</div>
              <div>
                <h5 className='text-lg font-medium mb-5'>{title}</h5>
                <p className='text-gray-300'>{description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
