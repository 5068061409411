import React, { useMemo } from 'react';
import { LinkType, OptionType } from '../../../types/type';
import { DropDown } from '../dropdown/DropDown';
import { useLang } from '../../../Lang/useLang';
import { twMerge } from 'tailwind-merge';
import { Link, Navigate, useNavigate } from "react-router-dom";
import { A } from '../a/A';


interface LinkProps {
  className?: string;
  children?: React.ReactNode;
  link?: string;
  isDropDown?: boolean;
  dropDownItems?: LinkType[];
  isBlack?: boolean;
  handleClick?: () => void;
}

export const Link2: React.FC<LinkProps> = ({
  className,
  children,
  link,
  isDropDown,
  dropDownItems,
  isBlack = false,
  handleClick = () => {}
}) => {
  const { $ }: any = useLang()
  const navigate = useNavigate()

  const onLinkClickHandler = () => {
    if (!isDropDown) {
      const href: string = link ?? '/'; 

      if (href.startsWith('/#')) {
        const destination = href.substring(1, href.length);
        navigate('/', { state: { destination: destination} });

      } else if (href.startsWith('http')) {                
        window.location.assign(href)
      } else {
        navigate(href)
      }
      handleClick()
    }
  };

  const dropDownOptions: OptionType[] = useMemo(() => {
    return (
      dropDownItems?.map((item, index) => {
        return {
          id: `link-${index}`,
          text: $(item.title),
          value: item.link ?? ''
        };
      }) ?? []
    );
  }, [$]);

  const onDropDownSelect = (option: OptionType) => {
    // window.location.href = option.value;
    const link: string = option.value || "/";
    if (link.startsWith('/#')) {
      const destination = link.substring(1, link.length);
      navigate('/', { state: { destination: destination} });
    } else if (link.startsWith('http')) {                
      window.location.assign(link)
    } else {
      navigate(option.value, {state: { destination: ''}})
    }
    handleClick()
  };

  return (
    <div
      data-black={isBlack}
      className={twMerge(
        'text-sm font-medium cursor-pointer whitespace-nowrap',
        'data-[black=false]:md:text-white',
        'data-[black=true]:text-zinc-900 data-[black=true]:hover:text-primary-500',
        className
      )}
      onClick={onLinkClickHandler}
    >
      {isDropDown ? (
        
        <DropDown
          options={dropDownOptions}
          onSelect={onDropDownSelect}
        >
          {children}
        </DropDown>
      ) : (
        children
      )}
    </div>
  );
};
