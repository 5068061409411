import { useLang } from "../../../../../Lang/useLang";
import { observer } from "../../../../../utils/observer";
import { DropMenu } from "../../../../UIKit/DropMenu/DropMenu";
import { Line, Rows, RowsFullHigh } from "../../../../UIKit/Line/Line";
import { DDCountry } from "./DDCountry"
import { DDLang } from "./DDLang"

type ButtonGroupProps = {
    isMenuOpen?: boolean;
    isBlack?: boolean;
};

export const LangCountryPicker: React.FC<ButtonGroupProps> = (props) => {
    const { getSelectedLang, $ } = useLang();
    const handleOpen = () => {
        observer.shout("region_picker_open", true);
    }
    return (
        <DropMenu
            onOpen={handleOpen}
            menu={(
                <RowsFullHigh>
                    <div className="text-sm font-bold text-zinc-900">
                        {$('SELECT_COUNTRY_HEADER')}
                    </div>
                    <Rows>
                        <div className="text-sm text-zinc-900">
                            {$('SELECT_COUNTRY_COUNTRY')}
                        </div>
                        <DDCountry {...props} isBlack />
                    </Rows>
                    <Rows>
                        <div className="text-sm text-zinc-900">
                            {$('SELECT_COUNTRY_LANG')}
                        </div>
                        <DDLang {...props} isBlack />
                    </Rows>
                </RowsFullHigh>
            )}
        >
            <Line addClass="flex-nowrap whitespace-nowrap">
                {getSelectedLang().flag}
                {getSelectedLang().lang}
            </Line>
        </DropMenu>
    )
}