import React from 'react';
import logo from '../../../assets/images/myor-logo.png';
import { ReactComponent as BlackLogoSvg } from '../../../assets/svgs/black-logo.svg';
import { twMerge } from 'tailwind-merge';

interface LogoProps {
  className?: string;
  isBlack?: boolean;
  onClick?: () => void;
}

export const Logo: React.FC<LogoProps> = ({ className, isBlack, onClick }) => {
  return (
    <div 
      className="flex items-center justify-center cursor-pointer" 
      onClick={onClick}
    >
      {isBlack ? (
        <BlackLogoSvg className={twMerge(
            'w-[181px] h-[56px]', 
            className
          )} 
        />
      ) : (
        <img 
          src={logo}
          className={twMerge(
              'w-[181px] h-[56px]', 
              className
            )} 
          alt=""
        />
      )}
    </div>
  );
};
