import React from 'react';
import { Title } from '../../../UIKit/title/Title';
import { useLang } from '../../../../Lang/useLang';

export const ProvidersHomeIntroduction: React.FC = () => {
    const { $ }: any = useLang();
    return (
      <div className="w-full flex flex-col items-center bg-blue-light px-6 py-8 md:px-12 md:py-20">
        <Title className='m-0 mb-6 text-[29px] md:text-[31px]' text={$('PROVIDERS_HOME_INTRO_TITLE')}/>
        <p className='max-w-[890px] text-lg text-center'>{$('PROVIDERS_HOME_INTRO_SUBTITLE')}</p>
      </div>
    );
  };
