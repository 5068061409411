import React, { useState } from 'react';
import { OptionType } from '../../../types/type';
import { CheckIcon } from '../icon/Icon';
import { twMerge } from 'tailwind-merge';
import { ChevronDown } from 'lucide-react';
import styles from './Dropdown.module.css';

interface DropDownProps {
  children: React.ReactNode;
  containerClassName?: string;
  optionsClassName?: string;
  options: OptionType[];
  onSelect: (option: OptionType) => void;
  isOpen?: boolean;
  selectedOption?: string;
  noChevronIcon?: boolean;
}

export const DropDown: React.FC<DropDownProps> = ({
  children,
  containerClassName,
  optionsClassName,
  options,
  onSelect,
  selectedOption,
  noChevronIcon = false
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={twMerge(
      'relative',
      containerClassName
    )}
      onBlur={() => setIsOpen(false)}
      tabIndex={0}
    >
      <div className="flex gap-2" onClick={() => setIsOpen(prevState => !prevState)}>
        {children}
        {!noChevronIcon && (
          <ChevronDown
            data-open={isOpen}
            className="w-5 h-5 data-[open=true]:text-primary-500 data-[open=true]:rotate-180 transition-all ease-in-out"
          />
        )}
      </div>
      {isOpen && (
        <div className={twMerge(
          'flex absolute bg-white rounded-xl flex-col items-start overflow-hidden',
          'top-10 w-full min-w-[250px] z-10 shadow-md',
          optionsClassName,
          styles.options
        )}
        >
          {options &&
            options.map((option) => (
              <div
                key={option.id}
                data-selected={selectedOption === option.id}
                className={twMerge(
                  'w-full flex flex-row items-start justify-between text-zinc-800 border-b border-zinc-200',
                  'cursor-pointer p-4 hover:bg-zinc-100 data-[selected=true]:bg-zinc-100'
                )}
                onClick={() => {
                  setIsOpen(false)
                  onSelect(option);
                }}
              >
                <div className="flex items-center gap-2">
                  {option.text}
                </div>
                {selectedOption === option.id && (
                  <CheckIcon className="flex justify-end w-6 h-6" />
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
