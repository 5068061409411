import React from 'react';
import c from 'classnames';

interface ButtonProps {
  className?: string;
  transparent?: boolean;
  children?: React.ReactNode;
  onClick?: (e: any) => void;
  hoverFill?: boolean;
  disabled?: boolean;
  position?: string;
}

export const Button: React.FC<ButtonProps> = ({ children, className, transparent, onClick, hoverFill, disabled, position }) => {
  return (
    <div 
      onClick={onClick} 
      className={c(
        "bg-primary-500 w-fit rounded-xl text-white items-center justify-center px-2 py-3 text-sm cursor-pointer gap-1 transition-colors", 
        transparent && "bg-transparent border border-white", 
        hoverFill && "hover:bg-primary-500", 
        disabled && 'pointer-events-none border-gray-600 !bg-gray-600 text-gray-700',
        className
      )}
      data-position={position}
    >
      {children}
    </div>
  )
};
