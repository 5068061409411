import React, { useEffect, useMemo, useState } from 'react';
import emilyImg from '../../../../../../assets/images/emily.png';
import mariaImg from '../../../../../../assets/images/maria.jpeg';
import sarahImg from '../../../../../../assets/images/sarah.png';
import michaelImg from '../../../../../../assets/images/michael.png';
import { Story } from './Story';
import c from 'classnames';
import { storyType } from '../../../../../../types/type';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLang } from '../../../../../../Lang/useLang';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { useMediaQuery } from 'react-responsive'

export const StorySlider: React.FC = () => {
  const [index, setIndex] = useState<number>(0);
  const [swiper, setSwiper] = useState<any>(null);
  const { $ } = useLang();
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  const isXL = useMediaQuery({ query: '(min-width: 1440px)' })
  const slidesPerView = isDesktop ? isXL ? 3 : 2 : 1;
  
  const stories: storyType[] = [
    {
      name: 'Emily S.',
      location: $('HOME_EMILY_LOCATION'),
      title: $('HOME_STORY_TITLE_EMILY'),
      text: $('HOME_STORY_TEXT_EMILY'),
      imgSource: emilyImg
    },
    {
      name: 'Maria G.',
      location: $('HOME_MARIA_LOCATION'),
      title: $('HOME_STORY_TITLE_MARIA'),
      text: $('HOME_STORY_TEXT_MARIA'),
      imgSource: mariaImg
    },
    {
      name: 'Sarah T.',
      location: $('HOME_SARAH_LOCATION'),
      title: $('HOME_STORY_TITLE_SARAH'),
      text: $('HOME_STORY_TEXT_SARAH'),
      imgSource: sarahImg
    },
    {
      name: 'Michael L.',
      location: $('HOME_MICHAEL_LOCATION'),
      title: $('HOME_STORY_TITLE_MICHAEL'),
      text: $('HOME_STORY_TEXT_MICHAEL'),
      imgSource: michaelImg
    }
  ];  


  const sliderPositionElements = useMemo(() => {
    let elements = [];
    for (
      let i = 0;
      i < (isDesktop ? stories.length - 1 : stories.length);
      i++
    ) {
      elements.push(
        <div
          className={c('w-3 h-3 border-2 border-primary-500 rounded-full ', i === index && 'bg-primary-500')}
          key={i}
        ></div>
      );
    }
    return elements;
  }, [stories, isDesktop]);

  const onLeftArrowClickHandler = () => {
    if (index > 0) {
      setIndex((prev) => prev - 1);
    }
    if(swiper) swiper.slideTo(index-1)
  };

  const onRightArrowClickHandler = () => {
    if (index < stories.length - 1) {
      setIndex((prev) => prev + 1);
    }
    if(swiper) swiper.slideTo(index+1)
  };

  useEffect(() => {
    const storiesElement = document.querySelector('.stories');
    const dragging = (e: any) => {
      storiesElement!.scrollLeft = e.pageX;
    };
    storiesElement?.addEventListener('mousemove', dragging);
  }, []);

  return (
    <div className="flex flex-col items-center gap-12">
      <div className="flex items-center  p-10 md:px-20 md:pt-20 gap-8 mb-8 md:mb-0">
        <button
          data-show={!isDesktop}
          className={`group flex items-center justify-center p-4 border hover:bg-primary-500 border-primary-500 rounded-xl cursor-pointer z-10 data-[show=true]:hidden transition-colors ${!index && 'opacity-0 pointer-events-none'}`}
          onClick={onLeftArrowClickHandler}
        >
          <ArrowLeft className="w-6 h-6 text-primary-500 group-hover:text-white " />
        </button>


        <Swiper
          className="flex w-[calc(100vw-100px)] md:w-[calc(100vw-340px)] md:max-w-[900px] xl:max-w-[1200px] gap-x-4"
          slidesPerView={slidesPerView}
          spaceBetween={10}
          onSwiper={setSwiper}
          onSlideChange={(slider: any) => {
            setIndex(slider.activeIndex);
          }}
        >
          {stories &&
            stories.map((story, index) => (
              <SwiperSlide key={index} className="flex justify-center">
                <Story key={story.name} story={story} />
              </SwiperSlide>
            ))}
        </Swiper>

        <button
          data-show={!isDesktop}
          className={`group flex items-center justify-center p-4 border hover:bg-primary-500 border-primary-500 rounded-xl cursor-pointer z-10 data-[show=true]:hidden transition-colors ${index+slidesPerView === stories.length && 'opacity-0 pointer-events-none'}`}
          onClick={onRightArrowClickHandler}
        >
          <ArrowRight className="w-6 h-6 text-primary-500 group-hover:text-white " />
        </button>

      </div>
      <div className="flex gap-5 z-20 md:hidden">
        {sliderPositionElements.map((element: JSX.Element) => element)}
      </div>
    </div>
  );
};
