import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { LangProvider } from './Lang/useLang';
import { PopIframeProvider } from './modal/popIframe';
import { BrowserRouter } from 'react-router-dom';

if (window.location.href.includes('//www.')) {
  window.location.href = window.location.href.replace('www.', '');
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <LangProvider>
      <PopIframeProvider>
        <App />
      </PopIframeProvider>
    </LangProvider>
  </BrowserRouter>
);
