import { FC } from 'react';
import { ReactComponent as FirstSVG } from '../../../../assets/svgs/med-team.svg';
import { ReactComponent as SecondSVG } from '../../../../assets/svgs/benefit-2.svg';
import { ReactComponent as ThirdSVG } from '../../../../assets/svgs/target-arrow.svg';
import { ReactComponent as FourthSVG } from '../../../../assets/svgs/computer-tie.svg';
import { ReactComponent as FifthSVG } from '../../../../assets/svgs/book-star.svg';
import { ReactComponent as SixthSVG } from '../../../../assets/svgs/human-gear.svg';
import { useLang } from '../../../../Lang/useLang';
import { Title } from '../../../UIKit/title/Title';


export const ProvidersHomeBenefits: FC = () => {
  const { $ }: any = useLang();
  const items = [
    {
      image: <FirstSVG />,
      description: $('PROVIDERS_HOME_BENEFIT_1'),
    },
    {
      image: <SecondSVG />,
      description: $('PROVIDERS_HOME_BENEFIT_2'),
    },
    {
      image: <ThirdSVG />,
      description: $('PROVIDERS_HOME_BENEFIT_3'),
    },
    {
      image: <FourthSVG />,
      description: $('PROVIDERS_HOME_BENEFIT_4'),
    },
    {
      image: <FifthSVG />,
      description: $('PROVIDERS_HOME_BENEFIT_5'),
    },
    {
      image: <SixthSVG />,
      description: $('PROVIDERS_HOME_BENEFIT_6'),
    },
  ]

  return (
    <div className="w-full flex flex-col items-center justify-center px-6 py-10 md:py-20 md:px-16">
      <Title className="text-[29px] md:text-[40px] max-w-[900px] mt-0 mb-6 md:mb-11" text={$('PROVIDERS_HOME_BENEFITS_TITLE')}/>

      <div className='w-full max-w-[920px] grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 gap-6 md:gap-[30px]'>
        {
          items.map(({ image, description }, index) => (
            <div className='bg-aqua-300 rounded-2xl p-6' key={index}>
              <div className='flex justify-center h-[92px]'>{image}</div>
              <p className='mt-5 text-lg text-gray-300 text-center'>{description}</p>
            </div>
          ))
        }
      </div>
    </div>
  );
};