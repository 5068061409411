import React from 'react';
import { Title } from '../../../../UIKit/title/Title';
import { Card } from '../../../../UIKit/card/Card';
import { Button } from '../../../../UIKit/button/Button';
import { ReactComponent as AISvg } from '../../../../../assets/svgs/ai.svg';
import { ReactComponent as ChartSvg } from '../../../../../assets/svgs/chart.svg';
import { ReactComponent as DBSvg } from '../../../../../assets/svgs/db.svg';
import { ReactComponent as NutritionSvg } from '../../../../../assets/svgs/nutrition.svg';
import { useLang } from '../../../../../Lang/useLang';
import { twMerge } from 'tailwind-merge';
import { Link } from 'react-router-dom';

export const AboutScienceSection: React.FC = () => {
  const { $ }: any = useLang();
  return (
    <div className="flex flex-col items-center justify-center mx-8 my-12 md:m-16 ">
      <div className='w-full max-w-content flex flex-col items-center justify-center gap-12'>
        <div className='flex w-full flex-col items-center justify-center'>
          <Title 
            text={$('ABOUT_SCIENCE_TITLE')} 
            className="mb-4" 
          />
          
          <span className="flex text-lg text-zinc-600 text-justify md:text-left">
            {$('ABOUT_SCIENCE_TEXT')}
          </span>
        </div>
      
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <Card className="w-full flex flex-col items-center px-5 py-6 gap-5 shadow-sm">
            <AISvg className="w-16  h-16" />
            <div className="font-semibold text-zinc-600">
              {$('ABOUT_SCIENCE_AI')}
            </div>
          </Card>
          <Card className="w-full flex flex-col items-center px-5 py-6 gap-5 shadow-sm">
            <ChartSvg className="w-16  h-16" />
            <div className="font-semibold text-zinc-600">
              {$('ABOUT_SCIENCE_MEDICAL_EXPERTISE')}
            </div>
          </Card>
          <Card className="w-full flex flex-col items-center px-5 py-6 gap-5 shadow-sm">
            <DBSvg className="w-16  h-16" />
            <div className="font-semibold text-zinc-600">
              {$('ABOUT_SCIENCE_DB')}
            </div>
          </Card>
          <Card className="w-full flex flex-col items-center px-5 py-6 gap-5 shadow-sm">
            <NutritionSvg className="w-16  h-16" />
            <div className="font-semibold text-zinc-600">
              {$('ABOUT_SCIENCE_NUTRITION')}
            </div>
          </Card>
        </div>
      <Card className="flex flex-col md:flex-row items-center justify-center p-14 shadow-sm gap-8">
        <span className="text-zinc-600 text-lg text-center md:text-left">
          {$('ABOUT_SCIENCE_CONTACT_US_1')}{' '}
          <b>{$('ABOUT_SCIENCE_CONTACT_US_2')} </b>
          {$('ABOUT_SCIENCE_CONTACT_US_3')}{' '}
          <b>{$('ABOUT_SCIENCE_CONTACT_US_4')}</b>{' '}
          <span className="font-bold text-primary-500">
            {$('ABOUT_SCIENCE_CONTACT_US_5')}
          </span>
        </span>
        <Link to={'/contact'} className="w-full md:w-fit">
          <Button 
            className={twMerge(
              'w-full flex items-center justify-center font-semibold text-base px-6 py-4',
              'text-white bg-primary-500 hover:bg-primary-500 rounded-xl',
              'whitespace-nowrap md:w-auto ',
              )} 
              >
            {$('ABOUT_SCIENCE_CONTACT_US')}
          </Button>
        </Link>
      </Card>
      </div>
    </div>
  );
};
