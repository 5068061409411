import React from 'react';

import { Header } from '../../layout/header/Header';
import { Footer } from '../../layout/footer/Footer';
import { Breaker } from '../../UIKit/breaker/Breaker';
import { ScienceIntroduction } from './components/ScienceIntroduction';
import { AllergenPrevention } from './components/AllergenPrevention';
import { RecruitmentSection } from '../home/home-sections';
import { ScienceInnovation } from './components/ScienceInnovation';
import { ScienceChart } from './components/ScienceChart';
import { SciencePublications } from './components/SciencePublications';

export const SciencePage: React.FC = () => {

  return (
    <div className='bg-white'>
        <Header className='bg-white'/>
        <ScienceIntroduction />
        <AllergenPrevention />
        <Breaker className='breaker-disable_gradient bg-aqua'/>
        <ScienceChart />
        <Breaker className='breaker-disable_gradient bg-aqua'/>
        <ScienceInnovation />
        <SciencePublications />
        <RecruitmentSection />
        <Footer />
    </div>
  );
};
