import { useLang } from "../../../../Lang/useLang";
import { Kids } from "./graph/Kids";
import { LeftGraph } from "./graph/LeftGraph";
import { RightGraph } from "./graph/RightGraph";

export function KidsSection() {
  const { $ }: any = useLang();
  
  return (
    <div className="w-full flex flex-col items-center justify-center relative">
      <div className="w-full max-w-content flex flex-col items-center justify-center gap-8 px-12 py-16 ">
        <div 
          className="w-full flex flex-col text-xl text-center font-light leading-relaxed"
        >
          {$("PREGNANCY_KIDS_TITLE")} 
          <span className="font-bold text-primary-500">
            {$("PREGNANCY_KIDS_TITLE_2")}
          </span>
        </div>
        <div className="w-full max-w-[900px] flex items-start mt-8 h-[2px] bg-primary-200">
          <div className="w-full md:w-[200px] lg:w-[270px] h-[2px] bg-primary-500" />
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center mt-8 mb-16 gap-12">
          
          <Kids />
          <div className="flex flex-col justify-start items-start gap-8">
            <span className="text-xl font-bold">
              {$("PREGNANCY_KIDS_LIST_TITLE")} 
            </span>
            <ul className="ml-6 list-disc">
              <li>{$("PREGNANCY_KIDS_LIST_1")}</li>
              <li>{$("PREGNANCY_KIDS_LIST_2")}</li>
              <li>{$("PREGNANCY_KIDS_LIST_3")}</li>
          </ul>
          </div>
        </div>

        <span className="text-3xl text-center font-light leading-relaxed">
          {$("PREGNANCY_KIDS_INNOVATIVE_TEXT")} <b className="font-bold">{$("PREGNANCY_KIDS_INNOVATIVE_TEXT_2")}</b> {$("PREGNANCY_KIDS_INNOVATIVE_TEXT_3")} <b className="font-bold">{$("PREGNANCY_KIDS_INNOVATIVE_TEXT_4")}</b>.
        </span>

        <div className="w-full max-w-[700px] flex flex-col rounded-lg shadow-sm items-center justify-center bg-white p-14 my-16 gap-8 z-20">
          <span className="text-lg text-center">
            {$("PREGNANCY_KIDS_SURVEY_TITLE")} <b className="font-bold">{$("PREGNANCY_KIDS_SURVEY_TITLE_2")}</b>
          </span>
          <button
            className="text-white font-bold rounded-lg bg-primary-500 px-5 py-3 hover:bg-primary-600 transition-colors"
          >
            {$("PREGNANCY_KIDS_SURVEY_BUTTON")}
          </button>
        </div>
      </div>
      <div className="left-0 bottom-0 absolute z-0">
        <LeftGraph />
      </div>
      <div className="hidden lg:flex right-0 bottom-0 absolute z-0">
        <RightGraph />
      </div>
    </div>
  )
}