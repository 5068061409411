import React from 'react';
import { Breaker } from '../../../../../../UIKit/breaker/Breaker';
import './indicator.css'

interface IndicatorProps {
  index: number;
  setIndex: (value: number) => void
}

export const Indicator: React.FC<IndicatorProps> = ({ index, setIndex = () => {} }) => {
  return (
    <div className='flex relative'>
      <Breaker className='absolute top-1/2 -translate-y-1/2'/>
      <input 
        className="w-3/4 mx-auto bg-transparent appearance-none relative range_thumb"
        type="range" 
        min={1} 
        max={4} 
        step={1}
        value={index}
        onChange={({ target }) => setIndex(+target.value)} 
      />
    </div>
  );
};
