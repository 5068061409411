import { BannerTrans, Inner } from "../../../UIKit"
import { RowsHigh } from "../../../UIKit/Line/Line"
import { Card } from "../uikit/card/Card"

import educationSrc from "../../../../assets/images/education.png";
import virtualSrc from "../../../../assets/images/virtual.png";
import insuranceSrc from "../../../../assets/images/insurance.png";
import { useLang } from "../../../../Lang/useLang";

export const Cards = () => {
    const { $ } = useLang();

    return (
        <BannerTrans>
            <Inner thin>
                <RowsHigh>
                    <Card
                        pic={educationSrc}
                        title={$('POST_CARDS_EDU_TITLE')}
                        text={$('POST_CARDS_EDU_TEXT')}
                    />
                    <Card
                        pic={virtualSrc}
                        title={$('POST_CARDS_VIRTUAL_TITLE')}
                        text={$('POST_CARDS_VIRTUAL_TEXT')}
                        flexEnd
                    />
                    <Card
                        pic={insuranceSrc}
                        title={$('POST_CARDS_INSURANCE_TITLE')}
                        text={$('POST_CARDS_INSURANCE_TEXT')}
                    />
                </RowsHigh>
            </Inner>
        </BannerTrans>
    )
}