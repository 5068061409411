import React from 'react';
import { Subtitle } from '../../../UIKit/subtitle/Subtitle';
import { useLang } from '../../../../Lang/useLang';
import { useMarkdown } from '../../../../hooks/useMarkdown';
import { MarkdownShow } from '../../../UIKit/MarkdownShow/MarkdownShow';


export const EffectsOfWithdrawal: React.FC = () => {
    const { $ }: any = useLang();
    const fetchEffectContent = useMarkdown('WITHDRAWAL_POLICY_EFFECTS_CONTENT');
  return (
    <div className="flex flex-col gap-8">
      <Subtitle>{$('WITHDRAWAL_POLICY_EFFECTS_TITLE')}</Subtitle>
      <MarkdownShow fetch={fetchEffectContent} className="flex flex-col gap-8"/>
    </div>
  );
};
