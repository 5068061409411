import { deleteCookie, getCookie, setCookie } from "../helpers/storage";

const KEY_LANG_COOKIE = 'MY_LANG_COOKIE_MYOR';
const KEY_COUNTRY_COOKIE = 'MY_COUNTRY_COOKIE_MYOR';

export const getStoredLang = () => getCookie(KEY_LANG_COOKIE) || '';
export const setStoredLang = (value: string) => setCookie(KEY_LANG_COOKIE, value.toString());
export const removeStoredLang = () => deleteCookie(KEY_LANG_COOKIE);


export const getStoredCountry = () => (getCookie(KEY_COUNTRY_COOKIE) || '');
export const setStoredCountry = (value: string) => setCookie(KEY_COUNTRY_COOKIE, value);
export const removeStoredCountry = () => deleteCookie(KEY_COUNTRY_COOKIE);