import { ChevronDown } from "lucide-react";
import { Button } from "../../../../UIKit/button/Button"
import { DropDown } from "../../../../UIKit/dropdown/DropDown"
import { useLang } from "../../../../../Lang/useLang";
import { useEffect, useRef, useState } from "react";
import c from 'classnames';
import { Line } from "../../../../UIKit/Line/Line";

import './style.css';
import { OptionType } from "../../../../../types/type";

type ButtonGroupProps = {
    isMenuOpen?: boolean;
    isBlack?: boolean;
};


export const DDCountry: React.FC<ButtonGroupProps> = ({
    isMenuOpen = true,
    isBlack = true
}) => {
    const { ddCountries, getSelectedLang }: any = useLang();
    const [isLanguageDropDownOpen, setIsLanguageDropDownOpen] = useState(false);

    const wrapRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener('click', handleCloseDropDown);
        return () => {
            document.removeEventListener('click', handleCloseDropDown);
        }
    }, [])

    const handleCloseDropDown = (e: any) => {
        if (!wrapRef.current?.contains(e.target)) {
            setIsLanguageDropDownOpen(false);
        }
    }

    const onLanguageDropDownClick = (e: any) => {
        setIsLanguageDropDownOpen(!isLanguageDropDownOpen);
    };
    

    const handleSelect = (selected: OptionType) => {
        setIsLanguageDropDownOpen(false);
        ddCountries().onSelect(selected);
    }


    return (
        <div ref={wrapRef} className="lang-picker-container ">
            <DropDown
                {...ddCountries()}
                onSelect={handleSelect}
                isOpen={isLanguageDropDownOpen}
                optionsClassName="top-10 w-fit"
                noChevronIcon={true}
            >
                <Button
                    onClick={onLanguageDropDownClick}
                    transparent
                    className={c(
                        'flex whitespace-nowrap items-center justify-around px-3 gap-2 transition-opacity ease-in-out',
                        isBlack && 'text-zinc-900 border border-zinc-900',
                        'stretch'
                    )}
                >
                    <Line>
                        {getSelectedLang().flag}
                        {getSelectedLang().country}
                    </Line>
                    <ChevronDown className="w-5 h-5" />
                </Button>
            </DropDown>
        </div>
    )
}