import { ExternalLink } from "lucide-react";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface ScienceButtonProps {
  children: ReactNode;
  onClick?: () => void;
  className?: string;
}

export function ScienceButton({children, onClick = () => {}, className }:ScienceButtonProps) {
  return (
    <button
      onClick={onClick}
      className={twMerge(
        'group w-full md:w-fit h-fit flex flex-row items-center justify-center px-5 py-4 text-sm font-semibold',
        'rounded-xl bg-transparent border border-primary-500 text-primary-500',
        'whitespace-nowrap gap-2 hover:bg-primary-500 hover:text-white z-50 ',
        'transition-colors',
        className
      )}
    >
      {children}
      <ExternalLink className="w-5 h-5" />
    </button>
  )
}