import React, { useEffect, useState } from 'react';
import { Card } from '../card/Card';
import { twMerge } from 'tailwind-merge';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { observer } from '../../../utils/observer';
import styles from './Accordion.module.css';

interface AccordionProps {
  className?: string;
  activeClassName?: string;
  title: React.ReactNode;
  content: React.ReactNode;
}

export const Accordion: React.FC<AccordionProps> = ({
  className,
  activeClassName,
  title,
  content
}) => {
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    if (open) {
      observer.shout('faq_click', title);
    }
  }, [open, title]);

  return (
    <Card className={twMerge(
      'flex flex-col p-5 gap-8 shadow-sm hover:shadow-md',
      className,
      open && activeClassName
    )}
    >
      <div
        className="flex flex-row items-center justify-between cursor-pointer gap-3"
        onClick={() => setOpen(!open)}
      >
        <span className="flex-1 text-base text-zinc-900 font-semibold">
          {title}
        </span>
        {open ? (
          <ChevronUp className="w-5 h-5 text-primary-500" />
        ) : (
          <ChevronDown className="w-5 h-5 text-primary-500" />
        )}
      </div>
      {open ? (
        <div className={styles.content}>
          <div className="text-sm text-zinc-600">
            {content}
          </div>
        </div>
      ) : <></>}
    </Card>
  );
};
