import { H2, P } from '../../../../UIKit';
import { Rows } from '../../../../UIKit/Line/Line';
import styles from './Card.module.css';

interface IProps {
    pic: string,
    text: string,
    title: string,
    flexEnd?: boolean
}

export const Card = ({ pic, text, title, flexEnd }: IProps) => {
    return (
        <div className={[
            styles.container,
            flexEnd && styles.flexEnd
        ].join(' ')}>
            <div>
                <img src={pic} alt={text} />
            </div>
            <div>
                <Rows>
                    <H2>{title}</H2>
                    <P>{text}</P>
                </Rows>
            </div>
        </div>
    )
}