import React, { useEffect, useState } from 'react';
import { useLang } from '../../../../Lang/useLang';
import { Title } from '../../../UIKit/title/Title';
import { Inner, Line, Tag } from '../../../UIKit';
import { api, routes } from '../../../../helpers/api';


interface ITags {
    id: string,
    title: string
}

export const Specialist: React.FC = () => {
    const [tags, setTags] = useState<ITags[]>([]);

    const { $ }: any = useLang();

    useEffect(() => {
        api.get(routes.server + "/blogs/tags?prefix=POSTPARTUM_TAG_HIW_")
            .then((resp: ITags[]) => setTags(resp))
    }, [])
    return (
        <div className='w-full max-w-content flex flex-col items-center py-8 md:py-10 px-6'>
            <Title
                className='text-[29px] leading-[45px] md:text-[40px] md:leading-[48px] mt-0 mb-6'
                text={$('POSTPARTUM_SPECIALIST_TITLE')}
            />
            <p className='max-w-[915px] text-lg text-gray-300 text-center mb-6'>
                {$('POSTPARTUM_SPECIALIST_SUBTITLE')}

            </p>
            <Inner thin>
                <Line center>
                    {
                        tags.map(({ title, id }) => (
                            <Tag key={id} text={title} />
                        ))
                    }
                </Line>
            </Inner>
        </div>
    );
};
