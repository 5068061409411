import {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { getStoredCountry, getStoredLang, removeStoredLang, setStoredCountry, setStoredLang } from "./storage";
import { api, routes } from "../helpers/api";
import { AustraliaFlagIcon, MexicoFlagIcon, SpainFlagIcon, USAFlagIcon } from '../components/UIKit/icon/Icon';
import { Line } from "../components/UIKit/Line/Line";
import { observer } from "../utils/observer";

export const langContext = createContext<{
  isLoading: boolean;
  langId: string;
  langIndex: number;
  countries: Country[];
  country: string;
  setSelectedLangs: (langs: string[]) => void;
  setLang: (id: number | string, defaultLang?: string) => void;
  $: (params?: string, ...args: Elem[]) => Elem;
  $flat: (params?: string, ...args: Elem[]) => String;
  getLangs: () => Langs;
  dd: () => {
    selectedOption: string,
    onSelect: (selected: { id: string | number }) => void,
    options: Langs
  },
  setCountry: (key: string) => void;
  getCountries: () => Countries;
  ddCountries: () => {
    selectedOption: string,
    onSelect: (selected: { id: string }) => void,
    options: Countries
  },
  getSelectedLang: () => SelectedLang;
}>({
  isLoading: true,
  langId: '',
  langIndex: 0,
  countries: [],
  country: '',
  setSelectedLangs: () => { },
  setLang: () => { },
  $: () => <></>,
  $flat: () => "",
  getLangs: () => [],
  dd: () => ({
    selectedOption: '',
    onSelect: () => { },
    options: []
  }),
  setCountry: () => { },
  getCountries: () => [],
  ddCountries: () => ({
    selectedOption: '',
    onSelect: () => { },
    options: []
  }),
  getSelectedLang: () => (
    {
      url: '',
      lang: '',
      country: '',
      flag: <></>
    }
  )
});

const Provider = langContext.Provider;

export const useLang = () => {
  const data = useContext(langContext);
  return { ...data };
};

export const toBold = {
  b: (value: string) => <b>{value}</b>
}

type Keys = {
  [key: string]: string[]
}

type SelectedLang  = {
  url: string;
  lang: string;
  country: string;
  flag: Elem;
}

type Lang = {
  _id: string;
  index: number;
  national: string;
  key: keyof Flags;
  url: string;
}

type Langs = {
  id: string,
  text: string
}[]

type Country = {
  _id: string;
  national: string;
  key: keyof Flags;
  url: string;
  selectedLangs: string[];
}

type Countries = {
  id: string,
  text: Elem
}[]

const flags: Flags = {
  "us": <USAFlagIcon className='w-5 h-5' />,
  "sp": <SpainFlagIcon className='w-5 h-5'/>,
  "mx": <MexicoFlagIcon className='w-5 h-5'/>,
  "au": <AustraliaFlagIcon className='w-5 h-5'/>
}

export const LangProvider = ({
  children
}: {
  children: Elem
}) => {
  const [keys, setKeys] = useState<Keys>({});
  const [langs, setLangs] = useState<Lang[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [lang, setLang] = useState<string>(getStoredLang());
  const [country, setCountry] = useState('');
  const [selectedLangs, setSelectedLangs] = useState<string[]>([]);

  const langIndex = langs.find(l => l._id === lang)?.index || 0;

  useEffect(() => {
    setStoredLang(lang);
  }, [lang])

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const keysList = await api.get(routes.server + "/translation/landing");
    const langsList = await api.get(routes.server + "/translation/langs");
    const countriesList = await api.get(routes.server + "/translation/countries/env");
    const selectedCountryId = getStoredCountry() || getPreferredUserCountry()?._id || countriesList[0]._id;
    const selectedCountry = countriesList.find((i: Country) => i._id === selectedCountryId);
    const selectedLangs = countriesList.find((i: Country) => i._id === selectedCountryId)?.selectedLangs;
    const lang = getStoredLang() !== '' ? getStoredLang() : langsList.find((i: Lang) => i._id === selectedCountry.defaultLang)?._id;
  
    function getPreferredUserCountry() {
      const { languages, language } = navigator || {};
      
      const userLocaleData: any = 
        [language, ...languages].map(lang => {
        const [langCode, countryCode] = lang?.split('-')
        return ({
          langCode, 
          countryCode, 
          full: lang,
        })
      })
      let userCountry: any = {}
      const userCountryCodes = userLocaleData.map(({ countryCode }: { countryCode: string}) => countryCode?.toLowerCase()).filter((countryCode: string) => countryCode)

      userCountryCodes.find((item: any) => { 
        const result = countriesList.reduce((res: any, curr: any) => ({ ...res, [curr.key]: curr }), {})[item]
        if(result) userCountry = result;
        return result
      })

      return userCountry;

    }
    
    setKeys(keysList);
    setLangs(langsList);
    setCountries(countriesList);
    setIsLoading(false);
    setCountry(selectedCountryId);
    setSelectedLangs(selectedLangs);
    setLang(lang);
  }

  const setLangById = (id: string) => {
    const selected = langs.find(l => l._id === id) || lang;
    if(selected) {
      observer.shout("region_picker_select", { 
        lang: langs.find(l => l._id === id)?.key,
        country: countries.find(c => c._id === country)?.key
      })

      setLang(id);
    }
  }

  const getSelectedLang = (): SelectedLang => (
    {
      url: `${process.env.REACT_APP_SURVEY_PORT}/${(countries.find(i => i._id === country)?.url)}`,
      lang: (langs[langIndex]?.key as string || '').toUpperCase(),
      country: countries.find(i => i._id === country)?.national || '',
      flag: flags[countries.find(i => i._id === country)?.key] || <></>
    }
  )

  const getParams = (params: string) => params.split(":");

  const getMappedValues = (params?: string, ...args: Elem[]) => {
    if (!Object.keys(keys).length) {
      return [""];
    }
    if (!keys) {
      return [""];
    }
    if (!params) {
      return [""];
    }
    const [key, extra] = getParams(params);

    if (extra) {
      args.push(extra);
    }

    let tagsObj: any = {};
    if (typeof (args[0]) === 'object' && !args[0].$$typeof) {
      tagsObj = args[0];
      args.splice(0, 1);
    }

    let value = (keys[key] && keys[key][langIndex]) || `*${key}*`;

    if (!keys[key]) {
      return [value];
    }

    const mapped = value
      .replace(/({arg([0-9]+)})/gi, '||$1||')
      .replace(/(\*(.*)\*)/gi, '||$1||')
      .replace(/(<[a-z]+>([^<]+)<[a-z]+>)/gi, '||$1||')
      .split('||')
      .reduce(
        (state: Elem[], val: string) => {
          const arg = val.match(/{arg([0-9]+)}/);
          const bold = val.match(/\*(.*)\*/);
          const tag = val.match(/<([a-z]+)>([^<]+)<[a-z]+>/);

          if (tag && tag.length > 2) {
            const [_, field, value] = tag;
            const cond = tagsObj[field];
            if (cond) {
              state.push(cond(value));
            }
          } else if (arg) {
            state.push(args[+arg[1] - 1]);
          } else if (bold) {
            state.push(<span className="lang_bold">{bold[1]}</span>);
          } else {

            state.push(val);
          }
          return state;
        },
        []
      );

      return mapped;
  }

  const value = {
    getSelectedLang,
    isLoading,
    langs,
    langId: lang,
    langIndex,
    countries,
    country,
    setLang: (id: string | number, defaultLang: string = '') => {
      if (id) {
        setLangById(id as string);
      } else {
        setLangById(defaultLang);
      }
    },

    setSelectedLangs,
    $: (params?: string, ...args: Elem[]) => {
      const mapped = getMappedValues(params, ...args);
      const [key] = getParams(params || "");

    
      if (mapped.length === 1) {
        return <span data-key={key}>{mapped[0]}</span>;
      }

      return mapped.map((m, i) => <span key={i} data-key={key}>{m}</span>);
    },
    $flat: (params?: string, ...args: Elem[]) => {
      const mapped = getMappedValues(params, ...args);

      return mapped[0];
    },
    getLangs: () => langs.filter(i => selectedLangs.find(id => i._id === id)).map(i => (
      {
        id: i._id,
        text: i.national
      }
    )),
    dd: () => (
      {
        selectedOption: lang,
        onSelect: (selected: { id: number | string }) => setLangById(selected.id as string),
        options: value.getLangs()
      }
    ),
    setCountry: (key: string) => {
      if(!countries.length) { return; }
      const { _id  } = countries.find(c => c._id === key) || countries[0];

      observer.shout("region_picker_select", { 
        lang: langs.find(l => l._id === lang)?.key,
        country: countries.find(c => c._id === _id)?.key
      })

      removeStoredLang();
      setStoredCountry(_id as string);
      window.location.reload();
    },
    getCountries: () => countries.map(i => (
      {
        id: i._id,
        text: (
          <Line>
            {flags[i.key]}
            {i.national}
          </Line>
        )
      }
    )),
    ddCountries: () => (
      {
        selectedOption: country,
        onSelect: (selected: { id: string }) => value.setCountry(selected.id as string),
        options: value.getCountries()
      }
    ),
  };

  return <Provider value={value}>{children}</Provider>;
};
