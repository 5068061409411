import React from 'react';
import { Title } from '../../../UIKit/title/Title';
import { useLang } from '../../../../Lang/useLang';
import mapPNG from '../../../../assets/images/fullMap.png';

export const ProvidersHomeNetwork: React.FC = () => {
    const { $ }: any = useLang();
    return (
      <div className="w-full flex flex-col items-center px-6 md:px-12 py-10 md:py-20 overflow-x-hidden">
            <Title className='m-0 text-[29px] md:text-[42px]' text={$('PROVIDERS_HOME_NETWORK_TITLE')}/>
            <p className='text-lg text-center text-gray-300 mt-6 mb-12'>{$('PROVIDERS_HOME_NETWORK_SUBTITLE')}</p>
            <img src={mapPNG} className="scale-125 md:scale-100"/>
      </div>
    );
  };
