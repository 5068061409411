import React from 'react';
import './TermsOfServicePage.css';
import { Header } from '../../layout/header/Header';
import { Footer } from '../../layout/footer/Footer';
import { MarkdownShow } from '../../UIKit/MarkdownShow/MarkdownShow';
import { useMarkdown } from '../../../hooks/useMarkdown';

export const TermsOfServicePage: React.FC = () => {
  const fetchTOScontent = useMarkdown('TERMS_OF_SERVICE')

  return (
    <div className="tos-bg">
      <Header />
      <div className="tos-content-container">
        <MarkdownShow fetch={fetchTOScontent} className="tos flex flex-col gap-8 mt-8" />
      </div>
      <Footer />
    </div>
  );
};
