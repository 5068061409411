import React, { useCallback, useEffect, useState } from 'react';

import { Header } from '../../layout/header/Header';
import { Footer } from '../../layout/footer/Footer';
import { useLang } from '../../../Lang/useLang';
import { Card } from '../../UIKit/card/Card';
import { Title } from '../../UIKit/title/Title';
import { Input } from '../../UIKit/input/Input';
import { useForm } from 'react-hook-form';
import { Label } from '../../UIKit/label/Label';
import { Button } from '../../UIKit/button/Button';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormDropDown } from '../../UIKit/FormDropDown/FormDropDown';
import { ToggleElement } from './components/ToggleElement';
import { SwitchElement } from './components/SwitchElement';
import { appointmentSchema, AppointmentSchemaType } from '../../../schemas/appointment-schema';
import { sendAppointmentData } from '../../../api/specialist-api';
import { appointmentAdapter } from '../../../helpers/appointmentAdapter';
import { insuranceOptions, stateOptions, topicOptions } from '../../../constants/appointment';
import { toast } from 'react-toastify';
import { TAppointmentFormData, TOption, TTopicOptions } from '../../../types/type';
import { useNavigate } from 'react-router-dom';
import { Modal } from '../../UIKit/modal/Modal';
import { observer } from '../../../utils/observer';
import { COUNTRIES } from '../../../types/countries';
import { getCookie } from '../../../helpers/storage';
import { LINKS } from '../../../types/links';

const steps = [0, 1, 2]

export const AppointmentPage: React.FC = () => {
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        watch,
        formState: { errors }
    } = useForm<AppointmentSchemaType>({
        resolver: zodResolver(appointmentSchema),
        defaultValues: {
            appointmentFor: { label: 'Me', value: 'Me' },
            topics: []
        }
    });
    const [currentStep, setCurrentStep] = useState(0);
    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false);
    const { $, $flat, getSelectedLang, country }: any = useLang();
    const navigator = useNavigate();
    const selectedCountry = getSelectedLang()?.country;

    const appointmentOptionAdapter = (array: TOption[]) => array.map(({ label, value }) => ({ label: $flat(label), value: $flat(value) }))

    const adaptedTopicOptions: TTopicOptions = Object.keys(topicOptions).reduce((res, curr) => ({ ...res, [curr]: appointmentOptionAdapter(topicOptions[curr]) }), {})
    const adaptedInsuranceOptions: TOption[] = appointmentOptionAdapter(insuranceOptions)
    const adaptedStateOptions: TOption[] = appointmentOptionAdapter(stateOptions)
    const isAustralia = country === COUNTRIES.AUSTRALIA;
    const isDisabledStep1 = watch('appointmentFor') && watch('fullName') && (isAustralia ? true : watch('state'));
    const isDisabledStep2 = watch('phoneNumber') && watch('email') && (isAustralia ? true : watch('insurance'));

    const handleSetValue = (key: any, option: TOption | TOption[]) => {
        setValue(key, option)
    }

    const isTopicSelected = (topic: TOption) => watch('topics').some(topicItem => topicItem.value === topic.value)
    const getFlow = useCallback(() => {
        return getValues().appointmentFor.value === "Me" ? "me" : "my child"
    }, [getValues]);

    const onSubmit = async (data: TAppointmentFormData) => {

        const adaptedData = appointmentAdapter(data)
        adaptedData.ga = getCookie("_ga");

        setLoading(true)
        
        const response = await sendAppointmentData({ email: adaptedData.email, payload: adaptedData })
        setLoading(false)
        if (response) {
            observer.shout("appointment_booked", {
                phone: adaptedData.phoneNumber,
                email: adaptedData.email,
                flow: getFlow()
            });

            toast.success($("APPOINTMENT_SUCCESS_MESSAGE"))
            navigator('/')
        }

    };

    useEffect(() => {
        observer.shout("appointment_step", {
            step: currentStep,
            flow: getFlow()
        });
    }, [currentStep, getFlow])

    return (
        <div className='w-full flex h-auto flex-col items-center'>
            <Header className='bg-white' />
            <div>
                <div className='max-w-[550px] mx-auto mt-14'>
                    <Title className="my-0 text-3xl md:text-[40px]" text={$("APPOINTMENT_PAGE_TITLE")} />
                    <p className='text-center text-gray-300 mt-3'>{$("APPOINTMENT_PAGE_SUBTITLE")}</p>
                    <div className='flex gap-x-2 md:gap-x-6 justify-center mt-6'>
                        {
                            steps.map(step => (<div key={step} className={`w-20 md:w-[120px] h-1 rounded-[20px] bg-gray-200 ${step <= currentStep && '!bg-primary-500'}`} />))
                        }
                    </div>
                </div>

                <div className="flex items-center justify-center my-16 px-4 md:px-8">
                    <Card className={`flex items-center w-full max-w-[450px] py-12 px-8 shadow-sm ${currentStep === 2 && 'max-w-[780px]'}`}>
                        {!currentStep && (
                            <div className='w-full flex flex-col items-center gap-y-6'>
                                <Title className="my-0 text-2xl font-medium" text={$("APPOINTMENT_FORM_HEADER")} />

                                <Label text={$("APPOINTMENT_FOR_LABEL")} containerClassName="w-full">
                                    <SwitchElement
                                        selectedOption={watch('appointmentFor')}
                                        handleChange={(option: TOption) => {
                                            handleSetValue('appointmentFor', option);
                                            handleSetValue('topics', []);
                                        }}
                                    />
                                </Label>

                                <Label text={$("APPOINTMENT_NAME_LABEL")} containerClassName="w-full">
                                    <Input
                                        className="px-4 py-6"
                                        placeholder={$flat("APPOINTMENT_NAME_LABEL")}
                                        name="fullName"
                                        register={register}
                                        error={errors.fullName}
                                    />
                                </Label>
                                {!isAustralia && (
                                    <FormDropDown
                                        options={adaptedStateOptions}
                                        placeholder="State"
                                        label={$("APPOINTMENT_STATE_LABEL")}
                                        selectedOption={watch('state')}
                                        handleChange={(option: TOption) => handleSetValue('state', option)}
                                        hideOnSelect
                                    />
                                )}
                                <Button
                                    className="!w-[200px] flex items-center justify-center font-semibold text-base hover:bg-primary-600 "
                                    onClick={() => setCurrentStep(1)}
                                    disabled={!isDisabledStep1}
                                >
                                    Next step
                                </Button>
                            </div>
                        )}
                        {
                            currentStep === 1 && (
                                <div className='w-full flex flex-col items-center gap-y-6'>
                                    <Title className="my-0 text-2xl font-medium" text={$("APPOINTMENT_PAGE_TWO_TITLE")} />
                                    {!isAustralia && (
                                        <>
                                            <FormDropDown
                                                options={adaptedInsuranceOptions}
                                                placeholder={$flat("APPOINTMENT_INSURANCE_LABEL")}
                                                label={$("APPOINTMENT_INSURANCE_LABEL")}
                                                selectedOption={watch('insurance')}
                                                handleChange={(option: TOption) => handleSetValue('insurance', option)}
                                                hideOnSelect
                                            />
                                            <Label text={$("APPOINTMENT_SPECIFIC_DIETITIAN")} containerClassName="w-full">
                                                <Input
                                                    className="px-4 py-6"
                                                    placeholder={$flat("APPOINTMENT_SPECIFIC_DIETITIAN_PLACEHOLDER")}
                                                    name="specificDietitian"
                                                    register={register}
                                                />
                                            </Label>
                                        </>
                                    )}
                                    <Label text={$("APPOINTMENT_PHONE_LABEL")} containerClassName="w-full">
                                        <Input
                                            className="px-4 py-6"
                                            placeholder={"Phone number"}
                                            name="phoneNumber"
                                            register={register}
                                            error={errors.phoneNumber}
                                        />
                                    </Label>
                                    <Label text={$("APPOINTMENT_EMAIL_LABEL")} containerClassName="w-full">
                                        <Input
                                            className="px-4 py-6"
                                            placeholder={"Email address"}
                                            name="email"
                                            register={register}
                                            error={errors.email}
                                        />
                                    </Label>
                                    <div className='flex gap-x-4'>
                                        <Button
                                            className="!w-[200px] flex items-center justify-center font-semibold text-base max-w-[120px] hover:bg-primary-600 "
                                            onClick={() => setCurrentStep(0)}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            className="!w-[200px] flex items-center justify-center font-semibold text-base max-w-[120px] hover:bg-primary-600 "
                                            onClick={() => setCurrentStep(2)}
                                            disabled={!isDisabledStep2}

                                        >
                                            Next
                                        </Button>

                                    </div>
                                </div>
                            )
                        }
                        {
                            currentStep === 2 && (
                                <div className='w-full flex flex-col items-center gap-y-6'>
                                    <Title className="my-0 text-2xl font-medium" text={$("APPOINTMENT_TOPICS_LABEL")} />
                                    <div className='flex flex-wrap gap-2.5 justify-center'>
                                        {adaptedTopicOptions[watch('appointmentFor').value]?.map((item: TOption) => (
                                            <ToggleElement
                                                key={item.value}
                                                option={item}
                                                controlled
                                                active={isTopicSelected(item)}
                                                renderCheckbox
                                                handleToggle={(option: TOption) => handleSetValue('topics', isTopicSelected(option) ? [...(watch('topics') || [])].filter(({ value }) => value !== option.value) : [...(watch('topics') || []), option])}
                                            />
                                        ))}
                                    </div>

                                    <div>
                                        <div className='flex gap-x-4'>
                                            <Button
                                                className="!w-[200px] flex items-center justify-center font-semibold text-base max-w-[120px] hover:bg-primary-600 "
                                                onClick={() => setCurrentStep(1)}
                                            >
                                                Back
                                            </Button>
                                            <Button
                                                className={`!w-[200px] flex items-center justify-center font-semibold text-base max-w-[120px] hover:bg-primary-600 ${loading && 'pointer-events-none opacity-60'}`}
                                                onClick={handleSubmit(onSubmit)}
                                                disabled={!watch('topics').length}
                                            >
                                                {loading ? 'Please wait...' : 'Submit'}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </Card>
                </div>
                <Modal
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    className="w-full max-w-[1280px] h-full max-h-[570px]"
                >
                    <div className='w-full h-full p-5 flex justify-center items-center'>
                        <iframe
                            title='appointment'
                            width={"100%"}
                            height={"100%"}
                            src={selectedCountry === 'Australia' ? LINKS.CLINIKO : LINKS.ZOCDOC}
                        />
                    </div>
                </Modal>
            </div>
            <Footer />
        </div>
    );
};
