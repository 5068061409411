import { useEffect } from "react"
import { Header } from "../../layout/header/Header"

export const ScheduleAppointment = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const practitionerId = urlParams.get('practitioner_id');
    const appointmentTypeId = urlParams.get('appointment_type_id');

    useEffect(() => {
        window.addEventListener('message', function handleIFrameMessage(e) {
            var clinikoBookings = document.getElementById('cliniko-96052429')!;
            if (typeof e.data !== 'string') return;
            if (e.data.search('cliniko-bookings-resize') > -1) {
                var height = Number(e.data.split(':')[1]);
                clinikoBookings.style.height = height + 'px';
            }
            e.data.search('cliniko-bookings-page') > -1 && clinikoBookings.scrollIntoView();
        });
    }, [])
    return (
        <div className='w-full flex h-auto flex-col items-center'>
            <Header className='bg-white' />
            <div style={{ width: '100%', padding: "1rem"}} className="max-w-[1000px]">
                <iframe
                    id='cliniko-96052429'
                    src={`https://myor-diagnostics-ltd.au2.cliniko.com/bookings?business_id=1320080146329246421&practitioner_id=${practitionerId}&appointment_type_id=${appointmentTypeId}&embedded=true`}
                    frameBorder='0'
                    scrolling='auto'
                    width='100%'
                    height='1000'
                    title="cliniko"
                    style={{ pointerEvents: 'auto' }} />
            </div>

        </div>
    )
}