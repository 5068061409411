import { FC } from 'react';
import { ReactComponent as FirstSVG } from '../../../../../assets/images/partner-1.svg';
import { ReactComponent as SecondSVG } from '../../../../../assets/images/partner-2.svg';
import { ReactComponent as ThirdSVG } from '../../../../../assets/images/partner-3.svg';
import { ReactComponent as FourthSVG } from '../../../../../assets/images/partner-4.svg';
import { ReactComponent as FifthSVG } from '../../../../../assets/images/partner-5.svg';
import parther6 from '../../../../../assets/images/partner-6.png';
import { useLang } from '../../../../../Lang/useLang';
import { Title } from '../../../../UIKit/title/Title';


export const PartnerSection: FC = () => {
  const { $, getSelectedLang }: any = useLang();
  const items = [
    {
      image: <FirstSVG />,
      description: $('HOME_PARTNER_1_DESCRIPTION'),
    },
    {
      image: <SecondSVG />,
      description: $('HOME_PARTNER_2_DESCRIPTION'),
    },
    {
      image: <ThirdSVG />,
      description: $('HOME_PARTNER_3_DESCRIPTION'),
    },
    {
      image: <FourthSVG />,
      description: $('HOME_PARTNER_4_DESCRIPTION'),
    },
    {
      image: <FifthSVG />,
      description: $('HOME_PARTNER_5_DESCRIPTION'),
    },
    {
      image: <img src={parther6}/>,
      description: $('HOME_PARTNER_6_DESCRIPTION'),
    },
  ]

  return (
    <div className="w-full flex flex-col items-center justify-center px-6 py-20 md:px-16">
      <Title className="text-[29px] md:text-[40px] max-w-[900px] mt-0 mb-6 md:mb-11" text={$('HOME_PARTNER_SECTION_TITLE')}/>

      <div className='w-full max-w-[920px] grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 gap-6 md:gap-[30px]'>
        {
          items.map(({ image, description }, index) => (
            <div className='bg-aqua-300 rounded-2xl p-6' key={index}>
              <div className='flex justify-center h-[92px]'>{image}</div>
              <p className='mt-5 text-lg text-gray-300 text-center'>{description}</p>
            </div>
          ))
        }
      </div>
    </div>
  );
};
