import React from 'react';

import { Header } from '../../layout/header/Header';
import { Footer } from '../../layout/footer/Footer';
import { Introduction } from './components/Introduction';
import { Statistics } from './components/Statistics';
import { HowItWorks } from './components/HowItWorks';
import { HealthierBeginnings } from './components/HealthierBeginnings';
import { Caring } from './components/Caring';
import { Specialist } from './components/Specialist';

export const ParentsPage: React.FC = () => {

  const isInternal = window.history.length > 2;

  return (
    <div className='w-full flex h-auto flex-col items-center bg-white'>
        {isInternal && <Header className='bg-white'/>}
        <Introduction />
        <Statistics />
        <HowItWorks />
        <Specialist />
        <HealthierBeginnings />
        <Caring />
        <Footer />
    </div>
  );
};
