import React, { ReactNode } from "react"
import styles from './Line.module.css';

interface IProps {
    children: ReactNode,
    center?: boolean
}

export const Line: React.FC<IProps> = ({ children, center }) => {
    return (
        <div className={[
            styles.container,
            center && styles.center
        ].join(' ')}>
            {children}
        </div>
    )
}