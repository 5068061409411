import React from 'react';
import image1 from '../../../../../../../assets/images/pediatrics-color.png';
import image2 from '../../../../../../../assets/images/annals-color.png';
import image3 from '../../../../../../../assets/images/pai-color.png';
import image4 from '../../../../../../../assets/images/allergy-color.png';

import 'swiper/css';

interface ScienceImagesProps {
  index: number;
  onClickImage: (index: number) => void;
}

export const ScienceImages: React.FC<ScienceImagesProps> = ({
  index,
  onClickImage
}) => {
  return (
    <div className="w-full flex flex-col items-center justify-around md:flex-row pb-8 gap-4 mt-2">

      <div
        className="flex items-center justify-center cursor-pointer transition-all ease-in-out"
        onClick={() => {
          onClickImage(1);
        }}
      >
        <img 
          data-show={index === 1} 
          src={image1} 
          className="grayscale opacity-50 data-[show=true]:grayscale-0 data-[show=true]:opacity-100 transition-all"
          alt="" 
        />
      </div>
      <div
        className="flex items-center justify-center cursor-pointer transition-all ease-in-out"
        onClick={() => {
          onClickImage(2);
        }}
      >
        <img 
          data-show={index === 2} 
          src={image2} 
          className="grayscale opacity-50 data-[show=true]:grayscale-0 data-[show=true]:opacity-100 transition-all"
          alt="" 
        />
      </div>
      <div
        className="flex items-center justify-center cursor-pointer transition-all ease-in-out ml-10"
        onClick={() => {
          onClickImage(3);
        }}
      >
        <img 
          data-show={index === 3} 
          src={image3} 
          className="grayscale opacity-50 data-[show=true]:grayscale-0 data-[show=true]:opacity-100 transition-all"
          alt="" 
        />
      </div>
      <div
        className="flex items-center justify-center cursor-pointer transition-all ease-in-out"
        onClick={() => {
          onClickImage(4);
        }}
      >
        <img 
          data-show={index === 4} 
          src={image4} 
          className="grayscale opacity-50 data-[show=true]:grayscale-0 data-[show=true]:opacity-100 transition-all"
          alt="" 
        />
      </div>
    </div>
  );
};
