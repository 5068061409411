
export function SkeletonLoading() {
  return (
    <div className="w-full h-screen flex flex-col items-center justify-center p-16 gap-4 bg-white">
      <div className="w-[600px] h-8 flex justify-center items-center bg-zinc-300 empty:bg-zinc-300 rounded-md animate-pulse"></div>
      <div className="w-[600px] h-52 flex justify-center items-center bg-zinc-100 empty:bg-zinc-100 rounded-md animate-pulse"></div>
      <div className="w-[600px] flex flex-wrap items-center justify-start gap-3">
        <div className="w-80 h-8 flex justify-center items-center bg-zinc-200 empty:bg-zinc-200 rounded-md animate-pulse"></div>
        <div className="w-40 h-8 flex justify-center items-center bg-zinc-300 empty:bg-zinc-300 rounded-md animate-pulse"></div>
        <div className="w-96 h-8 flex justify-center items-center bg-zinc-200 empty:bg-zinc-200 rounded-md animate-pulse"></div>
        <div className="w-40 h-8 flex justify-center items-center bg-zinc-300 empty:bg-zinc-300 rounded-md animate-pulse"></div>
        <div className="w-60 h-8 flex justify-center items-center bg-zinc-200 empty:bg-zinc-200 rounded-md animate-pulse"></div>
        <div className="w-40 h-8 flex justify-center items-center bg-zinc-300 empty:bg-zinc-300 rounded-md animate-pulse"></div>
        <div className="w-70 h-8 flex justify-center items-center bg-zinc-200 empty:bg-zinc-200 rounded-md animate-pulse"></div>
        <div className="w-60 h-8 flex justify-center items-center bg-zinc-300 empty:bg-zinc-300 rounded-md animate-pulse"></div>
        <div className="w-80 h-8 flex justify-center items-center bg-zinc-200 empty:bg-zinc-200 rounded-md animate-pulse"></div>
        <div className="w-50 h-8 flex justify-center items-center bg-zinc-300 empty:bg-zinc-300 rounded-md animate-pulse"></div>
        <div className="w-96 h-8 flex justify-center items-center bg-zinc-200 empty:bg-zinc-200 rounded-md animate-pulse"></div>
        <div className="w-40 h-8 flex justify-center items-center bg-zinc-300 empty:bg-zinc-300 rounded-md animate-pulse"></div>
        <div className="w-30 h-8 flex justify-center items-center bg-zinc-200 empty:bg-zinc-200 rounded-md animate-pulse"></div>
        <div className="w-40 h-8 flex justify-center items-center bg-zinc-300 empty:bg-zinc-300 rounded-md animate-pulse"></div>
        <div className="w-70 h-8 flex justify-center items-center bg-zinc-200 empty:bg-zinc-200 rounded-md animate-pulse"></div>

      </div>
      
    </div>
  )
}