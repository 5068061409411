import React from 'react';
import { Title } from '../../../../../UIKit/title/Title';
import { Button } from '../../../../../UIKit/button/Button';
import { useLang } from '../../../../../../Lang/useLang';
import { Link } from 'react-router-dom';

export const PhoneDescription: React.FC = () => {
  const { $, getSelectedLang }: any = useLang();

  return (
    <div className="flex flex-col w-auto md:w-[494px] items-start justify-center gap-y-6 md:gap-12 ">
      <Title
        className="mb-0 mt-0 text-center text-2xl md:text-[31px] md:text-left"
        text={$('HOME_PHONE_SECTION_TITLE')}
      />
      <span className="text-lg text-zinc-600 text-center md:text-left">
        {$('HOME_PHONE_SECTION_SUBTITLE')}
      </span>
      <Link to={getSelectedLang().url}>
        <Button
          className="cta_click hidden md:flex px-6 py-4 items-center justify-center text-base font-semibold hover:bg-primary-400"
          position="HOME_PHONE_START_SURVEY"
        >
          {$('HOME_PHONE_START_SURVEY')}
        </Button>
      </Link>
    </div>
  );
};
