import React from 'react';
import { Regulator } from './components/regulator/Regulator';
import {
  CEIcon,
  FDAIcon,
  GDPRIcon,
  HIPAAIcon,
  ISOIcon,
  TGAIcon
} from '../../../../UIKit/icon/Icon';
import { useLang } from '../../../../../Lang/useLang';

interface RegulatorsSectionProps {}

export const RegulatorsSection: React.FC<RegulatorsSectionProps> = ({}) => {
  const { $ }: any = useLang();
  return (
    <div className="w-full flex flex-col items-center my-16 ">
      <div className="flex flex-col w-full max-w-content items-center justify-center gap-8">
        <div className="w-full max-w-2xl text-center text-zinc-800 text-xl font-semibold ">
          {$("HOME_REGULATORS_SECTION_TITLE")}
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 items-center justify-center gap-12 md:gap-16">
          <Regulator
            className='peer-hover:flex'
            tooltipContent={
              $("HOME_REGULATORS_CE")
            }
          >
            <CEIcon className="w-14 h-14 ce peer" />
          </Regulator>

          <Regulator
            className='peer-hover:flex'
            tooltipContent={
              $("HOME_REGULATORS_GDPR")
            }
          >
            <GDPRIcon className="w-14 h-14 gdpr peer" />
          </Regulator>

          <Regulator
            className='peer-hover:flex'
            tooltipContent={
              $("HOME_REGULATORS_ISO")
            }
          >
            <ISOIcon className="w-20 h-20 iso peer" />
          </Regulator>
          <Regulator
            className='peer-hover:flex'
            tooltipContent={
              $("HOME_REGULATORS_FDA")
            }
          >
            <FDAIcon className="w-20 h-20 fda peer" />
          </Regulator>
          <Regulator
            className='peer-hover:flex'
            tooltipContent={
              $("HOME_REGULATORS_HIPAA")
            }
          >
            <HIPAAIcon className="w-24 h-24 hipaa peer" />
          </Regulator>
          <Regulator
            className='peer-hover:flex'
            tooltipContent={
              $("HOME_REGULATORS_TGA")
            }
          >
              <TGAIcon className="w-24 h-24 scale-[2] tga peer hover:fill-white" />
          </Regulator>
        </div>
      </div>
    </div>
  );
};
