import React from 'react';

import { ReactComponent as ArrowUpPrimarySvg } from '../../../assets/svgs/arrowUpPrimary.svg';
import { ReactComponent as USAFlagSvg } from '../../../assets/svgs/usFlag.svg';
import { ReactComponent as SpainFlagSvg } from '../../../assets/svgs/spainFlag.svg';
import { ReactComponent as MexicoFlagSvg } from '../../../assets/svgs/mexicoFlag.svg';
import { ReactComponent as FranceFlagSvg } from '../../../assets/svgs/franceFlag.svg';
import { ReactComponent as AustraliaFlagSvg } from '../../../assets/svgs/australia-flag.svg';
import { ReactComponent as IsraelFlagSvg } from '../../../assets/svgs/israel-flag.svg';
import { ReactComponent as IrelandFlagSvg } from '../../../assets/svgs/ireland-flag.svg';

import { ReactComponent as ArrowDownSvg } from '../../../assets/svgs/arrowDown.svg';
import { ReactComponent as PlaySvg } from '../../../assets/svgs/play.svg';
import { ReactComponent as CESvg } from '../../../assets/svgs/CE.svg';
import { ReactComponent as GDPRSvg } from '../../../assets/svgs/GDPR.svg';
import { ReactComponent as ISOSvg } from '../../../assets/svgs/ISO.svg';
import { ReactComponent as TGASvg } from '../../../assets/svgs/TGA.svg';
import { ReactComponent as FDASvg } from '../../../assets/svgs/FDA.svg';
import { ReactComponent as HIPAASvg } from '../../../assets/svgs/HIPAA.svg';
import { ReactComponent as ArrowUpFullSvg } from '../../../assets/svgs/arrowUpFull.svg';
import { ReactComponent as WomanSvg } from '../../../assets/svgs/woman.svg';
import { ReactComponent as BabySvg } from '../../../assets/svgs/baby.svg';
import { ReactComponent as BabyChairSvg } from '../../../assets/svgs/babyChair.svg';
import { ReactComponent as PlateSvg } from '../../../assets/svgs/plate.svg';
import { ReactComponent as CheckboxSvg } from '../../../assets/svgs/checkbox.svg';
import { ReactComponent as LinkSvg } from '../../../assets/svgs/link.svg';
import { ReactComponent as ArrowLeftSvg } from '../../../assets/svgs/arrow-left.svg';
import { ReactComponent as ArrowRightSvg } from '../../../assets/svgs/arrow-right.svg';
import { ReactComponent as FacebookSvg } from '../../../assets/svgs/facebook-icon.svg';
import { ReactComponent as TwitterSvg } from '../../../assets/svgs/twitter-icon.svg';
import { ReactComponent as YoutubeSvg } from '../../../assets/svgs/youtube-icon.svg';
import { ReactComponent as LinkedinSvg } from '../../../assets/svgs/linkedin-icon.svg';
import { ReactComponent as LinkedinNCSvg } from '../../../assets/svgs/linkedin-no-color.svg';
import { ReactComponent as InstagramSvg } from '../../../assets/svgs/instagram-icon.svg';
import { ReactComponent as CheckSvg } from '../../../assets/svgs/check.svg';
import { ReactComponent as MenuSvg } from '../../../assets/svgs/menu.svg';
import { ReactComponent as XSvg } from '../../../assets/svgs/x.svg';
import { ReactComponent as GoogleSvg } from '../../../assets/svgs/google-color.svg';
import { ReactComponent as GoogleNCSvg } from '../../../assets/svgs/google-no-color.svg';
import { ReactComponent as RightArrowTailSvg } from '../../../assets/svgs/right-arrow-tail.svg';
import { ReactComponent as EmailCSvg } from '../../../assets/svgs/email-icon-c.svg';
import { ReactComponent as EmailNCSvg } from '../../../assets/svgs/email-icon-nc.svg';

import { twMerge } from 'tailwind-merge';

interface IconProps {
  className?: string;
  onClick?: () => void;
}

export const ArrowDownIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <ArrowDownSvg />
    </div>
  );
};

export const ArrowUpPrimaryIcon: React.FC<IconProps> = ({
  className,
  onClick
}) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <ArrowUpPrimarySvg />
    </div>
  );
};

export const USAFlagIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <USAFlagSvg />
    </div>
  );
};

export const SpainFlagIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <SpainFlagSvg />
    </div>
  );
};

export const MexicoFlagIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <MexicoFlagSvg />
    </div>
  );
};

export const FranceFlagIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <FranceFlagSvg />
    </div>
  );
};

export const IrelandFlagIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <IrelandFlagSvg />
    </div>
  );
};

export const PlayIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <PlaySvg />
    </div>
  );
};

export const CEIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <CESvg />
    </div>
  );
};

export const GDPRIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <GDPRSvg />
    </div>
  );
};

export const ISOIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <ISOSvg />
    </div>
  );
};
export const TGAIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center',
      className
    )} onClick={onClick}>
      <TGASvg />
    </div>
  );
};

export const FDAIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <FDASvg className="hover:fill-primary-700"/>
    </div>
  );
};

export const ArrowUpFullIcon: React.FC<IconProps> = ({
  className,
  onClick
}) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <ArrowUpFullSvg />
    </div>
  );
};

export const HIPAAIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <HIPAASvg />
    </div>
  );
};

export const WomanIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <WomanSvg/>
    </div>
  );
};

export const BabyIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <BabySvg />
    </div>
  );
};

export const BabyChairIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <BabyChairSvg />
    </div>
  );
};

export const PlateIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <PlateSvg />
    </div>
  );
};

export const CheckboxIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <CheckboxSvg />
    </div>
  );
};

export const LinkIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <LinkSvg />
    </div>
  );
};

export const ArrowLeftIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <ArrowLeftSvg />
    </div>
  );
};

export const ArrowRightIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <ArrowRightSvg />
    </div>
  );
};

export const FacebookIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <FacebookSvg />
    </div>
  );
};

export const TwitterIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <TwitterSvg />
    </div>
  );
};

export const YoutubeIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <YoutubeSvg />
    </div>
  );
};

export const LinkedinIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <LinkedinSvg />
    </div>
  );
};

export const LinkedinNCIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <LinkedinNCSvg />
    </div>
  );
};

export const InstagramIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <InstagramSvg />
    </div>
  );
};

export const CheckIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <CheckSvg />
    </div>
  );
};

export const MenuIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <MenuSvg />
    </div>
  );
};

export const XIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <XSvg />
    </div>
  );
};

export const AustraliaFlagIcon: React.FC<IconProps> = ({
  className,
  onClick
}) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <AustraliaFlagSvg />
    </div>
  );
};

export const IsraelFlagIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <IsraelFlagSvg />
    </div>
  );
};

export const GoogleIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <GoogleSvg />
    </div>
  );
};

export const GoogleNCIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <GoogleNCSvg />
    </div>
  );
};

export const RightArrowTailIcon: React.FC<IconProps> = ({
  className,
  onClick
}) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <RightArrowTailSvg />
    </div>
  );
};

export const EmailCIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <EmailCSvg />
    </div>
  );
};

export const EmailNCIcon: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div className={twMerge(
      'flex items-center justify-center w-4 h-4',
      className
    )} onClick={onClick}>
      <EmailNCSvg />
    </div>
  );
};
