import React from 'react';
import { useLang } from '../../../../Lang/useLang';
import { Title } from '../../../UIKit/title/Title';
import { ReactComponent as DoctorSVG } from '../../../../assets/images/partner-3.svg';
import { ReactComponent as UnbornChildSVG } from '../../../../assets/svgs/unborn-child.svg';
import { ReactComponent as ChildFaceSVG } from '../../../../assets/svgs/child-face.svg';
import { ReactComponent as MedCardSVG } from '../../../../assets/svgs/med-card.svg';
import cn from 'classnames'
import { useMediaQuery } from 'react-responsive'


export const ScienceChart: React.FC = () => {
    const { $ }: any = useLang();
    const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })

    const items = [
        [
            {},
            {
                icon: <UnbornChildSVG className="scale-[0.7] md:scale-100" />,
                title: $('SCIENCE_CHART_1_TITLE')
            },
            {
                icon: <ChildFaceSVG className="scale-[0.7] md:scale-100" />,
                title: $('SCIENCE_CHART_2_TITLE')
            },
            {
                icon: <MedCardSVG className="scale-[0.7] md:scale-100" />,
                title: $('SCIENCE_CHART_3_TITLE')
            },
            {
                icon: <DoctorSVG className="scale-[0.7] md:scale-100" />,
                title: $('SCIENCE_CHART_4_TITLE')
            },
        ],
        [
            {
                title: $('ACCURACY')
            },
            {
                text: '89%'
            },
            {
                text: '90%'
            },
            {
                text: '69%'
            },
            {
                text: '95%'
            },
        ],
        [
            {
                title: $('SENSITIVITY')
            },
            {
                text: '49%'
            },
            {
                text: '68%'
            },
            {
                text: '46%'
            },
            {
                text: '11%'
            },
        ],
        [
            {
                title: $('SPECIFICITY')
            },
            {
                text: '90%'
            },
            {
                text: '91%'
            },
            {
                text: '70%'
            },
            {
                text: '99%'
            },
        ],
    ]
  return (
    <div className='w-full max-w-content flex flex-col items-center py-20 mx-auto'>
        <Title className=' max-w-[920px] text-[29px] md:text-[40px] leading-[48px] m-0 mb-6' text={$('SCIENCE_CHART_TITLE')}/>
        <p className='text-lg text-center font-light md:font-normal md:text-xl'>{$('SCIENCE_CHART_SUBTITLE')}</p>        
       <div className='w-full flex flex-col max-w-[1100px] mt-10 px-6'>
        {
            items.map((row, rowIndex) => (
                <div 
                    key={rowIndex}
                    className={cn('flex items-center relative', { 'h-20': rowIndex }, 'border-b-2 border-primary-500')}
                >
                    {
                        row.map(({ icon, title, text }: any, cellIndex) => isDesktop ? (
                            <div className='w-[220px] flex flex-col gap-y-6 items-center' key={`${rowIndex} - ${cellIndex}`}>
                                {icon && (<div className='h-20'>{icon}</div>)}
                                <p className={cn('max-w-[160px] h-16 flex items-center text-xl text-center', { 'font-medium': title, '!items-start': !rowIndex })}>{title || text}</p>
                            </div>
                        ) : cellIndex ? (
                            <div className='w-[220px] flex flex-col gap-y-6 items-center' key={`${rowIndex} - ${cellIndex}`}>
                                {icon && (<div className='h-11'>{icon}</div>)}
                                <p className={cn('max-w-[160px] h-auto md:h-16 flex items-center text-lg text-center', { 'font-medium !text-[10px]': title, '!items-start': !rowIndex })}>{title || text}</p>
                            </div>
                        ) : (
                            <div className='w-[220px] flex flex-col gap-y-6 items-center absolute top-2 left-1/2 -translate-x-1/2' key={`${rowIndex} - ${cellIndex}`}>
                                <p className={cn('max-w-[160px] flex items-center text-[10px] text-center', { 'font-medium': title, '!items-start': !rowIndex })}>{title || text}</p>
                            </div>
                        )
                        )
                    }
                    </div>
                )
                )
        }
       </div>
    </div>
  );
};