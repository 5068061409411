import React from 'react';
import { Title } from '../../../UIKit/title/Title';
import { useLang } from '../../../../Lang/useLang';
import ArielKImageSrc from '../../../../assets/images/Ariel-k-lg.png';

export const ProvidersHomeQuote: React.FC = () => {
    const { $ }: any = useLang();
    return (
      <div className="w-full bg-blue-light px-6 md:px-12 pt-10 md:pt-20 pb-16 md:py-28 bg-providers-home-bg md:bg-providers-home-bg-md bg-[position:bottom_left,right] md:bg-[position:bottom_left,top_20px_right] bg-no-repeat ">
          <div className='max-w-[1100px] mx-auto flex flex-col md:flex-row items-start gap-x-6 gap-y-8 justify-between'>
            <Title className='m-0 text-2xl md:text-[30px] md:leading-[48px] font-medium text-center md:text-left italic max-w-[850px]' text={$('PROVIDERS_HOME_QUOTE')}/>
            <div className='flex flex-col-reverse md:flex-col mx-auto gap-y-8 md:gap-y-3'>
                <img src={ArielKImageSrc} className="min-w-[200px]"/>
                <p className='max-w-[140px] text-center mx-auto text-lg'>{$('PROVIDERS_HOME_QUOTE_AUTHOR')}</p>
            </div>
          </div>
      </div>
    );
  };
