import React from 'react';
import { useLang } from '../../../../Lang/useLang';
import { Title } from '../../../UIKit/title/Title';
import { ReactComponent as ReportBookSVG } from '../../../../assets/svgs/report-book.svg'
import { ReactComponent as ComputerCheckSVG } from '../../../../assets/images/partner-1.svg';
import { ReactComponent as ComputerScienceSVG } from '../../../../assets/svgs/computer-science.svg';

export const ProvidersHIWGrow: React.FC = () => {
    const { $ }: any = useLang();

    const items = [
        {
            title: $('PROVIDERS_HIW_GROW_1_TITLE'),
            description: $('PROVIDERS_HIW_GROW_1_SUBTITLE'),
            image: <ComputerScienceSVG />
        },
        {
            title: $('PROVIDERS_HIW_GROW_2_TITLE'),
            description: $('PROVIDERS_HIW_GROW_2_SUBTITLE'),
            image: <ReportBookSVG />
        },
        {
            title: $('PROVIDERS_HIW_GROW_3_TITLE'),
            description: $('PROVIDERS_HIW_GROW_3_SUBTITLE'),
            image: <ComputerCheckSVG />
        },
    ]

  return (
    <div className='w-full max-w-content flex flex-col items-center py-8 md:py-32 px-6 mx-auto'>
        <Title className='text-[29px] md:text-[40px] mb-6 mt-0' text={$('PROVIDERS_HIW_GROW_TITLE')}/>
        <p className='max-w-[915px] text-lg text-center text-gray-300'>{$('PROVIDERS_HIW_GROW_SUBTITLE')}</p>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-y-10 gap-x-[100px] mt-8 md:mt-20'>
            {
                items.map(({ title, description, image }, index) => (
                    <div key={index} className="max-w-[250px] flex flex-col md:gap-y-4 items-center text-center">
                        <div className='h-[84px]'>{image}</div>
                        <h5 className='text-lg font-medium text-gray-300 mt-3 md:mt-0 mb-1.5 md:mb-0'>{title}</h5>
                        <p className='text-gray-300'>{description}</p>
                    </div>
                ))
            }
        </div>
    </div>
  );
};
